import React, { useState } from 'react';
import {
  Button, Input, Modal, Row, Table, Tooltip, Typography
} from 'antd';
import PropTypes from 'prop-types';

import { format } from 'date-fns';
import { es } from 'date-fns/locale';

import 'antd/dist/antd.less';
import {
  ExclamationCircleOutlined,
  EyeTwoTone,
  FilePdfTwoTone,
  SendOutlined
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Colors from '../../../../assets/colors/Colors';
import i18n from '../../../../assets/language/i18n';
import ConstantsDate from '../../../../constants/ConstantsDate';

import {
  actionGetPreBillPdf,
  actionSendPreBill,
} from '../../../../store/reducers/conciliations/conciliationsActions';
import ConstantsRoutes from '../../../../constants/ConstantsRoutes';
import { getWritePermissionByTab } from '../../../../utils/Utils';

const { Text } = Typography;
const { TextArea } = Input;

const styles = {
  div: {
    width: '100%',
    backgroundColor: Colors.BACKGROUND_COLOR_3,
    paddingLeft: 46,
    paddingTop: 20,
    paddingRight: 46,
    paddingBottom: 20,
  },
  row: {
    marginBottom: 40,
  },
  col: { marginRight: 100 },
  textTitle1: {
    fontSize: 22,
    color: Colors.TEXT_COLOR,
    fontFamily: 'Roboto-Medium',
    verticalAlign: 'middle',
    marginRight: 4,
  },
  textTitle2: {
    fontSize: 22,
    color: Colors.TEXT_COLOR,
    fontFamily: 'Roboto-Bold',
    fontWeight: '500',
    verticalAlign: 'middle',
  },
  textPay: {
    fontSize: 16,
    color: Colors.TEXT_COLOR,
    fontFamily: 'Roboto-Medium',
    verticalAlign: 'middle',
    fontWeight: '500',
    paddingLeft: 16,
    paddingRight: 16,
  },
  bluePoint: {
    width: 10, height: 10, backgroundColor: Colors.PRIMARY_COLOR, borderRadius: 5,
  },
  blueLine: {
    width: '100%', height: 2, backgroundColor: Colors.PRIMARY_COLOR,
  },
  greyPoint: {
    width: 10, height: 10, backgroundColor: Colors.PROGRESS_GREY_LINE, borderRadius: 5,
  },
  greyLine: {
    width: '100%', height: 2, backgroundColor: Colors.PROGRESS_GREY_LINE,
  },
  textValueGreenColumn: {
    fontSize: 14,
    fontFamily: 'Roboto-Regular',
    color: Colors.TEXT_GREEN,
  },
  textValueRedColumn: {
    fontSize: 14,
    fontFamily: 'Roboto-Regular',
    color: Colors.TEXT_RED,
  },
  textValueYellowColumn: {
    fontSize: 14,
    fontFamily: 'Roboto-Regular',
    color: Colors.TEXT_YELLOW,
  },
  buttonLink: {
    fontFamily: 'Roboto-Regular',
    fontSize: 14,
  },
  iconSave: {
    color: Colors.TEXT_GREEN,
  },
  iconEdit: {
    color: Colors.PRIMARY_COLOR,
  },
  iconDelete: {
    color: Colors.TEXT_RED,
  },
  buttonPrimary: {
    fontFamily: 'Roboto-Bold',
    fontSize: 14,
    marginTop: 34,
  },
  select: {
    width: 100,
    fontFamily: 'Roboto-Regular',
    fontSize: 14,
    borderRadius: 4,
    borderWidth: 1,
    borderColor: 'white',
  },
  select2: {
    width: 160,
    fontFamily: 'Roboto-Regular',
    fontSize: 14,
    borderRadius: 4,
    borderWidth: 1,
    borderColor: 'white',
  },
  textOptionValue: {
    fontSize: 14,
    color: Colors.INPUT_COLOR,
    fontFamily: 'Roboto-Regular',
  },
  input: {
    borderRadius: 4,
    borderWidth: 1,
    borderColor: 'white',
    fontFamily: 'Roboto-Regular',
    fontSize: 14,
    paddingTop: 6,
  },
  textTitlePopconfirm: {
    fontSize: 16,
    fontFamily: 'Roboto-Medium',
  },
  textOkButtonPopconfirm: {
    fontFamily: 'Roboto-Bold',
    fontWeight: '600',
    fontSize: 16,
    color: 'white',
  },
  textCancelButtonPopconfirm: {
    fontFamily: 'Roboto-Regular',
    fontSize: 16,
  },
  icon: {
    color: Colors.PRIMARY_COLOR,
  },
  inputModal: {
    width: '100%',
    borderRadius: 4,
    borderColor: Colors.BORDER_COLOR,
    fontFamily: 'Roboto-Regular',
    fontSize: 16,
  },
  selectModal: {
    fontFamily: 'Roboto-Regular',
    fontSize: 16,
    borderRadius: 4,
    borderWidth: 1,
    borderColor: 'white',
  },
  buttonPrimaryModal: {
    fontFamily: 'Roboto-Bold',
    fontWeight: '600',
    fontSize: 16,
    width: '100%',
  }
};

export default function PreBills(props) {
  const navigate = useNavigate();

  const [filteredInfo, setFilteredInfo] = useState(null);
  const [cancelConciliationComment, setCancelConciliationComment] = useState(null);

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.reducerLoading);

  const { conciliation } = props;

  const handleChange = (pagination, filters) => {
    setFilteredInfo(filters);
  };

  const showConfirmSendMail = (idJob, idHotel) => {
    const { confirm } = Modal;

    confirm({
      title: t('text_send_pre_facturation'),
      icon: <ExclamationCircleOutlined />,
      content: (<TextArea
        showCount
        allowClear
        placeholder={t('text_select_reservation')}
        rows={3}
        maxLength={130}
        onChange={(e) => {
          setCancelConciliationComment(e.target.value);
        }}
      />),
      onOk: () => {
        dispatch(actionSendPreBill(idJob, idHotel, cancelConciliationComment));
        setCancelConciliationComment(null);
      },
    });
  };

  const filtersSelected = filteredInfo || {};

  const hotelFilters = [];
  const statusFilters = [];

  conciliation?.prefacturas?.forEach((preBill) => {
    if (preBill.estado !== null) {
      if (!statusFilters.some((status) => status.value === preBill.estado)) {
        statusFilters.push({
          text: preBill.estado,
          value: preBill.estado,
        });
      }
    }
    if (preBill.nombre_hotel !== null) {
      if (!hotelFilters.some((status) => status.value === preBill.nombre_hotel)) {
        hotelFilters.push({
          text: preBill.nombre_hotel,
          value: preBill.nombre_hotel,
        });
      }
    }
  });

  const columns = [
    {
      title: (
        <Text className="text">
          {t('text_conciliation_status')}
        </Text>),
      dataIndex: 'estado',
      key: 'estado',
      sorter: {
        compare: (a, b) => a.estado.localeCompare(b.estado, i18n.language),
        multiple: 10,
      },
      filteredValue: filtersSelected.estado || null,
      filters: statusFilters,
      onFilter: (value, record) => record.estado?.indexOf(value) === 0,
      responsive: ['md'],
      render: (text) => (
        <Text className="text">
          {text}
        </Text>
      ),
    },
    {
      title: (
        <Text className="text">
          {t('text_hotel')}
        </Text>),
      dataIndex: 'nombre_hotel',
      key: 'nombre_hotel',
      sorter: {
        compare: (a, b) => a.nombre_hotel.localeCompare(b.nombre_hotel, i18n.language),
        multiple: 10,
      },
      filteredValue: filtersSelected.nombre_hotel || null,
      filters: hotelFilters,
      onFilter: (value, record) => record.nombre_hotel?.indexOf(value) === 0,
      responsive: ['md'],
      render: (text) => (
        <Text className="text">
          {text}
        </Text>
      ),
    },
    {
      title: (
        <Text className="text">
          {t('text_actions')}
        </Text>),
      dataIndex: 'id_prefactura',
      key: 'id_prefactura',
      responsive: ['md'],
      render: (text) => (
        <div>
          <Tooltip title={t('download_pdf')}>
            <Button
              type="link"
              htmlType="button"
              onClick={() => {
                const currentPreBill = conciliation.prefacturas.find(
                  (item) => item.id_prefactura === text
                );
                const fechaInicio = conciliation?.fecha_inicio?.length ? ''
                  : format(conciliation?.fecha_inicio, ConstantsDate.DATE_FORMAT_FILE_NAME, {
                    locale: es,
                  });
                const fechaFinal = conciliation?.fecha_final?.length ? ''
                  : format(conciliation?.fecha_final, ConstantsDate.DATE_FORMAT_FILE_NAME, {
                    locale: es,
                  });
                dispatch(actionGetPreBillPdf(
                  conciliation.id_job,
                  currentPreBill?.id_hotel,
                  `${currentPreBill?.nombre_hotel.replaceAll(' ', '-')}_${fechaInicio}_${fechaFinal}`
                ));
              }}
              icon={<FilePdfTwoTone style={{ marginRight: '7px', fontSize: '20px'}} />}
              style={styles.buttonLink}
            />
          </Tooltip>
          <Tooltip title={t('visualize_prebill')}>
            <Button
              type="link"
              htmlType="button"
              onClick={() => {
                navigate(ConstantsRoutes.CONCILIATION, {
                  state: {
                    jobId: conciliation.id_job,
                    hotelId: conciliation.prefacturas.find(
                      (item) => item.id_prefactura === text
                    ).id_hotel,
                    hotelName: conciliation.prefacturas.find(
                      (item) => item.id_prefactura === text
                    ).nombre_hotel,
                    companyName: null,
                  },
                });
              }}
              icon={<EyeTwoTone style={{ 'margin-right': '7px', fontSize: '20px'}} />}
              style={styles.buttonLink}
            />
          </Tooltip>
          {getWritePermissionByTab(ConstantsRoutes.CONCILIATIONS) ? (
            <Tooltip title={t('text_send_pre_facturation')}>
              <Button
                type="link"
                htmlType="button"
                disabled={!getWritePermissionByTab(ConstantsRoutes.CONCILIATIONS)}
                onClick={() => showConfirmSendMail(
                  conciliation.id_job,
                  conciliation.prefacturas.find(
                    (item) => item.id_prefactura === text
                  ).id_hotel
                )}
                icon={<SendOutlined style={{ color: Colors.OTHERS, marginRight: '7px', fontSize: '20px' }} />}
                style={styles.buttonLink}
              />
            </Tooltip>
          ) : <div />}
        </div>
      ),
    },
  ];

  return (
    <div style={styles.div}>
      <Row align="middle">
        <Table
          rowKey="id_prefactura"
          style={{ width: '100%' }}
          pagination={
            { hideOnSinglePage: true }
          }
          size="small"
          onChange={handleChange}
          loading={loading}
          columns={columns}
          dataSource={conciliation.prefacturas}
        />
      </Row>
    </div>
  );
}

PreBills.defaultProps = {
  conciliation: undefined
};

PreBills.propTypes = {
  conciliation: PropTypes.shape({
    prefacturas: PropTypes.arrayOf(PropTypes.shape({})),
    id_job: PropTypes.string,
    fecha_inicio: PropTypes.instanceOf(Date),
    fecha_final: PropTypes.instanceOf(Date)
  })
};
