import React, { useState } from 'react';
import {
  Button, Col, Collapse, DatePicker, Form, Input, message, Row, Select, Typography,
} from 'antd';
import 'antd/dist/antd.less';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { SearchOutlined } from '@ant-design/icons';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import ConstantsDate from '../../../constants/ConstantsDate';
import {useGetFiltersCollectionRequestsApiQuery, setAppliedFilters } from '../../../services/collectionRequests';
import './styles/styles.css';

const { Text } = Typography;
const { Panel } = Collapse;
const { Option } = Select;
const { RangePicker } = DatePicker;

const formRef = React.createRef();

function getOptionsForSelect(list, className, value = 'value', label = 'label') {
  return list?.map((i) => (
    <Option key={`${i[value]}`} value={i[value]}>
      <Text className={className}>
        {i[label]}
      </Text>
    </Option>
  ));
}

export default function FilterCollectionRequest() {
  const [stateCollectionOrder, setStateCollectionOrder] = useState(null);
  const [company, setCompany] = useState(null);
  const [chain, setChain] = useState(null);
  const [agency, setAgency] = useState(null);
  const [dateCreateStart, setDateCreateStart] = useState(null);
  const [dateCreateEnd, setDateCreateEnd] = useState(null);
  const [currency, setCurrency] = useState(null);
  const [numberLocator, setNumberLocator] = useState(null);
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const {
    data: filters, error
  } = useGetFiltersCollectionRequestsApiQuery();

  if (error) {
    message.error(error.message);
  }

  const clickClearFilters = () => {
    setStateCollectionOrder(null);
    setCompany(null);
    setChain(null);
    setAgency(null);
    setDateCreateStart(null);
    setDateCreateEnd(null);
    setCurrency(null);
    setNumberLocator(null);
    formRef.current.resetFields();
  };

  const clickFilterButton = (e) => {
    dispatch(setAppliedFilters({
      referencia: numberLocator ?? undefined,
      cadena_hotelera: chain ?? undefined,
      touroperador: agency ?? undefined,
      empresa: company ?? undefined,
      estado: stateCollectionOrder ?? undefined,
      moneda: currency ?? undefined,
      fecha_inicio_solicitud: !dateCreateStart ? undefined : format(dateCreateStart.toDate(), ConstantsDate.DATE_FORMAT_REQUEST, {
        locale: es,
      }),
      fecha_fin_solicitud: !dateCreateEnd ? undefined : format(dateCreateEnd.toDate(), ConstantsDate.DATE_FORMAT_REQUEST, {
        locale: es,
      }),
    }));
  };

  const chainsOptions = getOptionsForSelect(filters?.chains, 'text-option-value', 'id_cadena', 'nombre_cadena');
  const companiesOptions = getOptionsForSelect(filters?.companies.filter((item) => !chain || item.id_cadena === chain), 'text-option-value');
  const currencyOptions = getOptionsForSelect(filters?.currencies, 'text-option-value', 'currency');
  const agenciesOptions = getOptionsForSelect(filters?.agencies, 'text-option-value', 'id', 'nombre');
  const collectionOrderStatesOptions = getOptionsForSelect(filters?.status, 'text-option-value');

  function emptyFilters() {
    return !(stateCollectionOrder?.length || company?.length || chain?.length || agency?.length || dateCreateStart || dateCreateEnd || currency?.length || numberLocator?.length);
  }

  return (
    <Row align="middle" className="row-filter remaquia">
      <Collapse
        accordion
        defaultActiveKey={['1']}
        style={{ width: '100%' }}
        expandIconPosition="end"
      >
        <Panel
          key="1"
          header={(
            <Text className="text-title">
              {t('text_search_filters')}
            </Text>
          )}
        >
          <Form
            name="advanced_search"
            className="ant-advanced-search-form"
            labelCol={{
              span: 10,
            }}
            ref={formRef}
            onSubmit={clickFilterButton}
          >
            <Row gutter={24}>
              <Col xs={24} md={8}>
                <Form.Item
                  name="field1"
                  label={(
                    <Text className="text-title-description">
                      {t('text_reference')}
                    </Text>
                  )}
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                >
                  <Input
                    suffix={<SearchOutlined className="icon-filter" />}
                    placeholder={t('text_reference')}
                    onChange={(text) => {
                      setNumberLocator(text.target.value);
                    }}
                    value={numberLocator}
                    className="input-filter"
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={8}>
                <Form.Item
                  name="chain"
                  label={(
                    <Text className="text-title-description">
                      {t('text_chain')}
                    </Text>
                  )}
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                >
                  <Select
                    showSearch
                    allowClear
                    defaultActiveFirstOption={false}
                    className="select-input"
                    placeholder={t('text_chain')}
                    optionFilterProp="children"
                    onChange={(value) => {
                      setChain(value);
                    }}
                    filterOption={
                      (
                        input,
                        option
                      ) => option.children.props.children.toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    value={chain}
                  >
                    {chainsOptions}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} md={8}>
                <Form.Item
                  name="dateCreate"
                  label={(
                    <Text className="text-title-description">
                      {t('text_request_date')}
                    </Text>
                  )}
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                >
                  <RangePicker
                    className="input-filter"
                    format={ConstantsDate.DATE_FORMAT_PICKER}
                    onChange={(dates) => {
                      setDateCreateStart(dates === null ? null : dates[0]);
                      setDateCreateEnd(dates === null ? null : dates[1]);
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col xs={24} md={8}>
                <Form.Item
                  name="stateCollectionOrder"
                  label={(
                    <Text className="text-title-description">
                      {t('text_conciliation_status')}
                    </Text>
                  )}
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                >
                  <Select
                    showSearch
                    className="select-input"
                    allowClear
                    placeholder={t('text_conciliation_status')}
                    optionFilterProp="children"
                    onChange={(value) => {
                      setStateCollectionOrder(value);
                    }}
                    filterOption={
                      (
                        input,
                        option
                      ) => option.children.props.children.toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    value={stateCollectionOrder}
                  >
                    {collectionOrderStatesOptions}
                  </Select>
                </Form.Item>
              </Col>

              <Col xs={24} md={8}>
                <Form.Item
                  name="company"
                  label={(
                    <Text className="text-title-description">
                      {t('company')}
                    </Text>
                  )}
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                >
                  <Select
                    showSearch
                    allowClear
                    defaultActiveFirstOption={false}
                    className="select-input"
                    placeholder={t('company')}
                    optionFilterProp="children"
                    onChange={(value) => {
                      setCompany(value);
                    }}
                    filterOption={
                      (
                        input,
                        option
                      ) => option.children.props.children.toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    value={company}
                  >
                    {companiesOptions}
                  </Select>
                </Form.Item>
              </Col>

              <Col xs={24} md={8}>
                <Form.Item
                  name="currency"
                  label={(
                    <Text className="text-title-description">
                      {t('text_currency')}
                    </Text>
                  )}
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                >
                  <Select
                    showSearch
                    className="select-input"
                    allowClear
                    placeholder={t('text_currency')}
                    optionFilterProp="children"
                    onChange={(value) => {
                      setCurrency(value);
                    }}
                    filterOption={
                      (
                        input,
                        option
                      ) => option.children.props.children.toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    value={currency}
                  >
                    {currencyOptions}
                  </Select>
                </Form.Item>
              </Col>

            </Row>
            <Row gutter={24}>
              <Col xs={{span: 24, offset: 0}} md={{span: 8, offset: 8}}>
                <Form.Item
                  name="agency"
                  label={(
                    <Text className="text-title-description">
                      {t('agency')}
                    </Text>
                  )}
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                >
                  <Select
                    showSearch
                    allowClear
                    defaultActiveFirstOption={false}
                    className="select-input"
                    placeholder={t('agency')}
                    optionFilterProp="children"
                    onChange={(value) => {
                      setAgency(value);
                    }}
                    filterOption={
                      (
                        input,
                        option
                      ) => option.children.props.children.toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    value={agency}
                  >
                    {agenciesOptions}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="buttons"
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                >
                  <Row justify="end">
                    <Col>
                      <Button
                        onClick={clickClearFilters}
                        className="button-default-filters"
                      >
                        {t('text_button_clear')}
                      </Button>
                    </Col>
                    <Col>
                      <Button
                        type="primary"
                        htmlType="submit"
                        onClick={clickFilterButton}
                        className="button-primary-filters"
                        disabled={
                          emptyFilters()
                        }
                      >
                        {t('text_button_filter')}
                      </Button>
                    </Col>
                  </Row>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Panel>
      </Collapse>
    </Row>
  );
}
