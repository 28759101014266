const ConstantsTpvTransactions = {
  GET_FILTERS_TRANSACTIONS: 'GET_FILTERS_TRANSACTIONS',
  GET_FILTERS_TRANSACTIONS_RESULT: 'GET_FILTERS_TRANSACTIONS_RESULT',
  GET_TPV_TRANSACTIONS: 'GET_TPV_TRANSACTIONS',
  GET_TPV_TRANSACTIONS_RESULT: 'GET_TPV_TRANSACTIONS_RESULT',
  GET_DEVOLUTIONS: 'GET_DEVOLUTIONS',
  GET_DEVOLUTIONS_RESULT: 'GET_DEVOLUTIONS_RESULT',
  CANCEL_DEVOLUTION: 'CANCEL_DEVOLUTION',
  CANCEL_DEVOLUTION_RESULT: 'CANCEL_DEVOLUTION_RESULT',
  CLEAR_CANCEL_DEVOLUTION_RESULT: 'CLEAR_CANCEL_DEVOLUTION_RESULT',
  CREATE_DEVOLUTION: 'CREATE_DEVOLUTION',
  CREATE_DEVOLUTION_RESULT: 'CREATE_DEVOLUTION_RESULT',
  CLEAR_CREATE_DEVOLUTION_RESULT: 'CLEAR_CREATE_DEVOLUTION_RESULT',
};

export default ConstantsTpvTransactions;
