import { createApi } from '@reduxjs/toolkit/query/react';
import { request } from '../sagas/axiosConfig';

const axiosBaseQuery = () => async ({
  url, method, data, params, headers
}) => {
  try {
    const result = await request({
      url,
      method,
      data,
      params,
      headers
    });
    return { data: result.data };
  } catch (axiosError) {
    const error = axiosError;
    return {
      error
    };
  }
};

export const api = createApi({
  baseQuery: axiosBaseQuery(),
  reducerPath: 'api',
  endpoints: () => ({})
});

export const { apiService } = api;
