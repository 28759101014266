import React from 'react';
import PropTypes from 'prop-types';

export default function SecureField({
  fieldType, label, customClass, callback, children
}) {
  return (
    <div id={`${fieldType}-number-container`} className={customClass} onClick={() => callback()} aria-hidden="true">
      <label htmlFor={`${fieldType}-number`}>{label}</label>
      <div className="secure-field--input-container" style={{height: '40px'}}>
        <div className="secure-field--input" id={`${fieldType}-number`} />
        <div className="secure-field--actions">
          <span className={`secure-field--action secure-field--action__${fieldType}-icon`}>
            <span className={`secure-field--action-${fieldType}-wrap`}>
              {children}
            </span>
          </span>
        </div>
      </div>
    </div>
  );
}

SecureField.defaultProps = {
  fieldType: undefined,
  label: undefined,
  customClass: undefined,
  callback: undefined,
  children: undefined,
};

SecureField.propTypes = {
  fieldType: PropTypes.string,
  label: PropTypes.string,
  customClass: PropTypes.string,
  callback: PropTypes.func,
  children: PropTypes.node,
};
