import { createSlice } from '@reduxjs/toolkit';
import qs from 'qs';
import { api } from './api';
import i18n from '../assets/language/i18n';
import { downloadFile } from '../utils/Utils';

export const tpvTransactionRequestsApi = api.injectEndpoints({
  tagTypes: ['tpvTransactions'],
  endpoints: (build) => ({
    downloadReceiptRequestPdf: build.query({
      query: (values) => {
        const dataToSend = {id_tpv: values.id};
        return { method: 'POST', url: '/cobros/descargarJustificanteTpvPC', data: qs.stringify(dataToSend) };
      },
      transformResponse: (response, meta, arg) => {
        downloadFile(response, arg?.name);
        return {message: i18n.t('success_download_file')};
      },
      transformErrorResponse: () => ({
        message: i18n.t('error_download_file'),
      }),
    }),
  }),
});

export const {
  useLazyDownloadReceiptRequestPdfQuery,
} = tpvTransactionRequestsApi;

const tpvTransactionRequestsSlice = createSlice({
  name: 'tpvTransactionRequests',
  initialState: {
    appliedFilters: null,
  },
  reducers: {
    setAppliedFilters(state, action) {
      state.appliedFilters = action.payload;
    },
  },
});

export const { setAppliedFilters } = tpvTransactionRequestsSlice.actions;
export default tpvTransactionRequestsSlice.reducer;
