import Colors from '../../../../assets/colors/Colors';

export default {
  textValueGreenColumn: {
    fontSize: 14,
    fontFamily: 'Roboto-Bold',
    color: Colors.TEXT_GREEN,
  },
  textValueRedColumn: {
    fontSize: 14,
    fontFamily: 'Roboto-Bold',
    color: Colors.TEXT_RED,
  },
  textValueOrangeColumn: {
    fontSize: 14,
    fontFamily: 'Roboto-Bold',
    color: Colors.PAY_RETURN,
  },
  textValueYellowColumn: {
    fontSize: 14,
    fontFamily: 'Roboto-Bold',
    color: Colors.TEXT_YELLOW,
  },
  textValueHighGreenColumn: {
    fontSize: 14,
    fontFamily: 'Roboto-Bold',
    color: Colors.TEXT_GREEN,
    backgroundColor: Colors.ICON_COLOR,
    padding: 0,
  },
  textValueHighRedColumn: {
    fontSize: 14,
    fontFamily: 'Roboto-Bold',
    color: Colors.TEXT_RED,
    backgroundColor: Colors.ICON_COLOR,
    padding: 0,
  },
  textValueHighYellowColumn: {
    fontSize: 14,
    fontFamily: 'Roboto-Bold',
    color: Colors.TEXT_YELLOW,
    backgroundColor: Colors.ICON_COLOR,
    padding: 0,
  },
  textValueHighOrangeColumn: {
    fontSize: 14,
    fontFamily: 'Roboto-Bold',
    color: Colors.EXCLUDE,
    backgroundColor: Colors.ICON_COLOR,
    padding: 0,
  },
  div: {
    width: '100%',
    backgroundColor: Colors.BACKGROUND_COLOR_3,
    paddingLeft: 46,
    paddingTop: 20,
    paddingRight: 46,
    paddingBottom: 20,
  },
  row: {
    marginBottom: 40,
  },
  col: { marginRight: 100 },
  textTitle1: {
    fontSize: 22,
    color: Colors.TEXT_COLOR,
    fontFamily: 'Roboto-Medium',
    verticalAlign: 'middle',
    marginRight: 4,
  },
  textTitle2: {
    fontSize: 22,
    color: Colors.TEXT_COLOR,
    fontFamily: 'Roboto-Bold',
    fontWeight: '500',
    verticalAlign: 'middle',
  },
  textPay: {
    fontSize: 16,
    color: Colors.TEXT_COLOR,
    fontFamily: 'Roboto-Medium',
    verticalAlign: 'middle',
    fontWeight: '500',
    paddingLeft: 16,
    paddingRight: 16,
  },
  bluePoint: {
    width: 10, height: 10, backgroundColor: Colors.PRIMARY_COLOR, borderRadius: 5,
  },
  blueLine: {
    width: '100%', height: 2, backgroundColor: Colors.PRIMARY_COLOR,
  },
  greyPoint: {
    width: 10, height: 10, backgroundColor: Colors.PROGRESS_GREY_LINE, borderRadius: 5,
  },
  greyLine: {
    width: '100%', height: 2, backgroundColor: Colors.PROGRESS_GREY_LINE,
  },
  buttonLink: {
    fontFamily: 'Roboto-Regular',
    fontSize: 14,
  },
  iconSave: {
    color: Colors.OTHERS,
  },
  iconEdit: {
    color: Colors.PRIMARY_COLOR,
  },
  iconDelete: {
    color: Colors.TEXT_RED,
  },
  buttonPrimary: {
    fontFamily: 'Roboto-Bold',
    fontWeight: '600',
    fontSize: 16,
    minWidth: 90,
  },
  buttonDefault: {
    fontFamily: 'Roboto-Regular',
    fontSize: 14,
    width: 90,
  },
  select: {
    width: 100,
    fontFamily: 'Roboto-Regular',
    fontSize: 14,
    borderRadius: 4,
    borderWidth: 1,
    borderColor: 'white',
  },
  select2: {
    width: 160,
    fontFamily: 'Roboto-Regular',
    fontSize: 14,
    borderRadius: 4,
    borderWidth: 1,
    borderColor: 'white',
  },
  textOptionValue: {
    fontSize: 14,
    color: Colors.INPUT_COLOR,
    fontFamily: 'Roboto-Regular',
  },
  input: {
    borderRadius: 4,
    borderWidth: 1,
    borderColor: 'white',
    fontSize: 14,
    width: 188,
    marginBottom: 8,
    display: 'block',
  },
  textTitlePopconfirm: {
    fontSize: 16,
    fontFamily: 'Roboto-Medium',
  },
  textOkButtonPopconfirm: {
    fontFamily: 'Roboto-Bold',
    fontWeight: '600',
    fontSize: 16,
    color: 'white',
  },
  textCancelButtonPopconfirm: {
    fontFamily: 'Roboto-Regular',
    fontSize: 16,
  },
  icon: {
    color: Colors.PRIMARY_COLOR,
  },
  inputModal: {
    width: '100%',
    borderRadius: 4,
    borderColor: Colors.BORDER_COLOR,
    fontFamily: 'Roboto-Regular',
    fontSize: 16,
  },
  inputRefund: {
    width: '100%',
    borderRadius: 4,
    borderColor: Colors.BORDER_COLOR,
    fontFamily: 'Roboto-Regular',
    fontSize: 16,
  },
  selectModal: {
    fontFamily: 'Roboto-Regular',
    fontSize: 16,
    borderRadius: 4,
    borderWidth: 1,
    borderColor: 'white',
  },
  buttonPrimaryModal: {
    fontFamily: 'Roboto-Bold',
    fontWeight: '600',
    fontSize: 16,
    width: '100%',
  },
  buttonPrimaryRefund: {
    fontFamily: 'Roboto-Bold',
    fontWeight: '600',
    fontSize: 16,
    width: '50%',
  },
};
