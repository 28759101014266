const ConstantsReservationStatus = {
  Pending: '0',
  Validate: '1',
  Exception: '2',
  Exclude: '3',
  PreConciliate: '4',
  Conciliate: '5',
  Facture: '6',
};

export default ConstantsReservationStatus;
