import React, { useEffect } from 'react';
import {
  BackTop,
  Col, Image,
  Row,
  Select,
  Spin,
  Typography,
} from 'antd';
import 'antd/dist/antd.less';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Colors from '../../../assets/colors/Colors';
import { actionLoading } from '../../../store/reducers/loading/loadingActions';
import SecureFields from './SecureFields';

import PaymentResultModal from './paymentResult/PaymentResultModal';

import {
  actionGetFiltersTpvManual,
} from '../../../store/reducers/tpvManual/tpvManualActions';
import logosPay from '../../../assets/img/logospago.jpg';
import { useSendTransactionForTpvManualApiMutation, useSendTransactionForPaymentApiMutation} from '../../../services/tpvManual';

const { Text } = Typography;
const { Option } = Select;

const styles = {
  icon: {
    marginTop: 20,
    fontSize: 100,
    color: Colors.TEXT_GREEN,
  },
  iconRed: {
    marginTop: 20,
    fontSize: 100,
    color: Colors.TEXT_RED,
  },
  textResultValue: {
    fontSize: 40,
    color: Colors.INPUT_COLOR,
    fontFamily: 'Roboto-Regular',
  },
  textOptionValue: {
    fontSize: 14,
    color: Colors.INPUT_COLOR,
    fontFamily: 'Roboto-Regular',
  },
  input: {
    borderRadius: 4,
    borderColor: Colors.BORDER_COLOR,
    fontFamily: 'Roboto-Regular',
    fontSize: 16,
  },
  buttonPrimary: {
    fontFamily: 'Roboto-Bold',
    fontWeight: '600',
    fontSize: 16,
    width: 90,
  },
  select: {
    fontFamily: 'Roboto-Regular',
    fontSize: 16,
    borderRadius: 4,
    borderWidth: 1,
    borderColor: 'white',
  },
};

export default function TpvManual() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const applyFilters = useSelector((state) => state.reducerGetFiltersTpvManualResult);
  const loading = useSelector((state) => state.reducerLoading);

  const [
    sendTransaction,
    {
      data: dataSendTransaction,
      isLoading: isLoadingSendTransaction,
      isSuccess: isSuccessSendTransaction,
      error: errorSendTransaction,
      reset: resetSendTransaction
    }
  ] = useSendTransactionForTpvManualApiMutation({
    fixedCacheKey: 'shared-create-payment-request'
  });

  useEffect(() => {
    if (!loading) {
      dispatch(actionLoading(true));
      dispatch(actionGetFiltersTpvManual());
    }
  }, []);

  const handleForm = (cardData) => {
    if (cardData !== null) {
      sendTransaction(cardData);
    }
  };
  // 4188 2021 1015 0644

  const hotelsOptions = [];
  applyFilters.hotels.forEach((i) => {
    hotelsOptions.push(
      <Option key={i.value} value={i.value}>
        <Text style={styles.textOptionValue}>
          {i.label}
        </Text>
      </Option>,
    );
  });

  const currencyOptions = [];
  applyFilters.currencies.forEach((i) => {
    if (i.currency !== 'CUP') {
      currencyOptions.push(
        <Option key={i.currency} value={i.currency}>
          <Text style={styles.textOptionValue}>
            {i.label}
          </Text>
        </Option>,
      );
    }
  });

  return (
    <Spin tip={t('we_are_processing_your_payment')} spinning={isLoadingSendTransaction} size="large">
      <BackTop />
      <Row style={{ marginTop: 20 }} justify="center">
        {isSuccessSendTransaction || errorSendTransaction ? (
          <PaymentResultModal
            paymentData={isSuccessSendTransaction ? dataSendTransaction : errorSendTransaction}
            isSuccess={isSuccessSendTransaction}
            visible
            closeModal={() => resetSendTransaction()}
          />
        ) : ''}
        <Col xs={20} md={12}>
          <SecureFields
            config={{
              merchantID: process.env.REACT_APP_PCI_PROXY_MERCHANT_ID,
              fields: {
                cardNumber: {
                  placeholderElementId: 'card-number',
                  inputType: 'tel'
                },
                cvv: {
                  placeholderElementId: 'cvv-number',
                  inputType: 'tel'
                }
              },
              options: {}
            }}
            handleForm={handleForm}
            hotelsOptions={hotelsOptions}
            currencyOptions={currencyOptions}
          />
          <Row style={{ marginTop: 20 }}>
            <Image
              preview={false}
              src={logosPay}
            />
          </Row>
        </Col>
      </Row>
    </Spin>
  );
}
