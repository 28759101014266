import React from 'react';
import {
  Button,
  Typography,
} from 'antd';
import 'antd/dist/antd.less';

import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import Colors from '../../../assets/colors/Colors';

const { Text } = Typography;

const styles = {
  iconActive: {
    color: Colors.PRIMARY_COLOR,
    fontSize: 24
  },
  iconInactive: {
    color: 'white',
    fontSize: 24
  },
  textActive: {
    fontSize: 14,
    color: Colors.PRIMARY_COLOR,
    fontFamily: 'Roboto-Regular',
    verticalAlign: 'middle'
  },
  textInactive: {
    fontSize: 14,
    color: 'white',
    fontFamily: 'Roboto-Regular',
    verticalAlign: 'middle'
  },
  lineActive: {
    backgroundColor: Colors.PRIMARY_COLOR,
    height: 3,
    width: '100%'
  },
  lineInactive: { backgroundColor: 'transparent', height: 3, width: '100%' },
  tab: { width: '100px' },
};

export default function BurgerMenuItem(props) {
  const {
    clickHandler,
    route,
    label,
    icon
  } = props;

  const location = useLocation();

  return (
    <Button
      type="text"
      onClick={clickHandler}
      className="items-center flex"
      icon={React.cloneElement(icon, {
        className: 'mr-2',
        style: location.pathname === route
          ? styles.iconActive
          : styles.iconInactive
      })}
    >
      <Text
        style={
                        location.pathname === route
                          ? styles.textActive
                          : styles.textInactive
                      }
      >
        {label}
      </Text>
    </Button>

  );
}
BurgerMenuItem.defaultProps = {
  clickHandler: undefined,
  route: undefined,
  label: undefined,
  icon: undefined
};

BurgerMenuItem.propTypes = {
  clickHandler: PropTypes.func,
  route: PropTypes.string,
  label: PropTypes.string,
  icon: PropTypes.element,
};
