import React from 'react';
import { Row, Col, Typography } from 'antd';
import PropTypes from 'prop-types';
// import { useTranslation } from 'react-i18next';
import Colors from '../../../assets/colors/Colors';
import SendPrebillProcessBody from './SendPrebillProcessBody';
import StartConciliationProcessBody from './StartConciliationProcessBody';
import SendBillProcessBody from './SendBillProcessBody';

const styles = {
  borderBottom: { borderBottom: '1px solid #E2E8F0' },
  textValueGreenColumn: {
    color: Colors.TEXT_GREEN,
  },
  textValueRedColumn: {
    color: Colors.TEXT_RED,
  },
  textValueYellowColumn: {
    color: Colors.TEXT_YELLOW,
  },
};

const SEND_PRE_BILL_KEY = 'SEND_PRE_BILL';
const SEND_PRE_BILL_KEY_OLD = 'SEND_PREBILL';
const SEND_BILL_KEY = 'SEND_BILL';
const START_CONCILIATION_KEY = 'START_CONCILIATION';

function getProcessBody(type, bulkAction) {
  switch (type) {
    case SEND_PRE_BILL_KEY:
    case SEND_PRE_BILL_KEY_OLD:
      return (<SendPrebillProcessBody bulkAction={bulkAction} />);
    case START_CONCILIATION_KEY:
      return (<StartConciliationProcessBody bulkAction={bulkAction} />);
    case SEND_BILL_KEY:
      return (<SendBillProcessBody bulkAction={bulkAction} />);
    default:
      return '';
  }
}

function getColorByStatus(status) {
  switch (status) {
    case 'Completado':
      return Colors.TEXT_GREEN;
    case 'Error':
      return Colors.TEXT_RED;
    case 'Pendiente':
    default:
      return Colors.TEXT_YELLOW;
  }
}

export default function AsyncProcessItemList(props) {
  const { Text } = Typography;

  const { bulkAction, addBorderBottom } = props;

  const styleContainer = {
    ...(addBorderBottom ? styles.borderBottom : {})
  };

  const processBody = getProcessBody(bulkAction?.tipo, bulkAction);

  return (
    <div key={bulkAction?.id} style={styleContainer} className="px-0 py-2">
      {processBody}
      <Row className="mt-2">
        <Col span={24}>
          <Text
            className="text-[14px] font-['Roboto-Bold']"
            style={{ color: getColorByStatus(bulkAction?.estado) }}
          >
            {bulkAction?.estado}

          </Text>
        </Col>
      </Row>
    </div>
  );
}

AsyncProcessItemList.defaultProps = {
  bulkAction: {},
  addBorderBottom: true
};

AsyncProcessItemList.propTypes = {
  bulkAction: PropTypes.shape({
    id: PropTypes.string,
    id_usuario: PropTypes.string,
    tipo: PropTypes.string,
    estado: PropTypes.string,
    created_at: PropTypes.string,
    updated_at: PropTypes.string,
    datos: PropTypes.shape({
      mensaje: PropTypes.string,
      id_job: PropTypes.string || PropTypes.number,
      nombre_empresa: PropTypes.string,
    }),
  }),
  addBorderBottom: PropTypes.bool

};
