import React from 'react';
import { Row, Col, Typography } from 'antd';
import PropTypes from 'prop-types';
// import { useTranslation } from 'react-i18next';

export default function StartConciliationProcessBody(props) {
  const { Text } = Typography;

  const { bulkAction} = props;

  const title = `ID del proceso: ${bulkAction?.id} - Iniciar preconciliación`;

  return (
    <div>
      <Row>
        <Col>
          <Text className="font-bold">{title}</Text>
        </Col>
      </Row>
      <Row>
        <Col>
          <Text className="font-bold">{'Fecha de inicio: '}</Text>
          <Text>{bulkAction?.created_at}</Text>
        </Col>
      </Row>
      <Row className="mt-3 mb-1">
        <Col>
          <Text className="font-bold">Información de la conciliación:</Text>
        </Col>
      </Row>
      <Row>
        <Col>
          <Text className="font-bold">{'Nombre: '}</Text>
          <Text>{bulkAction?.datos?.nombre}</Text>
        </Col>
      </Row>
      {bulkAction?.datos?.nombre_empresa ? (
        <Row>
          <Col>
            <Text className="font-bold">{'Empresa: '}</Text>
            <Text>{bulkAction?.datos?.nombre_empresa}</Text>
          </Col>
        </Row>
      ) : null}
      <Row>
        <Col>
          <Text className="font-bold">{'Fecha de inicio: '}</Text>
          <Text>{bulkAction?.datos?.fecha_inicio}</Text>
        </Col>
      </Row>
      <Row>
        <Col>
          <Text className="font-bold">{'Fecha final: '}</Text>
          <Text>{bulkAction?.datos?.fecha_final}</Text>
        </Col>
      </Row>
    </div>
  );
}

StartConciliationProcessBody.defaultProps = {
  bulkAction: {},
};

StartConciliationProcessBody.propTypes = {
  bulkAction: PropTypes.shape({
    id: PropTypes.string,
    id_usuario: PropTypes.string,
    tipo: PropTypes.string,
    estado: PropTypes.string,
    created_at: PropTypes.string,
    updated_at: PropTypes.string,
    datos: PropTypes.shape({
      nombre: PropTypes.string,
      id_job: PropTypes.string || PropTypes.number,
      nombre_empresa: PropTypes.string,
      fecha_inicio: PropTypes.string,
      fecha_final: PropTypes.string,
    }),
  }),
};
