import ConstantsTpvManual from './tpvManualConstants';

export const actionGetFiltersTpvManual = () => ({
  type: ConstantsTpvManual.GET_FILTERS_TPV_MANUAL,
});

export const actionGetFiltersTpvManualResult = (result, msg, filters) => ({
  type: ConstantsTpvManual.GET_FILTERS_TPV_MANUAL_RESULT,
  result,
  msg,
  filters,
});
