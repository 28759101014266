import ConstantsLogin from './loginConstants';

const reducerResetPasswordResult = (state = { result: false, msg: null }, action = {}) => {
  switch (action.type) {
    case ConstantsLogin.RESET_PASSWORD_RESULT:
      return action;
    case ConstantsLogin.CLEAR_RESET_PASSWORD_RESULT:
      return {
        result: false,
        msg: null,
      };
    default:
      return state;
  }
};

export default reducerResetPasswordResult;
