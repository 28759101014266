import React, { useState, useEffect } from 'react';
import {
  Button,
  Col,
  InputNumber,
  Modal,
  Typography,
  Row,
  Form,
  Input,
  Checkbox
} from 'antd';

import {
  CloseCircleOutlined,
  EuroCircleOutlined,
} from '@ant-design/icons';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import styles from './styles/stylesIndex';

export default function RefundModal(props) {
  const { Text } = Typography;
  const { TextArea } = Input;

  const { t } = useTranslation();

  const { closeModal, transaction, submitFormHandler } = props;

  const [refundAmount, setRefundAmount] = useState(0);
  const [comments, setComments] = useState('');
  const [refundTotal, setRefundTotal] = useState(false);

  const [form] = Form.useForm();

  useEffect(() => {
    setRefundAmount(refundTotal ? transaction.amount : 0);
    form.setFieldsValue({ amount: refundTotal ? transaction.amount : 0 });
  }, [refundTotal]);

  return (
    <Modal
      destroyOnClose
      title={(
        <Text style={styles.textTitlePopconfirm}>
          {t('text_request_return')}
        </Text>
      )}
      open
      footer={null}
      onCancel={() => {
        closeModal();
      }}
      closeIcon={<CloseCircleOutlined style={styles.icon} />}
    >
      <Row justify="center">
        <Col span={20}>
          <Form
            form={form}
            name="basic"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            autoComplete="off"
            onFinish={() => {
              submitFormHandler(refundAmount, comments);
            }}
          >
            <Row>
              <Col span={24}>

                <Form.Item
                  name="amount"
                  label={(

                    <Text style={styles.textTitleDescription}>
                      {t('text_import')}
                    </Text>
                  )}
                  rules={[
                    {
                      required: true,
                      message: t('required_amount_field')

                    },
                  ]}
                >
                  <InputNumber
                    prefix={<EuroCircleOutlined style={styles.icon} />}
                    placeholder={t('text_import')}
                    onChange={(text) => {
                      setRefundAmount(text);
                    }}
                    max={transaction.amount}
                    value={refundAmount}
                    disabled={refundTotal}
                    decimalSeparator=","
                  />

                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item
                  name="comments"
                  label={(
                    <Text style={styles.textTitleDescription}>
                      {t('text_comments')}
                    </Text>
                  )}
                  rules={[
                    {
                      required: true,
                      message: t('required_comment_field')
                    },
                  ]}
                >
                  <TextArea
                    onChange={(e) => {
                      setComments(e.target.value);
                    }}
                    value={refundAmount}
                    style={styles.inputRefund}
                  />

                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={18} offset={8}>
                <Form.Item
                  name="refundTotal"
                >
                  <Checkbox
                    checked={refundTotal}
                    onChange={() => {
                      setRefundAmount((refundTotal) ? transaction.amount : 0);
                      setRefundTotal(!refundTotal);
                    }}
                  >
                    Devolver el total del cargo
                  </Checkbox>
                </Form.Item>

              </Col>
            </Row>

            <Row>
              <Col span={24}>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={styles.buttonPrimaryModal}
                >
                  {t('text_button_solicit_refund')}
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>

    </Modal>
  );
}

RefundModal.defaultProps = {
  closeModal: undefined,
  submitFormHandler: undefined,
  transaction: undefined,
};

RefundModal.propTypes = {
  closeModal: PropTypes.func,
  submitFormHandler: PropTypes.func,
  transaction: PropTypes.shape({
    moneda: PropTypes.string,
    amount: PropTypes.string,
  }),
};
