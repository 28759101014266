import Colors from '../../../../assets/colors/Colors';

export default {
  textValuePending: {
    fontSize: 14,
    fontFamily: 'Roboto-Bold',
    color: Colors.PENDING,
  },
  textValueValidate: {
    fontSize: 14,
    fontFamily: 'Roboto-Bold',
    color: Colors.VALIDATE,
  },
  textValueException: {
    fontSize: 14,
    fontFamily: 'Roboto-Bold',
    color: Colors.EXCEPTION,
  },
  textValueExclude: {
    fontSize: 14,
    fontFamily: 'Roboto-Bold',
    color: Colors.EXCLUDE,
  },
  textValuePreConciliate: {
    fontSize: 14,
    fontFamily: 'Roboto-Bold',
    color: Colors.PRE_CONCILIATE,
  },
  textValueConciliate: {
    fontSize: 14,
    fontFamily: 'Roboto-Bold',
    color: Colors.CONCILIATE,
  },
  textValueFacture: {
    fontSize: 14,
    fontFamily: 'Roboto-Bold',
    color: Colors.FACTURE,
  },
  textValueOther: {
    fontSize: 14,
    fontFamily: 'Roboto-Bold',
    color: Colors.OTHERS,
  },
  textValueHighPending: {
    fontSize: 14,
    fontFamily: 'Roboto-Bold',
    color: Colors.PENDING,
    backgroundColor: Colors.ICON_COLOR,
    padding: 0,
  },
  textValueHighValidate: {
    fontSize: 14,
    fontFamily: 'Roboto-Bold',
    color: Colors.VALIDATE,
    backgroundColor: Colors.ICON_COLOR,
    padding: 0,
  },
  textValueHighException: {
    fontSize: 14,
    fontFamily: 'Roboto-Bold',
    color: Colors.EXCEPTION,
    backgroundColor: Colors.ICON_COLOR,
    padding: 0,
  },
  textValueHighExclude: {
    fontSize: 14,
    fontFamily: 'Roboto-Bold',
    color: Colors.EXCLUDE,
    backgroundColor: Colors.ICON_COLOR,
    padding: 0,
  },
  textValueHighPreConciliate: {
    fontSize: 14,
    fontFamily: 'Roboto-Bold',
    color: Colors.PRE_CONCILIATE,
    backgroundColor: Colors.ICON_COLOR,
    padding: 0,
  },
  textValueHighConciliate: {
    fontSize: 14,
    fontFamily: 'Roboto-Bold',
    color: Colors.CONCILIATE,
    backgroundColor: Colors.ICON_COLOR,
    padding: 0,
  },
  textValueHighFacture: {
    fontSize: 14,
    fontFamily: 'Roboto-Bold',
    color: Colors.FACTURE,
    backgroundColor: Colors.ICON_COLOR,
    padding: 0,
  },
  textValueHighOther: {
    fontSize: 14,
    fontFamily: 'Roboto-Bold',
    color: Colors.OTHERS,
    backgroundColor: Colors.ICON_COLOR,
    padding: 0,
  },
  input: {
    borderRadius: 4,
    borderWidth: 1,
    borderColor: 'white',
    fontSize: 14,
    width: 188,
    marginBottom: 8,
    display: 'block',
  },
  buttonPrimary: {
    fontFamily: 'Roboto-Bold',
    fontWeight: '600',
    fontSize: 16,
    width: 90,
  },
  buttonDefault: {
    fontFamily: 'Roboto-Regular',
    fontSize: 14,
    width: 130,
  },
  textValuePayStatusCharge: {
    fontSize: 14,
    fontFamily: 'Roboto-Bold',
    color: Colors.PAY_CHARGE,
  },
  textValuePayStatusReturn: {
    fontSize: 14,
    fontFamily: 'Roboto-Bold',
    color: Colors.PAY_RETURN,
  },
  textValueAdgCollect: {
    fontSize: 14,
    fontFamily: 'Roboto-Bold',
    color: Colors.ADG_COLLECT,
  },
  textValueHotelCollect: {
    fontSize: 14,
    fontFamily: 'Roboto-Bold',
    color: Colors.HOTEL_COLLECT,
  },
  iconSave: {
    color: Colors.OTHERS,
  },
  iconDelete: {
    color: Colors.TEXT_RED,
  },
  buttonLink: {
    fontFamily: 'Roboto-Regular',
    fontSize: 14,
  },
  textOkButtonPopconfirm: {
    fontFamily: 'Roboto-Bold',
    fontWeight: '600',
    fontSize: 16,
    color: 'white',
  },
  textCancelButtonPopconfirm: {
    fontFamily: 'Roboto-Regular',
    fontSize: 16,
  },
};
