const ConstantsRoutes = {
  PAYMENTS: '/payments',
  TPVS: '/payments/tpvs',
  INVITATIONS: '/payments/invitations',
  INVITATION_ADD: '/payments/invitations/add',
  TPV_SECURE_MANUAL: '/payments/invitations/tpv_secure_manual',
  PRE_CONCILIATION: '/payments/preConciliation',
  CONCILIATIONS: '/payments/conciliations',
  CONCILIATION: '/payments/conciliations/conciliation',
  TPV_MANUAL: '/payments/tpvManual',
  TPV_TRANSACTIONS: '/payments/tpvTransactions',
  COLLECTION_REQUESTS: '/payments/collectionRequests',
  PAYMENT_REQUESTS: '/payments/paymentRequests',
};

export default ConstantsRoutes;
