import React from 'react';
import PropTypes from 'prop-types';

import {
  CloseCircleOutlined,
  CheckCircleOutlined
} from '@ant-design/icons';

import Colors from '../../assets/colors/Colors';

export default function PasswordFeature(props) {
  const {
    label,
    value,
  } = props;

  return (
    <div className=" ">
      { value ? <CheckCircleOutlined style={{color: Colors.TEXT_GREEN}} /> : <CloseCircleOutlined style={{color: Colors.TEXT_RED}} />}
      <span className="ml-1">{label}</span>
    </div>
  );
}

PasswordFeature.defaultProps = {
  value: false,
  label: ''
};

PasswordFeature.propTypes = {
  value: PropTypes.bool,
  label: PropTypes.string
};
