import React, { useEffect } from 'react';
import {
  Row,
  Table,
  Typography,
  Tooltip, Button, message
} from 'antd';
import PropTypes from 'prop-types';

import { format } from 'date-fns';
import { es } from 'date-fns/locale';

import 'antd/dist/antd.less';
import {
  DownloadOutlined,
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import i18n from '../../../assets/language/i18n';
import ConstantsDate from '../../../constants/ConstantsDate';

import { useLazyGetAttachedFileContentApiQuery} from '../../../services/collectionRequests';

import './styles/styles.css';

const { Text } = Typography;
export default function Attachments(props) {
  const { t } = useTranslation();
  const loading = useSelector((state) => state.reducerLoading);

  const { collectionRequest } = props;

  const [
    getAttachedFile,
    {
      data: attachedFileResponse,
      isFetching,
      error
    }
  ] = useLazyGetAttachedFileContentApiQuery();

  useEffect(() => {
    if (error) {
      message.error(error.message);
    }
  }, [error]);

  const columns = [
    {
      title: (
        <Text className="text">
          {t('name')}
        </Text>),
      dataIndex: 'nombre',
      key: 'nombre',
      sorter: {
        compare: (a, b) => a.nombre.localeCompare(b.nombre, i18n.language),
        multiple: 10,
      },
      render: (text) => (
        <Text className="text">
          {text}
        </Text>
      ),
    },
    {
      title: <Text className="text">{t('text_creation_date')}</Text>,
      dataIndex: 'created_at',
      key: 'created_at',
      sorter: {
        compare: (a, b) => a.created_at - b.created_at,
        multiple: 4,
      },
      align: 'center',
      responsive: ['md'],
      render: (text) => {
        const dateObject = text ? new Date(text.replace(' ', 'T')) : null;

        return (
          <Text className="text">
            {!text
              ? ''
              : format(dateObject, ConstantsDate.DATE_HOUR_FORMAT, {
                locale: es,
              })}
          </Text>
        );
      },
    },
    {
      title: (
        <Text className="text">
          {t('text_actions')}
        </Text>),
      dataIndex: 'ruta_descarga',
      key: 'ruta_descarga',
      responsive: ['md'],
      render: (text, record) => (
        <div>
          <Tooltip title={t('download_file')}>
            <Button
              type="link"
              htmlType="button"
              onClick={() => {
                getAttachedFile({
                  id: record.id,
                  name: record.nombre
                });
              }}
              icon={<DownloadOutlined style={{ marginRight: '7px', fontSize: '20px' }} />}
              className="button-link"
              loading={isFetching}
            />
          </Tooltip>
        </div>
      ),
    },
  ];

  return (
    <div className="attachment-container">
      <Row align="middle">
        <Table
          rowKey="id_adjunto"
          style={{ width: '100%' }}
          pagination={
            { hideOnSinglePage: true }
          }
          size="small"
          loading={loading}
          columns={columns}
          dataSource={collectionRequest.adjuntos}
        />
      </Row>
    </div>
  );
}

Attachments.defaultProps = {
  collectionRequest: undefined
};

Attachments.propTypes = {
  collectionRequest: PropTypes.shape({
    adjuntos: PropTypes.arrayOf(PropTypes.shape({})),
    id_oc: PropTypes.string,
  })
};
