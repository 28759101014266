import { Result, Typography } from 'antd';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Colors from './assets/colors/Colors';

const { Text } = Typography;

const styles = {
  text: {
    fontSize: 24,
    color: Colors.PRIMARY_COLOR,
    fontFamily: 'SofiaProMedium',
    textAlign: 'justify',
    textJustify: 'inter-word',
  },
  textTitle: {
    fontSize: 36,
    fontFamily: 'SofiaProBold',
    color: Colors.TEXT_RED,
    textAlign: 'center',
  },
};

class PageNotFound extends Component {
  render() {
    const { t } = this.props;
    return (
      <div>
        <Result
          status="404"
          title={(
            <Text style={styles.textTitle}>
              404
            </Text>
)}
          subTitle={(
            <Text style={styles.text}>
              {t('error_page_not_found')}
            </Text>
)}
        />
      </div>
    );
  }
}

PageNotFound.defaultProps = {
  t: undefined,
};

PageNotFound.propTypes = {
  t: PropTypes.func,
};

export default withTranslation()(PageNotFound);
