import React from 'react';
import PropTypes from 'prop-types';
import { Spin } from 'antd';
import AsyncProcessItemList from './AsyncProcessItemList';

const styles = {
  listContainer: {
    width: '300px',
    maxHeight: '500px',
    overflowY: 'auto',
  },
};

export default function AsyncProcessesList(props) {
  const {
    bulkActions,
    isLoading
  } = props;

  return (
    <div style={styles.listContainer}>
      <Spin spinning={isLoading}>
        {bulkActions?.map((bulkAction, index) => (<AsyncProcessItemList key={bulkAction.id} bulkAction={bulkAction} addBorderBottom={index !== bulkActions.length - 1} />))}
      </Spin>
    </div>
  );
}

AsyncProcessesList.defaultProps = {
  bulkActions: [],
  isLoading: false,
};

AsyncProcessesList.propTypes = {
  isLoading: PropTypes.bool,
  bulkActions: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    id_usuario: PropTypes.string,
    tipo: PropTypes.string,
    estado: PropTypes.string,
    created_at: PropTypes.string,
    updated_at: PropTypes.string,
    datos: PropTypes.shape({
      mensaje: PropTypes.string,
      id_job: PropTypes.string || PropTypes.number,
    }),
  })),

};
