import React, { useEffect, useState } from 'react';
import {
  Button,
  Col,
  Collapse,
  DatePicker,
  Form,
  Input,
  message,
  Row,
  Select,
  Typography,
  Tooltip
} from 'antd';
import 'antd/dist/antd.less';
import { CreditCardOutlined, SearchOutlined, } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import { actionLoading } from '../../../store/reducers/loading/loadingActions';
import { actionSetReservation } from '../../../store/reducers/payments/paymentsActions';
import ConstantsDate from '../../../constants/ConstantsDate';
import {
  actionGetFiltersTpvTransactions,
  actionGetTpvTransactions
} from '../../../store/reducers/tpvTransactions/tpvTransactionsActions';

import {
  getIsAdminPermission,
} from '../../../utils/Utils';

const { Text } = Typography;
const { Panel } = Collapse;
const { Option } = Select;
const { RangePicker } = DatePicker;

const formRef = React.createRef();

function getOptionsForSelect(list, className, value = 'value', label = 'label') {
  return list?.map((i) => (
    <Option key={`${i[value]}`} value={i[value]}>
      <Text className={className}>
        {i[label]}
      </Text>
    </Option>
  ));
}

export default function FilterTpvTransactions() {
  const [form] = Form.useForm();

  const [numberLocator, setNumberLocator] = useState('');
  const [internalReference, setInternalReference] = useState('');

  const [idTransaction, setIdTransaction] = useState('');
  const [nameHotel, setNameHotel] = useState('');
  const [dateCreateStart, setDateCreateStart] = useState(null);
  const [dateCreateEnd, setDateCreateEnd] = useState(null);
  const [statePay, setStatePay] = useState(null);
  const [chain, setChain] = useState('');
  const [currency, setCurrency] = useState('');
  const [cardLastNumber, setCardLastNumber] = useState('');

  const [dateInitSendStart, setDateInitSendStart] = useState(null);
  const [dateInitSendEnd, setDateInitSendEnd] = useState(null);

  const [dateEndSendStart, setDateEndSendStart] = useState(null);
  const [dateEndSendEnd, setDateEndSendEnd] = useState(null);

  const [operationType, setOperationType] = useState('');

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const filters = useSelector((state) => state.reducerFiltersTpvTransactions);
  const loading = useSelector((state) => state.reducerLoading);
  const jwtData = useSelector(
    (state) => state.reducerJwtData
  );

  const isCubatourProfile = jwtData?.userInfo?.permisos?.tipo_agente === 'ctt';

  useEffect(() => {
    if (!loading) {
      dispatch(actionLoading(true));
      dispatch(actionGetFiltersTpvTransactions());
    }
  }, []);

  useEffect(() => {
    if (filters.msg !== null && filters.result === false) {
      message.error(filters.msg);
    }
  });

  const clickClearFilters = () => {
    setNumberLocator('');
    setInternalReference('');
    setIdTransaction('');
    setNameHotel('');
    setDateCreateStart(null);
    setDateCreateEnd(null);
    setStatePay(null);
    setChain('');
    setCurrency('');
    setCardLastNumber('');
    setDateInitSendStart(null);
    setDateInitSendEnd(null);
    setDateEndSendStart(null);
    setDateEndSendEnd(null);
    setOperationType('');
    formRef.current.resetFields();
  };

  const clickFilterButton = (e) => {
    e.preventDefault();
    callFilter();
  };

  function callFilter() {
    dispatch(actionLoading(true));
    dispatch(actionGetTpvTransactions(
      nameHotel,
      !dateCreateStart?.isValid() ? '' : format(dateCreateStart.toDate(), ConstantsDate.DATE_FORMAT_REQUEST, {
        locale: es,
      }),
      !dateCreateEnd?.isValid() ? '' : format(dateCreateEnd.toDate(), ConstantsDate.DATE_FORMAT_REQUEST, {
        locale: es,
      }),
      numberLocator,
      idTransaction,
      statePay,
      chain,
      currency,
      cardLastNumber,
      internalReference,
      operationType,
      !dateInitSendStart?.isValid() ? '' : format(dateInitSendStart.toDate(), ConstantsDate.DATE_FORMAT_REQUEST, {
        locale: es,
      }),
      !dateInitSendEnd?.isValid() ? '' : format(dateInitSendEnd.toDate(), ConstantsDate.DATE_FORMAT_REQUEST, {
        locale: es,
      }),
      !dateEndSendStart?.isValid() ? '' : format(dateEndSendStart.toDate(), ConstantsDate.DATE_FORMAT_REQUEST, {
        locale: es,
      }),
      !dateEndSendEnd?.isValid() ? '' : format(dateEndSendEnd.toDate(), ConstantsDate.DATE_FORMAT_REQUEST, {
        locale: es,
      }),
    ));
  }

  const datesDifferenceValidator = (rule, value) => {
    if (!value || value?.length < 2) {
      return Promise.resolve();
    }
    if (!getIsAdminPermission() && !datesDifferenceLessThanAux(value[0], value[1])) {
      return Promise.reject(t('cant_filter_by_more_than_3_months'));
    }
    return Promise.resolve();
  };

  const datesDifferenceLessThanAux = (dateStart, dateEnd, daysDifference = 90) => {
    if (!dateStart || !dateEnd) return true;
    const date1 = dateStart.toDate();
    const date2 = dateEnd.toDate();

    const oneDay = 24 * 60 * 60 * 1000; // Number of milliseconds in one day
    const differenceInDays = Math.round(Math.abs((date1 - date2) / oneDay));
    if (differenceInDays > daysDifference) {
      // eslint-disable-next-line prefer-promise-reject-errors
      return false;
    }
    return true;
  };

  const datesDifferenceValidation = () => datesDifferenceLessThanAux(dateCreateStart, dateCreateEnd)
  && datesDifferenceLessThanAux(dateInitSendStart, dateInitSendEnd)
  && datesDifferenceLessThanAux(dateEndSendStart, dateEndSendEnd);

  const datesValidation = () => {
    if (dateCreateStart?.isValid() && dateCreateEnd?.isValid()) {
      return true;
    }
    if (dateInitSendStart?.isValid() && dateInitSendEnd?.isValid()) {
      return true;
    }
    if (dateEndSendStart?.isValid() && dateEndSendEnd?.isValid()) {
      return true;
    }
    return false;
  };

  function isDisabledSubmit() {
    if (!getIsAdminPermission() && !datesDifferenceValidation()) {
      return true;
    }
    if (numberLocator?.length || idTransaction?.length || internalReference?.length) {
      return false;
    }
    return !datesValidation();
  }

  const hotelsOptions = getOptionsForSelect(filters?.hotels.filter((item) => !chain || item.id_cadena === chain), 'text-option-value');
  const statesPayOptions = getOptionsForSelect(filters?.payStates, 'text-option-value', 'estado', 'label');
  const chainsOptions = getOptionsForSelect(filters?.chains, 'text-option-value', 'id_cadena', 'nombre_cadena');
  const currencyOptions = getOptionsForSelect(filters?.currencies, 'text-option-value', 'currency', 'label');
  const operationTypeOptions = getOptionsForSelect(filters?.operationTypes, 'text-option-value');

  return (
    <Row align="middle" className="row-filter remaquia">
      <Collapse
        accordion
        defaultActiveKey={['1']}
        style={{ width: '100%' }}
        expandIconPosition="end"
      >
        <Panel
          key="1"
          header={(
            <Text classname="text-title">
              {t('text_search_filters')}
            </Text>
          )}
        >
          <Form
            name="advanced_search"
            className="ant-advanced-search-form"
            labelCol={{
              span: 10,
            }}
            ref={formRef}
            onSubmit={clickFilterButton}
            form={form}
          >

            <Row gutter={24}>
              <Col xs={24} md={8}>
                <Form.Item
                  name="external_locator"
                  label={(
                    <Text classname="text-title-description">
                      {t('text_external_booking_locator')}
                    </Text>
                  )}
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                >
                  <Input
                    suffix={<SearchOutlined classname="icon-filter" />}
                    placeholder={t('text_external_booking_locator')}
                    onChange={(text) => {
                      dispatch(actionSetReservation(null));
                      setNumberLocator(text.target.value);
                    }}
                    value={numberLocator}
                    className="input-filter"
                  />
                </Form.Item>
              </Col>

              <Col xs={24} md={8}>
                <Form.Item
                  name="chain"
                  label={(
                    <Text classname="text-title-description">
                      { t(isCubatourProfile ? 'text_group' : 'text_chain')}
                    </Text>
                  )}
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                >
                  <Select
                    showSearch
                    className="select-input"
                    allowClear
                    placeholder={t(isCubatourProfile ? 'text_group' : 'text_chain')}
                    optionFilterProp="children"
                    onChange={(value) => {
                      setChain(value);
                    }}
                    filterOption={
                      (
                        input,
                        option
                      ) => option.children.props.children.toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    value={chain}
                  >
                    {chainsOptions}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} md={8}>
                <Form.Item
                  name="dateCreation"
                  label={(
                    <Text classname="text-title-description">
                      {t('text_create_date')}
                    </Text>
                  )}
                  rules={[
                    {
                      required: false,
                    },
                    { validator: datesDifferenceValidator },
                  ]}
                >
                  <RangePicker
                    style={{ width: '100%' }}
                    classname="input-filter"
                    format={ConstantsDate.DATE_FORMAT_PICKER}
                    onChange={(dates) => {
                      setDateCreateStart(dates === null ? null : dates[0]);
                      setDateCreateEnd(dates === null ? null : dates[1]);
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col xs={24} md={8}>
                <Form.Item
                  name="internal_reference"
                  label={(
                    <Text classname="text-title-description">
                      {t('text_locator')}
                    </Text>
                  )}
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                >
                  <Input
                    suffix={<SearchOutlined classname="icon-filter" />}
                    placeholder={t('text_locator')}
                    onChange={(text) => {
                      setInternalReference(text.target.value);
                    }}
                    value={internalReference}
                    classname="input-filter"
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={8}>
                <Form.Item
                  name="nameHotel"
                  label={(
                    <Text classname="text-title-description">
                      {isCubatourProfile ? t('text_office') : t('text_hotel')}
                    </Text>
                  )}
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                >
                  <Select
                    showSearch
                    allowClear
                    defaultActiveFirstOption={false}
                    className="select-input"
                    placeholder={isCubatourProfile ? t('text_office') : t('hint_hotel_name')}
                    optionFilterProp="children"
                    onChange={(value) => {
                      setNameHotel(value);
                    }}
                    filterOption={
                      (
                        input,
                        option
                      ) => option.children.props.children.toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    value={nameHotel}
                  >
                    {hotelsOptions}
                  </Select>
                </Form.Item>
              </Col>

              <Col xs={24} md={8}>
                <Form.Item
                  name="dateInitSend"
                  label={(
                    <Text classname="text-title-description">
                      {t('text_init_service_date')}
                    </Text>
                  )}
                  rules={[
                    {
                      required: false,
                    },
                    { validator: datesDifferenceValidator },
                  ]}
                >
                  {/* es necesario para evitar el autocompletamiento en la fecha */}
                  <input style={{display: 'none'}} autoComplete="cc-exp-month" />
                  <input style={{ display: 'none' }} autoComplete="cc-exp-year" />

                  <RangePicker
                    style={{ width: '100%' }}
                    classname="input-filter"
                    format={ConstantsDate.DATE_FORMAT_PICKER}
                    onChange={(dates) => {
                      setDateInitSendStart(dates === null ? null : dates[0]);
                      setDateInitSendEnd(dates === null ? null : dates[1]);
                    }}
                  />
                </Form.Item>
              </Col>

            </Row>
            <Row gutter={24}>
              <Col xs={24} md={8}>
                <Form.Item
                  name="idTransaction"
                  label={(
                    <Text classname="text-title-description">
                      {t('text_id_transaction')}
                    </Text>
                  )}
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                >
                  <Input
                    suffix={<SearchOutlined classname="icon-filter" />}
                    placeholder={t('text_id_transaction')}
                    onChange={(text) => {
                      setIdTransaction(text.target.value);
                    }}
                    value={idTransaction}
                    classname="input-filter"
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={8}>
                <Form.Item
                  label={(
                    <Text classname="text-title-description">
                      { t('text_card_number') }
                    </Text>
                  )}
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                >
                  <Input
                    autoComplete="off"
                    suffix={<CreditCardOutlined classname="icon-filter" />}
                    placeholder={t('text_card_number')}
                    onChange={(text) => {
                      setCardLastNumber(text.target.value);
                    }}
                    maxLength={4}
                    showCount
                    value={cardLastNumber}
                    classname="input-filter"
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={8}>
                <Form.Item
                  name="dateEndSend"
                  label={(
                    <Text classname="text-title-description">
                      {t('text_end_service_date')}
                    </Text>
                  )}
                  rules={[
                    {
                      required: false,
                    },
                    { validator: datesDifferenceValidator },
                  ]}
                >
                  <RangePicker
                    style={{ width: '100%' }}
                    autoComplete="off"
                    classname="input-filter"
                    format={ConstantsDate.DATE_FORMAT_PICKER}
                    onChange={(dates) => {
                      setDateEndSendStart(dates === null ? null : dates[0]);
                      setDateEndSendEnd(dates === null ? null : dates[1]);
                    }}
                  />
                </Form.Item>
              </Col>

            </Row>
            <Row gutter={24}>
              <Col xs={24} md={8}>
                <Form.Item
                  name="operationType"
                  label={(
                    <Text classname="text-title-description">
                      {t('text_operation_type')}
                    </Text>
                  )}
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                >
                  <Select
                    showSearch
                    className="select-input"
                    allowClear
                    placeholder={t('text_operation_type')}
                    optionFilterProp="children"
                    onChange={(value) => {
                      setOperationType(value);
                    }}
                    filterOption={
                      (
                        input,
                        option
                      ) => option.children.props.children.toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    value={operationType}
                  >
                    {operationTypeOptions}
                  </Select>
                </Form.Item>
              </Col>

              <Col xs={24} md={8}>
                <Form.Item
                  label={(
                    <Text id="randomField" classname="text-title-description">
                      {t('text_pay_type')}
                    </Text>
                  )}
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                >
                  <Select
                    showSearch
                    className="select-input"
                    allowClear
                    placeholder={t('text_pay_type')}
                    optionFilterProp="children"
                    onChange={(value) => {
                      setStatePay(value);
                    }}
                    filterOption={
                      (
                        input,
                        option
                      ) => option.children.props.children.toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    value={statePay}
                  >
                    {statesPayOptions}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} md={8}>
                <Form.Item
                  name="currency"
                  label={(
                    <Text classname="text-title-description">
                      {t('text_currency')}
                    </Text>
                  )}
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                >
                  <Select
                    showSearch
                    allowClear
                    defaultActiveFirstOption={false}
                    className="select-input"
                    placeholder={t('text_currency')}
                    optionFilterProp="children"
                    onChange={(value) => {
                      setCurrency(value);
                    }}
                    filterOption={
                      (
                        input,
                        option
                      ) => option.children.props.children.toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    value={currency}
                  >
                    {currencyOptions}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={24}>
                <Form.Item
                  name="buttons"
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                >
                  <Row justify="end">
                    <Col>
                      <Button
                        onClick={clickClearFilters}
                        className="button-default-filters"
                      >
                        {t('text_button_clear')}
                      </Button>
                    </Col>
                    <Col>
                      <Tooltip title={isDisabledSubmit() ? t('mustFilterAtLeastByOneDateField') : null}>
                        <Button
                          type="primary"
                          htmlType="submit"
                          onClick={clickFilterButton}
                          className="button-primary-filters"
                          disabled={isDisabledSubmit()}
                        >
                          {t('text_button_filter')}
                        </Button>
                      </Tooltip>
                    </Col>
                  </Row>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Panel>
      </Collapse>
    </Row>
  );
}
