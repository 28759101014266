import React, { useState, useEffect } from 'react';
import {
  Button, Card, Col, Form, Input, message, Row, Spin, Typography,
} from 'antd';
import 'antd/dist/antd.less';
import {
  UserOutlined, LockOutlined,
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { actionLoading } from '../../store/reducers/loading/loadingActions';
import { actionLogin, actionClearLoginResult } from '../../store/reducers/login/loginActions';
import ConstantsValue from '../../constants/ConstantsValue';
import background from '../../assets/images/adobe-stock-91340865-neg.jpg';
import background2x from '../../assets/images/adobe-stock-91340865-neg@2x.jpg';
import background3x from '../../assets/images/adobe-stock-91340865-neg@3x.jpg';
import logo from '../../assets/images/group-28.svg';
import {getDefaultView } from '../../utils/Utils';

const { Link, Text } = Typography;

export default function Login() {
  const navigate = useNavigate();

  const [user, setUser] = useState('');
  const [password, setPassword] = useState('');

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const loginResult = useSelector((state) => state.reducerLoginResult);
  const loading = useSelector((state) => state.reducerLoading);

  const navigateToDefaultView = () => {
    navigate(getDefaultView());
  };

  useEffect(() => {
    if (loginResult.msg !== null && loginResult.result === false) {
      message.error(loginResult.msg);
      dispatch(actionClearLoginResult());
    }
    if (loginResult.result === true) {
      message.success(loginResult.msg);
      setTimeout(() => {
        navigateToDefaultView();
      }, 1500);
      dispatch(actionClearLoginResult());
    }
  });

  const validateCredentials = () => {
    dispatch(actionLoading(true));
    dispatch(actionLogin(user, password));
  };

  const navigateForgotPassword = () => {
    navigate('/resetpassword');
  };

  if (Cookies.get(ConstantsValue.JWT) !== undefined
    && Cookies.get(ConstantsValue.JWT) !== null) {
    return navigateToDefaultView();
  }
  return (
    <Spin spinning={loading} size="large">
      <Row className="w-100">
        <Col span={24}>
          <img
            alt="background"
            src={background}
            srcSet={`${background2x} 2x,${background3x} 3x`}
            className="img-w img img-h-250 img-auto mb-5"
          />
        </Col>
        <Col
          span={20}
          md={12}
          xl={8}
          className="ant-row mb-5 mx-auto"
        >
          <Col span={24}>
            <Card bordered={false}>
              <Form
                name="normal_login"
                initialValues={{
                  remember: true,
                  size: 'middle',
                }}
              >
                <Form.Item
                  name="username"
                  rules={[
                    {
                      required: true,
                      message: t('error_empty_field'),
                    },
                  ]}
                >
                  <Input
                    prefix={<UserOutlined className="icon icon-color-secondary" />}
                    placeholder={t('hint_user')}
                    onChange={(text) => {
                      setUser(text.target.value);
                    }}
                    onPressEnter={validateCredentials}
                    value={user}
                    className="rounded-sm py-2"
                  />
                </Form.Item>
                <Form.Item
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: t('error_empty_field'),
                    },
                  ]}
                >
                  <Input.Password
                    prefix={<LockOutlined className="icon icon-color-secondary" />}
                    type="password"
                    placeholder={t('hint_password')}
                    onChange={(text) => {
                      setPassword(text.target.value);
                    }}
                    onPressEnter={validateCredentials}
                    value={password}
                    className="rounded-sm py-2"
                  />
                </Form.Item>
                <Form.Item>
                  <Row align="middle">
                    <Col>
                      <Button
                        type="link"
                        htmlType="button"
                        onClick={navigateForgotPassword}
                        className="pl-0"
                      >
                        {t('text_forgot_password')}
                      </Button>
                    </Col>
                  </Row>
                </Form.Item>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    disabled={user.length === 0 || password.length === 0}
                    onClick={validateCredentials}
                    className="btn-block text-bold"
                  >
                    {t('button_enter')}
                  </Button>
                </Form.Item>
              </Form>
            </Card>
          </Col>
        </Col>
        <Col
          span={20}
          offset={2}
          className="ant-row ant-row-end mb-5 text-right text-md-center"
        >
          <Col className="mb-3 text-right text-md-center">
            <img
              alt="logo"
              src={logo}
            />
          </Col>
          <Col>
            <Text className="text text-size-0-8 text-color-white mr-2">
              {t('text_contact_1')}
            </Text>
            <Link
              className="text text-size-0-8 text-color-secondary"
              href="mailto:info@adgtravel.com"
              target="_blank"
            >
              {t('text_contact_2')}
            </Link>
          </Col>
        </Col>
      </Row>
    </Spin>
  );
}
