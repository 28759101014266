import decode from 'jwt-decode';
import Cookies from 'js-cookie';
import ConstantsHome from './homeConstants';
import ConstantsValue from '../../../constants/ConstantsValue';

const actionIndexActive = (index) => ({
  type: ConstantsHome.INDEX_ACTIVE,
  index,
});

export const actionDecodedJWT = () => {
  const jwt = Cookies.get(ConstantsValue.JWT);
  const decoded = decode(jwt);

  return {
    type: ConstantsHome.JWT_DECODED,
    data: decoded,
  };
};

export default actionIndexActive;
