import React from 'react';
import {
  Descriptions
} from 'antd';
import PropTypes from 'prop-types';

const styles = {
  divContainer: {
    overflowY: 'auto',
    maxHeight: '180px',
    border: '1px solid #d9d9d9',
    borderRadius: '4px',
    marginTop: '10px',
    padding: '20px'
  },
  description: {
    padding: '5px',
    borderTop: '1px solid #d9d9d9',
    borderBottom: '1px solid #d9d9d9',

  }
};

export default function PrevisualizationConciliationCard(props) {
  const {
    logs,
  } = props;

  const rows = [];
  logs.forEach((hotel) => {
    const currentLogs = [];
    if (!hotel.logs) {
      return;
    }
    Object.keys(hotel.logs).forEach((key) => {
      currentLogs.push((
        <Descriptions.Item label={key} key={key}>
          {hotel.logs[key]}
        </Descriptions.Item>
      ));
    });
    rows.push((
      <Descriptions title={hotel.nombre_hotel} size="small" column={1} key={hotel.nombre_hotel} style={styles.description}>
        {currentLogs}
      </Descriptions>
    ));
  });

  return (
    <div style={styles.divContainer}>
      {rows}
    </div>
  );
}

PrevisualizationConciliationCard.defaultProps = {
  logs: false,
};

PrevisualizationConciliationCard.propTypes = {
  logs: PropTypes.arrayOf(PropTypes.shape({})),
};
