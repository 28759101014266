import React, { useEffect } from 'react';
import {
  Button,
  Col,
  Form,
  Modal,
  Typography,
  Row,
  Upload,
  Spin,
  message
} from 'antd';
import { useTranslation } from 'react-i18next';
import { UploadOutlined, CloseCircleOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { useAttachDocToPaymentRequestApiMutation} from '../../../services/paymentRequests';

import './styles/styles.css';

export default function AttachmentModal({
  handleOk, handleCancel, paymentRequest
}) {
  const { Text } = Typography;
  const { t } = useTranslation();

  const [
    changeAttachDocToPaymentRequest,
    {
      isLoading,
      isSuccess,
      error,
      reset
    }
  ] = useAttachDocToPaymentRequestApiMutation({ });

  const [form] = Form.useForm();
  const onOk = () => {
    form.submit();
  };

  const onFinishForm = (values) => {
    const fileSize = values?.attachments?.file?.size;
    if (fileSize && (fileSize / (1024 * 1024) > 6)) {
      message.error(t('error_max_size_file'));
      return;
    }
    changeAttachDocToPaymentRequest({
      id: paymentRequest.id,
      file: values?.attachments?.file
    });
  };

  useEffect(() => {
    if (isSuccess) {
      message.success(t('success_attaching_payment_file'));
      handleOk();
      reset();
    }
    if (error) {
      message.error(error.message);
    }
  }, [isSuccess, error]);

  return (
    <Spin spinning={isLoading} size="large">
      <Modal
        title={t('attach_file_to_payment_request')}
        open
        onOk={onOk}
        confirmLoading={false}
        onCancel={() => {
          handleCancel();
        }}
        width={800}
        okText={t('attach_file')}
        closeIcon={<CloseCircleOutlined className="icon-payment-request" />}
      >
        <Form
          form={form}
          name="advanced_search"
          className="ant-advanced-search-form"
          labelCol={{
            span: 4,
          }}
          onFinish={onFinishForm}

        >

          <Row gutter={24} justify="center">
            <Col span={24}>
              <Form.Item
                name="attachments"
                label={
                  <Text className="text-title-description">{t('text_attachment_files')}</Text>
                }
                rules={[
                  {
                    required: true,
                    message: t('required_field'),
                  },
                ]}
              >
                <Upload
                  multiple={false}
                  maxCount={1}
                  className="upload-list-inline"
                  beforeUpload={() => false}
                >
                  <Button icon={<UploadOutlined />}>{t('text_button_upload')}</Button>
                </Upload>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </Spin>
  );
}

AttachmentModal.defaultProps = {
  handleOk: undefined,
  handleCancel: undefined,
};

AttachmentModal.propTypes = {
  handleOk: PropTypes.func,
  handleCancel: PropTypes.func,
  paymentRequest: PropTypes.shape({
    id: PropTypes.string || PropTypes.number,
  }).isRequired,

};
