import React, { useEffect, useState } from 'react';
import { EuroCircleOutlined } from '@ant-design/icons';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import {
  BackTop, Button, Col, DatePicker, Input, message, Row, Select, Typography, InputNumber
} from 'antd';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import { useSelector } from 'react-redux';
import moment from 'moment';
import i18n from '../../../assets/language/i18n';
import SecureField from './SecureField';
import SecureFieldIcon from './SecureFieldIcon';
import './SecureField.css';
import Colors from '../../../assets/colors/Colors';
import ConstantsDate from '../../../constants/ConstantsDate';

const { TextArea } = Input;
const { Text } = Typography;

const initalInputStyle = 'font-size: 100%; border-radius: 0; -webkit-appearance: none; padding: 0';
const initalCssClass = {
  'secure-field': true,
  'secure-field__input ': true,
  'secure-field__base ': true,
  'secure-field__has-actions ': true,
  'secure-field__has-card-icon': true,
  'secure-field__has-error': false,
  'secure-field__is-recognized': false
};

const styles = {
  icon: {
    color: Colors.PRIMARY_COLOR,
  },
  input: {
    borderRadius: 4,
    borderColor: Colors.BORDER_COLOR,
    fontFamily: 'Roboto-Regular',
    fontSize: 16,
    width: '100%',
  },
  inputDate: {
    borderRadius: 4,
    borderColor: Colors.BORDER_COLOR,
    fontFamily: 'Roboto-Regular',
    fontSize: 16,
    width: '100%',
    height: 40,
    marginTop: 8
  },
  buttonPrimaryModal: {
    fontFamily: 'Roboto-Bold',
    fontWeight: '600',
    fontSize: 16,
    width: '100%',
    marginTop: 8,
  },
  select: {
    fontFamily: 'Roboto-Regular',
    fontSize: 16,
    borderRadius: 4,
    borderWidth: 1,
    borderColor: 'white',
    width: '100%',
  },
};

export default function SecureFields(props) {
  const { RangePicker } = DatePicker;

  const [secureFields, setSecureFields] = useState();
  const [cardIcon, setCardIcon] = useState('card-empty');
  const [cvvIcon, setCvvIcon] = useState('cvv-empty');
  const [cardContainerClassNames, setCardContainerClassNames] = useState(initalCssClass);
  const [cvvContainerClassNames, setCvvContainerClassNames] = useState(initalCssClass);

  const {
    config,
    handleForm,
    hotelsOptions,
    currencyOptions,
    showDatesFields
  } = props;

  const [payConcept, setPayConcept] = useState('');
  const [localizator, setLocalizator] = useState(config?.formFields?.localizator?.initialValue ?? '');
  const [hotelCode, setHotelCode] = useState(config?.formFields?.hotel?.initialValue ?? '');
  const [serviceAmount, setServiceAmount] = useState(config?.formFields?.amount?.initialValue && parseFloat(config.formFields.amount.initialValue) !== 0 ? config.formFields.amount.initialValue : '');
  const [currency, setCurrency] = useState(config?.formFields?.currency?.initialValue ?? '');
  const [date, setDate] = useState('');
  const [card, setCard] = useState('');
  const [dateInitSend, setDateInitSend] = useState(new Date().toLocaleDateString());
  const [dateEndSend, setDateEndSend] = useState(new Date().toLocaleDateString());

  const jwtData = useSelector(
    (state) => state.reducerJwtData
  );

  const isCubatourProfile = jwtData?.userInfo?.permisos?.tipo_agente === 'ctt';

  const initSecureFields = () => {
    const initalizedSecureFields = new window.SecureFields();
    initalizedSecureFields.initTokenize(config.merchantID, config.fields, config.options);
    setSecureFields(initalizedSecureFields);
  };

  const cleanupSecureFields = () => {
    if (secureFields) {
      window.setTimeout(() => {
        try {
          secureFields.destroy();
          setSecureFields(null);
        } catch (err) {
          message.error(i18n.t('text_error_pay'));
        }
      }, 1);
    }
  };

  const successSecureFields = (data) => {
    const body = {
      payConcept,
      localizator,
      hotelCode,
      serviceAmount,
      currency,
      date: date.length === 0 ? '' : format(date.toDate(), 'yyMM', {
        locale: es,
      }),
      transactionId: data.transactionId,
      dateInitSend,
      dateEndSend
    };
    if (data.transactionId) {
      handleForm(body);
    } else if (data.error) {
      handleForm(null);
    }
    initSecureFields();
  };

  useEffect(() => {
    if (!secureFields) {
      document.getElementById('secure-fields-script')?.remove();
      const scriptSource = process.env.REACT_APP_PCI_PROXY_URL;
      const script = document.createElement('script');
      script.src = scriptSource;
      script.id = 'secure-fields-script';
      script.onload = () => {
        initSecureFields();
      };
      document.body.appendChild(script);
      cleanupSecureFields();
    } else {
      secureFields.on('ready', () => {
        secureFields.setStyle('cardNumber', initalInputStyle);
        secureFields.setStyle('cvv', initalInputStyle);
        secureFields.focus('cardNumber');
      });

      // Set class names and icon when fields change
      secureFields.on('change', (data) => {
        const paymentMethod = data.fields.cardNumber.paymentMethod
          ? data.fields.cardNumber.paymentMethod
          : false;

        setCardContainerClassNames({
          ...cardContainerClassNames,
          'secure-field__is-recognized': !!paymentMethod,
          'secure-field__has-error': false
        });
        setCvvContainerClassNames({
          ...cvvContainerClassNames,
          'secure-field__has-error': false
        });
        setCardIcon(paymentMethod ? (`brands/${paymentMethod}`) : 'card-empty');
        setCvvIcon('cvc-empty');

        if (paymentMethod !== false) {
          setCard(true);
        }
      });

      // Set error icon and class name on validate failure
      secureFields.on('validate', (data) => {
        if (!data.fields.cardNumber.valid) {
          setCardContainerClassNames({
            ...cardContainerClassNames,
            'secure-field__is-recognized': false,
            'secure-field__has-error': true
          });
          setCardIcon('card-error');
        }

        if (!data.fields.cvv.valid) {
          setCvvContainerClassNames({
            ...cvvContainerClassNames,
            'secure-field__has-error': true
          });
          setCvvIcon('cvc-error');
        }
      });

      // Show transaction ID on success or transaction error message
      secureFields.on('success', successSecureFields);
    }
  }, [secureFields, payConcept, localizator, hotelCode, serviceAmount, currency, date, dateInitSend, dateEndSend]);

  const validateForm = () => (payConcept.length === 0
      || serviceAmount.length === 0
      || currency.length === 0
      || hotelCode.length === 0
      || date.length === 0
      || localizator.length === 0
      || dateInitSend.length === 0
      || dateEndSend.length === 0
      || !card);

  return (
    <form onSubmit={(e) => {
      e.preventDefault();
      if (validateForm()) {
        message.error(i18n.t('error_empty_fields'));
      } else {
        secureFields.submit();
      }
    }}
    >
      <BackTop />
      <Row justify="center">
        <Col xs={24} sm={18}>
          <Row style={{marginTop: 20}}>
            <Text>
              {isCubatourProfile ? i18n.t('text_office') : i18n.t('hint_hotel_name')}
            </Text>
          </Row>
          <Row>
            <Select
              showSearch
              defaultActiveFirstOption={false}
              style={styles.select}
              placeholder={isCubatourProfile ? i18n.t('text_office') : i18n.t('hint_hotel_name')}
              optionFilterProp="children"
              onChange={(value) => {
                setHotelCode(value);
              }}
              value={hotelCode}
              filterOption={
                (
                  input,
                  option
                ) => option.children.props.children.toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              disabled={config?.formFields?.hotel?.blocked}
            >
              {hotelsOptions}
            </Select>
          </Row>

          <Row style={{marginTop: 20}}>
            <Text>
              {i18n.t('text_external_booking_locator')}
            </Text>
          </Row>
          <Row>
            <Input
              placeholder={i18n.t('text_external_booking_locator')}
              onChange={(text) => {
                setLocalizator(text.target.value);
              }}
              style={styles.input}
              value={localizator}
              disabled={config?.formFields?.localizator?.blocked}
            />
          </Row>

          <Row>
            <Text
              style={{
                marginTop: 8,
              }}
            >
              {i18n.t('hint_pay_concept')}
            </Text>
          </Row>
          <Row>
            <TextArea
              placeholder={i18n.t('hint_pay_concept')}
              onChange={(text) => {
                setPayConcept(text.target.value);
              }}
              style={styles.input}
              rows={4}
            />
          </Row>

          {showDatesFields ? (
            <div>
              <Row style={{ marginTop: 20 }}>
                <Col span={12}>
                  <Text>
                    {i18n.t('text_init_date')}
                  </Text>
                </Col>
                <Col span={12}>
                  <Text>
                    {i18n.t('text_end_date')}
                  </Text>
                </Col>
              </Row>
              <Row>
                <RangePicker
                  style={{...styles.input, ...styles.datesRange}}
                  format={ConstantsDate.DATE_FORMAT_PICKER}
                  defaultValue={[moment(dateInitSend, ConstantsDate.DATE_FORMAT_PICKER), moment(dateEndSend, ConstantsDate.DATE_FORMAT_PICKER)]}
                  onChange={(dates) => {
                    setDateInitSend(dates === null ? '' : dates[0].format(ConstantsDate.DATE_FORMAT_PICKER));
                    setDateEndSend(dates === null ? '' : dates[1].format(ConstantsDate.DATE_FORMAT_PICKER));
                  }}
                />
              </Row>
            </div>
          ) : ''}
          <Row style={{marginTop: 20}}>
            <Text
              style={{
                width: '50%',
                marginTop: 8,
              }}
            >
              {i18n.t('hint_amount_service')}
            </Text>
            <Text
              style={{
                width: '50%',
                marginTop: 8,
              }}
            >
              {i18n.t('text_currency')}
            </Text>
          </Row>
          <Row>
            <InputNumber
              prefix={<EuroCircleOutlined style={styles.icon} />}
              placeholder={i18n.t('hint_amount_service')}
              onChange={(text) => {
                setServiceAmount(text);
              }}
              style={{
                width: '50%',
              }}
              value={serviceAmount}
              decimalSeparator=","
              stringMode
              disabled={config?.formFields?.amount?.blocked}
            />
            <Select
              style={{
                width: '50%',
              }}
              defaultActiveFirstOption={false}
              placeholder={i18n.t('text_currency')}
              optionFilterProp="children"
              onChange={(value) => {
                setCurrency(value);
              }}
              value={currency}
              disabled={config?.formFields?.currency?.blocked}
            >
              {currencyOptions}
            </Select>
          </Row>
          <div style={{maxWidth: '100%', marginTop: 16}}>
            {/* <!-- Card Number markup --> */}
            <SecureField
              fieldType="card"
              label={i18n.t('text_card_number')}
              customClass={classnames(cardContainerClassNames)}
              callback={() => secureFields.focus('cardNumber')}
            >
              <SecureFieldIcon fieldType="card" iconType={cardIcon} />
            </SecureField>
          </div>
          <Row>
            <div style={{width: '50%', marginTop: 20}}>
              <Text
                style={{
                  marginTop: 8,
                }}
              >
                {i18n.t('hint_month_year')}
              </Text>
              <DatePicker
                placeholder={i18n.t('hint_month_year')}
                format="MM/YY"
                picker="month"
                style={styles.inputDate}
                onChange={(value) => {
                  setDate(value);
                }}
              />
            </div>
            <div style={{width: '50%', marginTop: 20}}>
              {/* <!-- CVV markup --> */}
              <SecureField
                fieldType="cvv"
                label="CVV"
                customClass={classnames(cvvContainerClassNames)}
                callback={() => secureFields.focus('cvv')}
              >
                <SecureFieldIcon fieldType="cvv" iconType={cvvIcon} />
              </SecureField>
            </div>
          </Row>
          <Button
            id="form-submit"
            type="primary"
            htmlType="submit"
            style={styles.buttonPrimaryModal}
          >
            {i18n.t('text_button_process_pay')}
          </Button>
        </Col>
      </Row>
    </form>
  );
}

SecureFields.defaultProps = {
  config: undefined,
  handleForm: undefined,
  hotelsOptions: undefined,
  currencyOptions: undefined,
  showDatesFields: true
};

SecureFields.propTypes = {
  config: PropTypes.shape({
    merchantID: PropTypes.string,
    fields: PropTypes.shape({}),
    formFields: PropTypes.shape({
      localizator: PropTypes.shape({
        initialValue: PropTypes.string,
        blocked: PropTypes.bool,
      }),
      hotel: PropTypes.shape({
        initialValue: PropTypes.string,
        blocked: PropTypes.bool,
      }),
      currency: PropTypes.shape({
        initialValue: PropTypes.string,
        blocked: PropTypes.bool,
      }),
      amount: PropTypes.shape({
        initialValue: PropTypes.string,
        blocked: PropTypes.bool,
      }),
    }),
    options: PropTypes.shape({})
  }),
  handleForm: PropTypes.func,
  hotelsOptions: PropTypes.arrayOf(PropTypes.shape({})),
  currencyOptions: PropTypes.arrayOf(PropTypes.shape({})),
  showDatesFields: PropTypes.bool
};
