import {
  call, takeEvery, put,
} from 'redux-saga/effects';
import qs from 'qs';
import i18n from '../assets/language/i18n';
import { actionLoading } from '../store/reducers/loading/loadingActions';
import {request} from './axiosConfig';
import ConstantsPayments from '../store/reducers/payments/paymentsConstants';
import {
  actionAddPayReservationResult, actionGetChargesReservationResult,
  actionGetFiltersResult,
  actionGetReservationsResult, actionPayChargeReservationResult, actionRemovePayReservationResult,
} from '../store/reducers/payments/paymentsActions';

function getFiltersApi() {
  return request.post('/reservas/getFiltros', new FormData())
    .then((response) => response)
    .catch((error) => error.response);
}

function* generateGetFilters() {
  const filters = {
    hotels: [],
    channels: [],
    payStates: [],
    payTypes: [],
    payActions: [],
    reservationStates: [],
    clients: [],
    chains: [],
    currencies: [],
    concepts: [],
    crs: [],
  };
  try {
    const { status, data } = yield call(getFiltersApi);
    if (status === 200) {
      filters.hotels = data.hoteles_asociados?.sort(
        (a, b) => a?.label.localeCompare(b?.label, i18n.language),
      );
      filters.channels = data.canales?.sort(
        (a, b) => a?.label.localeCompare(b?.label, i18n.language),
      );
      filters.payStates = data.estados_de_pago?.sort(
        (a, b) => a?.label.localeCompare(b?.label, i18n.language),
      );
      filters.payTypes = data.forma_de_pago?.sort(
        (a, b) => a?.label.localeCompare(b?.label, i18n.language),
      );
      filters.payActions = data.action_de_pago?.sort(
        (a, b) => a?.label.localeCompare(b?.label, i18n.language),
      );
      filters.reservationStates = data.estados_reservas?.sort(
        (a, b) => a?.label.localeCompare(b?.label, i18n.language),
      );
      filters.payForms = data.forma_de_pago?.sort(
        (a, b) => a?.label.localeCompare(b?.label, i18n.language),
      );
      filters.clients = data.cliente?.sort(
        (a, b) => a?.cliente.localeCompare(b?.cliente, i18n.language),
      );
      filters.chains = data.cadena_hotelera?.sort(
        (a, b) => a?.nombre_cadena.localeCompare(b?.nombre_cadena, i18n.language),
      );
      filters.currencies = data.moneda?.sort(
        (a, b) => a?.label.localeCompare(b?.label, i18n.language),
      );
      filters.concepts = data.concepto?.sort(
        (a, b) => a?.label.localeCompare(b?.label, i18n.language),
      );
      filters.crs = data.crs?.sort(
        (a, b) => a?.label.localeCompare(b?.label, i18n.language),
      );

      yield put(actionGetFiltersResult(true, null, filters));
    } else {
      yield put(actionGetFiltersResult(false, i18n.t('error_get_filters'), filters));
    }
  } catch (error) {
    console.log(error);
    yield put(actionGetFiltersResult(false, i18n.t('error_get_filters'), filters));
  }

  yield put(actionLoading(false));
}

function getReservationsApi(values) {
  const parameters = {};
  if (values.hotel?.length !== 0) {
    parameters.codigo_hotel = values.hotel;
  }
  if (values.channel?.length !== 0) {
    parameters.canal = values.channel;
  }
  if (values.numberLocator?.length !== 0) {
    parameters.localizador_reservas = values.numberLocator;
  }
  if (values.dateCreateStart?.length !== 0) {
    parameters.fecha_inicio = values.dateCreateStart;
  }
  if (values.dateCreateEnd?.length !== 0) {
    parameters.fecha_final = values.dateCreateEnd;
  }
  if (values.dateCheckinStart?.length !== 0) {
    parameters.fecha_checkin_inicio = values.dateCheckinStart;
  }
  if (values.dateCheckinEnd?.length !== 0) {
    parameters.fecha_checkin_final = values.dateCheckinEnd;
  }
  if (values.dateCheckoutStart?.length !== 0) {
    parameters.fecha_checkout_inicio = values.dateCheckoutStart;
  }
  if (values.dateCheckoutEnd?.length !== 0) {
    parameters.fecha_checkout_final = values.dateCheckoutEnd;
  }
  if (values.reservationState?.length !== 0) {
    parameters.estado = values.reservationState;
  }
  if (values.payState?.length !== 0) {
    parameters.estado_cobro = values.payState;
  }
  if (values.otaCode?.length !== 0) {
    parameters.codigo_ota = values.otaCode;
  }
  if (values.client?.length !== 0) {
    parameters.cliente = values.client;
  }
  if (values.currency?.length !== 0) {
    parameters.moneda = values.currency;
  }
  if (values.chain?.length !== 0) {
    parameters.cadena_hotelera = values.chain;
  }
  if (values.concept?.length !== 0) {
    parameters.concepto = values.concept;
  }
  if (values.crs?.length !== 0) {
    parameters.crs = values.crs;
  }
  if (values.externalLocator?.length !== 0) {
    parameters.localizador_agencia = values.externalLocator;
  }
  return request.post(
    '/reservas/getReservas',
    qs.stringify(parameters)
  )
    .then((response) => response)
    .catch((error) => error.response);
}

function* generateGetReservations(values) {
  try {
    const { status, data } = yield call(getReservationsApi, values);
    if (status === 200) {
      const reservations = data.reservas?.map((reservation) => (
        {
          ...reservation,
          arrival: reservation.arrival === null ? '' : new Date(reservation.arrival),
          arrival_string: reservation.arrival,
          checkout: reservation.checkout === null ? '' : new Date(reservation.checkout),
          checkout_string: reservation.checkout,
          fecha_creacion: reservation.fecha_creacion === null ? '' : new Date(reservation.fecha_creacion),
          fecha_creacion_string: reservation.fecha_creacion,
          fecha_modificacion: reservation.fecha_modificacion === null ? '' : new Date(reservation.fecha_modificacion),
          fecha_modificacion_string: reservation.fecha_modificacion,
          nights: Number(reservation.nights),
          channel: reservation.channel === null ? '' : reservation.channel,
          cliente: reservation.cliente === null ? '' : reservation.cliente,
          agency: reservation.agency === null ? '' : reservation.agency,
          total_pagado: reservation.total_pagado === undefined ? 0 : reservation.total_pagado,
          transacciones: reservation.transacciones === undefined ? [] : reservation.transacciones,
        }
      ));
      yield put(actionGetReservationsResult(true, null, reservations));
    } else {
      yield put(actionGetReservationsResult(false, i18n.t('error_get_reservations'), []));
    }
  } catch (error) {
    console.log(error);
    yield put(actionGetReservationsResult(false, i18n.t('error_get_reservations'), []));
  }

  yield put(actionLoading(false));
}

function savePayApi(values) {
  return request.post(
    '/reservas/nuevoCobro',
    qs.stringify({
      tipo: values.payType,
      action: values.actionPay,
      reserva: values.reservationId,
      transaccion: values.transaction,
      estado: values.statePay,
      fecha_transaccion: values.date,
      amount: values.amount,
    })
  )
    .then((response) => response)
    .catch((error) => error.response);
}

function* generateSavePay(values) {
  try {
    const { status, data } = yield call(savePayApi, values);
    if (status === 200) {
      yield put(actionAddPayReservationResult(true, i18n.t('success_save_pay'), values, data.id_cobro));
    } else {
      yield put(actionAddPayReservationResult(false, i18n.t('error_save_pay'), {}, ''));
    }
  } catch (error) {
    console.log(error);
    yield put(actionAddPayReservationResult(false, i18n.t('error_save_pay'), {}, ''));
  }

  yield put(actionAddPayReservationResult(false, null, null, null));
  yield put(actionLoading(false));
}

function removePayApi(values) {
  return request.post(
    '/reservas/borrarCobro',
    qs.stringify({
      id_cobro: values.payId,
    })
  )
    .then((response) => response)
    .catch((error) => error.response);
}

function* generateRemovePay(values) {
  try {
    const { status } = yield call(removePayApi, values);
    if (status === 200) {
      yield put(actionRemovePayReservationResult(true, i18n.t('success_remove_pay'), values.reservationId, values.payId));
    } else {
      yield put(actionRemovePayReservationResult(false, i18n.t('error_remove_pay'), values.reservationId, values.payId));
    }
  } catch (error) {
    console.log(error);
    yield put(actionRemovePayReservationResult(false, i18n.t('error_remove_pay'), values.reservationId, values.payId));
  }

  yield put(actionRemovePayReservationResult(false, null, null, null));
  yield put(actionLoading(false));
}

function getChargesReservationApi(values) {
  return request.post(
    '/reservas/getCobrosByIdReserva',
    qs.stringify({
      localizador_reservas: values.reservationLocator,
    })
  )
    .then((response) => response)
    .catch((error) => error.response);
}

function* generateGetChargesReservation(values) {
  try {
    const { status, data } = yield call(getChargesReservationApi, values);
    if (status === 200) {
      yield put(actionGetChargesReservationResult(true, '', values.reservationId, data));
    } else {
      yield put(actionGetChargesReservationResult(false, '', values.reservationId, null));
    }
  } catch (error) {
    console.log(error);
    yield put(actionGetChargesReservationResult(false, '', values.reservationId, null));
  }

  yield put(actionGetChargesReservationResult(false, null, null, null));
  yield put(actionLoading(false));
}

function payChargeReservationApi(values) {
  return request.post(
    '/cobros/procesarTarjetasVirtuales',
    qs.stringify({
      id_cobro: values.cobroId,
    })
  )
    .then((response) => response)
    .catch((error) => error.response);
}

function* generatePayChargeReservation(values) {
  try {
    const { status, data } = yield call(payChargeReservationApi, values);
    if (status === 200 && (data.code === '0' || data.code === 0)) {
      yield put(actionPayChargeReservationResult(true, i18n.t('text_send_pay_charge')));
    } else {
      yield put(actionPayChargeReservationResult(false, i18n.t('error_send_pay_charge')));
    }
  } catch (error) {
    console.log(error);
    yield put(actionPayChargeReservationResult(false, i18n.t('error_send_pay_charge')));
  }

  yield put(actionLoading(false));
}

export default function* paymentsSagas() {
  yield takeEvery(ConstantsPayments.GET_FILTERS, generateGetFilters);
  yield takeEvery(ConstantsPayments.GET_RESERVATIONS, generateGetReservations);
  yield takeEvery(ConstantsPayments.ADD_PAY_RESERVATION, generateSavePay);
  yield takeEvery(ConstantsPayments.REMOVE_PAY_RESERVATION, generateRemovePay);
  yield takeEvery(ConstantsPayments.GET_CHARGES_RESERVATION, generateGetChargesReservation);
  yield takeEvery(ConstantsPayments.PAY_CHARGE_RESERVATION, generatePayChargeReservation);
}
