import ConstantsTpvTransactions from './tpvTransactionsConstants';

export const actionGetFiltersTpvTransactions = () => ({
  type: ConstantsTpvTransactions.GET_FILTERS_TRANSACTIONS,
});

export const actionGetFiltersTpvTransactionsResult = (result, msg, filters) => ({
  type: ConstantsTpvTransactions.GET_FILTERS_TRANSACTIONS_RESULT,
  result,
  msg,
  filters,
});

export const actionGetTpvTransactions = (
  hotel,
  dateCreateStart,
  dateCreateEnd,
  numberLocator,
  idTransaction,
  payState,
  chain,
  currency,
  cardLastNumber,
  internalReference,
  operationType,
  dateInitSendStart,
  dateInitSendEnd,
  dateEndSendStart,
  dateEndSendEnd
) => ({
  type: ConstantsTpvTransactions.GET_TPV_TRANSACTIONS,
  hotel,
  dateCreateStart,
  dateCreateEnd,
  numberLocator,
  idTransaction,
  payState,
  chain,
  currency,
  cardLastNumber,
  internalReference,
  operationType,
  dateInitSendStart,
  dateInitSendEnd,
  dateEndSendStart,
  dateEndSendEnd
});

export const actionGetTpvTransactionsResult = (result, msg, charges) => ({
  type: ConstantsTpvTransactions.GET_TPV_TRANSACTIONS_RESULT,
  result,
  msg,
  charges,
});

export const actionGetDevolutionsByTransaction = (idTransaction) => ({
  type: ConstantsTpvTransactions.GET_DEVOLUTIONS,
  idTransaction
});

export const actionGetDevolutionsByTransactionResult = (result, msg, devolutions) => ({
  type: ConstantsTpvTransactions.GET_DEVOLUTIONS_RESULT,
  result,
  msg,
  devolutions,
});

export const actionCancelDevolution = (idDevolution) => ({
  type: ConstantsTpvTransactions.CANCEL_DEVOLUTION,
  idDevolution
});

export const actionCancelDevolutionResult = (result, msg) => ({
  type: ConstantsTpvTransactions.CANCEL_DEVOLUTION_RESULT,
  result,
  msg,
});

export const actionClearCancelDevolutionResult = () => ({
  type: ConstantsTpvTransactions.CLEAR_CANCEL_DEVOLUTION_RESULT,
});

export const actionCreateDevolution = (idTransaction, amount, comments) => ({
  type: ConstantsTpvTransactions.CREATE_DEVOLUTION,
  idTransaction,
  amount,
  comments
});

export const actionCreateDevolutionResult = (result, msg) => ({
  type: ConstantsTpvTransactions.CREATE_DEVOLUTION_RESULT,
  result,
  msg,
});

export const actionClearCreateDevolutionResult = () => ({
  type: ConstantsTpvTransactions.CLEAR_CREATE_DEVOLUTION_RESULT,
});
