import { createSlice } from '@reduxjs/toolkit';
import qs from 'qs';
import { api } from './api';
import i18n from '../assets/language/i18n';
import { downloadFile } from '../utils/Utils';

export const paymentRequestsApi = api.injectEndpoints({
  tagTypes: ['paymentRequests'],
  endpoints: (build) => ({
    getFiltersPaymentRequestsApi: build.query({
      query: () => ({ method: 'POST', url: '/SolicitudPago/getFiltros', data: new FormData() }),
      transformResponse: (response) => ({
        companies: response?.empresa?.sort(
          (a, b) => a?.label.localeCompare(b?.label, i18n.language),
        ),
        status: response?.estado?.sort(
          (a, b) => a?.label.localeCompare(b?.label, i18n.language),
        ),
        currencies: response?.moneda?.sort(
          (a, b) => a?.label.localeCompare(b?.label, i18n.language),
        ),
        chains: response?.cadena_hotelera?.sort(
          (a, b) => a?.nombre_cadena.localeCompare(b?.nombre_cadena, i18n.language),
        ),

      }),
      transformErrorResponse: () => ({
        message: i18n.t('error_get_filters'),
      }),
    }),
    getPaymentRequestsApi: build.query({
      query: (values) => {
        const dataToSend = {...values};
        return { method: 'POST', url: '/SolicitudPago/getSolicitudesPago', data: qs.stringify(dataToSend) };
      },
      transformResponse: (response) => (response.solicitud_pago.map((invitation) => (
        {
          ...invitation,
          estado: invitation.estado.toUpperCase(),
        }
      ))),
      transformErrorResponse: () => ({
        message: i18n.t('error_get_paymentRequests'),
      }),
      providesTags: ['paymentRequests'],
    }),
    createPaymentRequestApi: build.mutation({
      query: (data) => {
        const dataToSend = {
          fecha: data.date,
          id_cadena: data.chain,
          id_empresa: data.company,
          id_tour_operador: data.agency,
          importe: data.amount,
          moneda: data.currency,
          descripcion: data.description,
          id_cuenta_bancaria: data.bankAccount,
          titular_receptor: data.bankToTransferOwner,
          banco_receptor: data.bankToTransfer,
          cuenta_bancaria_receptor: data.bankToTransferAccountNumber,
          swift: data.bankToTransferSwift,
          direccion_banco_receptor: data.bankToTransferAddress,
          email_empresa_cc: data.emailCompany
        };
        return ({ method: 'POST', url: '/SolicitudPago/insertarSolicitudPago', data: qs.stringify(dataToSend) });
      },
      transformResponse: (response) => ({ ...response, message: `${i18n.t('success_create_new_payment')} ${response?.referencia}`}),
      transformErrorResponse: () => ({
        message: i18n.t('error_create_new_payment'),
      }),
      invalidatesTags: ['paymentRequests'],
    }),
    changePaymentRequestStatusApi: build.mutation({
      query: (data) => {
        const dataToSend = {
          id_sp: data.id,
          estado: data.status,
        };
        return ({ method: 'POST', url: '/SolicitudPago/cambiarEstadoSolicitudPago', data: qs.stringify(dataToSend) });
      },
      transformResponse: (response) => ({ ...response, message: i18n.t('success_changing_payment_status')}),
      transformErrorResponse: () => ({
        message: i18n.t('error_changing_payment_status'),
      }),
      invalidatesTags: ['paymentRequests'],
    }),
    attachDocToPaymentRequestApi: build.mutation({
      query: (data) => {
        const { id, file } = data;
        const formData = new FormData();
        formData.append('file', file);

        return ({
          method: 'POST',
          url: `/SolicitudPago/insertAttachmentSP/${id}`,
          data: formData,
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
      },
      transformErrorResponse: () => ({
        message: i18n.t('error_attaching_payment_file'),
      }),
      invalidatesTags: ['paymentRequests'],
    }),
    getPaymentRequestAttachedFileContentApi: build.query({
      query: (values) => {
        const dataToSend = {id: values.id};
        return { method: 'POST', url: '/SolicitudPago/descargarSolicitudPago', data: qs.stringify(dataToSend) };
      },
      transformResponse: (response, meta, arg) => {
        downloadFile(response, arg?.name);
        return {message: i18n.t('success_download_file')};
      },
      transformErrorResponse: () => ({
        message: i18n.t('error_download_file'),
      }),
    }),
    downloadPaymentRequestPdf: build.query({
      query: (values) => {
        const dataToSend = {id_sp: values.id};
        return { method: 'POST', url: '/SolicitudPago/generarPdf', data: qs.stringify(dataToSend) };
      },
      transformResponse: (response, meta, arg) => {
        downloadFile(response, arg?.name);
        return {message: i18n.t('success_download_file')};
      },
      transformErrorResponse: () => ({
        message: i18n.t('error_download_file'),
      }),
    }),
    downloadFinalPaymentRequest: build.query({
      query: (values) => {
        const dataToSend = {id: values.id};
        return { method: 'POST', url: '/SolicitudPago/descargarSolicitudPagoFactura', data: qs.stringify(dataToSend) };
      },
      transformResponse: (response) => {
        downloadFile(response?.factura, response?.nombre_factura);
        return {message: i18n.t('success_download_file')};
      },
      transformErrorResponse: () => ({
        message: i18n.t('error_download_file'),
      }),
    }),

  }),
});

export const {
  useGetFiltersPaymentRequestsApiQuery,
  useGetPaymentRequestsApiQuery,
  useLazyGetPaymentRequestsApiQuery,
  useCreatePaymentRequestApiMutation,
  useChangePaymentRequestStatusApiMutation,
  useAttachDocToPaymentRequestApiMutation,
  useLazyGetPaymentRequestAttachedFileContentApiQuery,
  useLazyDownloadPaymentRequestPdfQuery,
  useLazyDownloadFinalPaymentRequestQuery,
} = paymentRequestsApi;

const paymentRequestsSlice = createSlice({
  name: 'paymentRequests',
  initialState: {
    appliedFilters: null,
  },
  reducers: {
    setAppliedFilters(state, action) {
      state.appliedFilters = action.payload;
    },
  },
});

export const { setAppliedFilters } = paymentRequestsSlice.actions;
export default paymentRequestsSlice.reducer;
