import React from 'react';
import {
  Button,
  Col,
  Row,
  Typography,
} from 'antd';
import 'antd/dist/antd.less';

import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import Colors from '../../../assets/colors/Colors';

const { Text } = Typography;

const styles = {
  textActive: {
    fontSize: 12,
    color: Colors.PRIMARY_COLOR,
    fontFamily: 'Roboto-Regular',
    verticalAlign: 'middle'
  },
  textInactive: {
    fontSize: 12,
    color: 'white',
    fontFamily: 'Roboto-Regular',
    verticalAlign: 'middle'
  },
  lineActive: {
    backgroundColor: Colors.PRIMARY_COLOR,
    height: 3,
    width: '100%'
  },
  lineInactive: { backgroundColor: 'transparent', height: 3, width: '100%' },
  tab: { width: '100px' },
};

export default function TopBarItem(props) {
  const {
    clickHandler,
    route,
    label,
    icon
  } = props;

  const location = useLocation();

  return (
    <Col
      style={styles.tab}
      onClick={() => {
        clickHandler();
      }}
    >
      <Row justify="center">
        <Button
          type="link"
          htmlType="button"
          icon={icon}
        />
      </Row>
      <Row justify="center">
        <Button
          type="text"
          htmlType="button"
        >
          <Text
            style={
                        location.pathname === route
                          ? styles.textActive
                          : styles.textInactive
                      }
          >
            {label}
          </Text>
        </Button>
      </Row>
      <Row justify="center">
        <div
          style={
                      location.pathname === route
                        ? styles.lineActive
                        : styles.lineInactive
                    }
        />
      </Row>
    </Col>
  );
}

TopBarItem.defaultProps = {
  clickHandler: undefined,
  route: undefined,
  label: undefined,
  icon: undefined
};

TopBarItem.propTypes = {
  clickHandler: PropTypes.func,
  route: PropTypes.string,
  label: PropTypes.string,
  icon: PropTypes.element,
};
