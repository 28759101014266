import ConstantsLogin from './loginConstants';

export const reducerLoginResult = (state = { result: false, msg: null }, action = {}) => {
  switch (action.type) {
    case ConstantsLogin.LOGIN_RESULT:
      return action;
    case ConstantsLogin.CLEAR_LOGIN_RESULT:
      return {
        result: false,
        msg: null,
      };
    default:
      return state;
  }
};

export const reducerLoginByHashResult = (state = { result: false, msg: null }, action = {}) => {
  switch (action.type) {
    case ConstantsLogin.LOGIN_BY_HASH_RESULT:
      return action;
    default:
      return state;
  }
};
