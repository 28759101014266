import React, { useEffect, useState } from 'react';
import {
  Col,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Row,
  Select,
  Typography,
  DatePicker,
  Spin,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { CloseCircleOutlined } from '@ant-design/icons';
import moment from 'moment';

import ConstantsDate from '../../../constants/ConstantsDate';
import './styles/styles.css';

import {useCreatePaymentRequestApiMutation, useGetFiltersPaymentRequestsApiQuery } from '../../../services/paymentRequests';
import { useLazyGetBanksApiQuery } from '../../../services/collectionRequests';
import { defaultRequiredRules, validateEmail} from '../../../utils/Utils';

const { Text } = Typography;
const { Option } = Select;

function getOptionsForSelect(list, className, value = 'value', label = 'label') {
  return list?.map((i) => (
    <Option key={i[value]} value={i[value]}>
      <Text className={className}>
        {i[label]}
      </Text>
    </Option>
  ));
}

export default function NewPaymentRequestModal(props) {
  const [chain, setChain] = useState('');
  const [bank, setBank] = useState('');
  const [feePercentage, setFeePercentage] = useState(0);

  const { t } = useTranslation();
  const loading = useSelector((state) => state.reducerLoading);

  const {
    data: filters
  } = useGetFiltersPaymentRequestsApiQuery();

  const [
    createPaymentRequest,
    {
      data: createPaymentRequestResult,
      isLoading: isLoadingCreatePaymentRequest,
      isSuccess: isSuccessCreatePaymentRequest,
      error: errorCreatePaymentRequest,
      reset: resetCreatePaymentRequest
    }
  ] = useCreatePaymentRequestApiMutation({
    fixedCacheKey: 'shared-create-payment-request'
  });

  const [
    getBanksInfoByChain,
    {
      data: banksInfoList,
      error: errorBanksInfoList
    }
  ] = useLazyGetBanksApiQuery();

  const {
    handleOk,
    handleCancel,
    visible
  } = props;
  const { TextArea } = Input;

  const [form] = Form.useForm();
  const onOk = () => {
    form.submit();
  };

  useEffect(() => {
    if (errorCreatePaymentRequest) {
      message.error(errorCreatePaymentRequest.message);
    } else if (isSuccessCreatePaymentRequest) {
      message.success(createPaymentRequestResult.message);
      resetCreatePaymentRequest();
      handleOk();
    }
  }, [errorCreatePaymentRequest, isSuccessCreatePaymentRequest, createPaymentRequestResult, handleOk]);

  useEffect(() => {
    if (chain) {
      getBanksInfoByChain({
        chain
      });
      const currentChain = filters?.chains?.find((item) => item.id_cadena === chain);
      setFeePercentage(Number(currentChain?.porcentaje));
    }
  }, [chain, getBanksInfoByChain, form]);

  useEffect(() => {
    changeAmountFields(form.getFieldValue('amount'));
  }, [feePercentage, setFeePercentage, form]);

  useEffect(() => {
    if (errorBanksInfoList) {
      message.error(errorBanksInfoList.message);
    }
    if (bank) {
      const currentBank = banksInfoList?.find((item) => item.id === bank);
      form.setFieldValue('bank', currentBank?.banco);
      form.setFieldValue('bankAddress', currentBank?.direccion);
      form.setFieldValue('bankAccountNumber', currentBank?.numero_cuenta_bancaria);
      form.setFieldValue('swift', currentBank?.swift);
    }
  }, [bank, errorBanksInfoList, form]);

  const onFinishForm = () => {
    if (validateEmail(form.getFieldValue('email_company_cc'))) {
      createPaymentRequest({
        date: form.getFieldValue('date')?.format('YYYY-MM-DD'),
        chain,
        company: form.getFieldValue('company'),
        description: form.getFieldValue('description') ?? undefined,
        bankAccount: bank,
        currency: 'EUR',
        amount: form.getFieldValue('amount'),
        bankToTransferOwner: form.getFieldValue('bankToTransferOwner'),
        bankToTransfer: form.getFieldValue('bankToTransfer'),
        bankToTransferAddress: form.getFieldValue('bankToTransferAddress'),
        bankToTransferAccountNumber: form.getFieldValue('bankToTransferAccountNumber'),
        bankToTransferSwift: form.getFieldValue('bankToTransferSwift'),
        emailCompany: form.getFieldValue('email_company_cc'),
      });
    } else {
      message.error(t('error_email_format'));
    }
  };

  const chainsOptions = getOptionsForSelect(filters?.chains, 'text-option-value', 'id_cadena', 'nombre_cadena');
  const companiesOptions = getOptionsForSelect(filters?.companies.filter((item) => !chain || item.id_cadena === chain), 'text-option-value');
  const banksOptions = getOptionsForSelect(banksInfoList && banksInfoList?.length ? banksInfoList : [], 'text-option-value', 'id', 'titular');

  const formInitialValues = {
    date: moment(),
  };

  const changeAmountFields = (value) => {
    const feeAmount = (value * feePercentage) / 100;
    form.setFieldValue('feeAmount', feeAmount);
    form.setFieldValue('total', value - feeAmount);
  };

  return (
    <Spin spinning={isLoadingCreatePaymentRequest} size="large">
      <Modal
        title={t('text_new_payment_request')}
        open={visible}
        onOk={onOk}
        confirmLoading={loading}
        onCancel={() => {
          handleCancel();
        }}
        width={800}
        okText={t('text_create_payment_request')}
        closeIcon={<CloseCircleOutlined className="icon-payment-request" />}
      >
        <Form
          form={form}
          name="advanced_search"
          className="ant-advanced-search-form"
          labelCol={{
            span: 6,
          }}
          onFinish={onFinishForm}
          initialValues={formInitialValues}
        >
          <div className="container-modal">
            <div>
              {/* date */}
              <Row gutter={24}>
                <Col span={24}>
                  <Form.Item
                    className="mb-1"
                    name="date"
                    label={
                      <Text className="text-title-description">{t('text_date')}</Text>
                }
                  >
                    <DatePicker
                      className="input-payment-request-modal"
                      format={ConstantsDate.DATE_FORMAT_PICKER}
                      disabled
                    />
                  </Form.Item>
                </Col>
              </Row>

              {/* cadena y empresa */}
              <Row gutter={24}>
                <Col span={24}>
                  <Form.Item
                    className="mb-1"
                    name="chain"
                    label={
                      <Text className="text-title-description">{t('text_chain')}</Text>
                }
                    rules={defaultRequiredRules(t('required_field'))}
                  >
                    <Select
                      className="select-input"
                      allowClear
                      showSearch
                      onChange={(value) => {
                        setChain(value);
                      }}
                      value={chain}
                      filterOption={(input, option) => option?.children?.props?.children?.toLowerCase().includes(input.toLowerCase())}
                    >
                      {chainsOptions}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={24}>
                <Col span={24}>
                  <Form.Item
                    className="mb-1"
                    name="company"
                    label={
                      <Text className="text-title-description">{t('company')}</Text>
                }
                    rules={defaultRequiredRules(t('required_field'))}
                  >
                    <Select
                      className="select-input"
                      allowClear
                      showSearch
                      filterOption={(input, option) => option?.children?.props?.children?.toLowerCase().includes(input.toLowerCase())}
                      onChange={(value) => {
                        form.setFieldValue('nit', filters?.companies?.find((item) => item.value === value.toString())?.nit);
                      }}
                    >
                      {companiesOptions}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              {/* nit */}
              <Row gutter={24}>
                <Col span={24}>
                  <Form.Item
                    name="nit"
                    className="mb-1"
                    label={
                      <Text className="text-title-description">{t('nit')}</Text>
                }
                  >
                    <Input
                      className="input-payment-request-modal"
                      disabled
                    />
                  </Form.Item>
                </Col>
              </Row>

              {/* email company cc */}
              <Row gutter={24}>
                <Col span={24}>
                  <Form.Item
                    name="email_company_cc"
                    label={
                      <Text className="text-title-description">{t('email_company_cc_solicitud_pago')}</Text>
                    }
                    rules={[
                      {
                        type: 'email',
                        message: 'El correo electrónico no es válido',
                      }
                    ]}
                  >
                    <Input
                      className="input-collection-request-modal"
                      type="email"
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={24}>
                <Col
                  span={24}
                >
                  <Form.Item
                    className="mb-1"
                    name="amount"
                    label={
                      <Text className="text-title-description">{t('text_import')}</Text>
                }
                    rules={defaultRequiredRules(t('requiredAmount'))}
                  >
                    <InputNumber
                      addonAfter="EUR"
                      className="input-payment-request-modal"
                      onChange={(value) => {
                        changeAmountFields(value);
                      }}
                      decimalSeparator=","
                      stringMode
                    />

                  </Form.Item>
                </Col>

              </Row>

              <Row gutter={24}>
                <Col
                  span={24}
                >
                  <Form.Item
                    name="feeAmount"
                    className="mb-1"
                    label={
                      <Text className="text-title-description">{`${t('adg_profit')} - (${feePercentage}%)`}</Text>
                    }
                    rules={defaultRequiredRules(t('required_field'))}
                  >
                    <InputNumber
                      addonAfter="EUR"
                      className="input-payment-request-modal"
                      disabled
                    />

                  </Form.Item>
                </Col>

              </Row>

              <Row gutter={24}>
                <Col
                  span={24}
                >
                  <Form.Item
                    name="total"
                    label={
                      <Text className="text-title-description">{t('text_pay_total')}</Text>
                }
                    rules={defaultRequiredRules(t('required_field'))}
                  >
                    <InputNumber
                      disabled
                      addonAfter="EUR"
                      className="input-payment-request-modal"
                    />

                  </Form.Item>
                </Col>

              </Row>
            </div>

            {/* payment to manage fields */}
            <Row className="mb-3" gutter={2} justify="start">
              <Col span={6}>
                <div className="justify-flex-end modal-section-name-text">
                  Desde la cuenta:
                </div>
              </Col>
            </Row>

            {/* bank info  */}
            <div>
              <Row gutter={24}>
                <Col span={24}>
                  <Form.Item
                    name="bank_account_owner"
                    className="mb-1"
                    rules={defaultRequiredRules(t('required_field'))}
                    label={
                      <Text className="text-title-description">{t('bank_account_owner')}</Text>
                }
                  >
                    <Select
                      disabled={!chain}
                      className="select-input"
                      allowClear
                      showSearch
                      onChange={(value) => {
                        setBank(value);
                      }}
                      value={bank}
                      filterOption={(input, option) => option?.children?.props?.children?.toLowerCase().includes(input.toLowerCase())}
                    >
                      {banksOptions}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={24}>
                  <Form.Item
                    name="bank"
                    className="mb-1"
                    label={
                      <Text className="text-title-description">{t('text_bank')}</Text>
                }
                  >
                    <Input
                      className="input-payment-request-modal"
                      disabled
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={24}>
                  <Form.Item
                    className="mb-1"
                    name="bankAddress"
                    label={
                      <Text className="text-title-description">{t('bank_address')}</Text>
                }
                  >
                    <Input
                      className="input-payment-request-modal"
                      disabled
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={24}>
                  <Form.Item
                    className="mb-1"
                    name="bankAccountNumber"
                    label={
                      <Text className="text-title-description">{t('bank_account_number')}</Text>
                }
                  >
                    <Input
                      className="input-payment-request-modal"
                      disabled
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={24}>
                  <Form.Item
                    name="swift"
                    label={
                      <Text className="text-title-description">{t('swift')}</Text>
                }
                  >
                    <Input
                      className="input-payment-request-modal"
                      disabled
                    />
                  </Form.Item>
                </Col>
              </Row>
            </div>

            {/* bank to transfer info  */}
            <div>
              <Row className="mb-3" gutter={2} justify="start">
                <Col span={6}>
                  <div className="justify-flex-end modal-section-name-text">
                    Transferir a:
                  </div>
                </Col>
              </Row>

              <Row gutter={24}>
                <Col span={24}>
                  <Form.Item
                    name="bankToTransferOwner"
                    className="mb-1"
                    label={
                      <Text className="text-title-description">{t('bank_account_owner')}</Text>
                }
                    rules={defaultRequiredRules(t('required_field'))}
                  >
                    <Input
                      className="input-payment-request-modal"
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={24}>
                <Col span={24}>
                  <Form.Item
                    name="bankToTransfer"
                    className="mb-1"
                    label={
                      <Text className="text-title-description">{t('text_bank')}</Text>
                }
                    rules={defaultRequiredRules(t('required_field'))}
                  >
                    <Input
                      className="input-payment-request-modal"

                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={24}>
                <Col span={24}>
                  <Form.Item
                    className="mb-1"
                    name="bankToTransferAddress"
                    label={
                      <Text className="text-title-description">{t('bank_address')}</Text>
                }
                    rules={defaultRequiredRules(t('required_field'))}
                  >
                    <Input
                      className="input-payment-request-modal"
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={24}>
                <Col span={24}>
                  <Form.Item
                    className="mb-1"
                    name="bankToTransferAccountNumber"
                    label={
                      <Text className="text-title-description">{t('bank_account_number')}</Text>
                }
                    rules={defaultRequiredRules(t('required_field'))}
                  >
                    <Input
                      className="input-payment-request-modal"
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={24}>
                <Col span={24}>
                  <Form.Item
                    name="bankToTransferSwift"
                    label={
                      <Text className="text-title-description">{t('swift')}</Text>
                }
                    rules={defaultRequiredRules(t('required_field'))}
                  >
                    <Input
                      className="input-payment-request-modal"
                    />
                  </Form.Item>
                </Col>
              </Row>
            </div>

            <Row gutter={24}>
              <Col span={24}>
                <Form.Item
                  name="description"
                  label={
                    <Text className="text-title-description">{t('text_description')}</Text>
                }
                >
                  <TextArea
                    row={2}
                    placeholder={t('text_description')}
                    className="input-payment-request-modal"
                  />
                </Form.Item>
              </Col>
            </Row>
          </div>
        </Form>
      </Modal>
    </Spin>
  );
}

NewPaymentRequestModal.defaultProps = {
  visible: false,
  handleOk: undefined,
  handleCancel: undefined,
};

NewPaymentRequestModal.propTypes = {
  visible: PropTypes.bool,
  handleOk: PropTypes.func,
  handleCancel: PropTypes.func,
};
