import { all, fork, } from 'redux-saga/effects';
import * as loginSagas from './login';
import * as paymentsSagas from './payments';
import * as paySagas from './pay';
import * as preConciliationSagas from './preConciliation';
import * as conciliationsSagas from './conciliations';
import * as tpvManualSagas from './tpvManual';
import * as tpvTransactionsSagas from './tpvTransactions';

export default function* rootSaga() {
  yield all(
    [...Object.values(loginSagas),
      ...Object.values(paymentsSagas),
      ...Object.values(paySagas),
      ...Object.values(preConciliationSagas),
      ...Object.values(conciliationsSagas),
      ...Object.values(tpvManualSagas),
      ...Object.values(tpvTransactionsSagas)].map(fork),
  );
}
