const ConstantsConciliations = {
  GET_FILTERS_CONCILIATIONS: 'GET_FILTERS_CONCILIATIONS',
  GET_FILTERS_CONCILIATIONS_RESULT: 'GET_FILTERS_CONCILIATIONS_RESULT',
  GET_CONCILIATIONS: 'GET_CONCILIATIONS',
  GET_CONCILIATIONS_RESULT: 'GET_CONCILIATIONS_RESULT',
  ADD_NEW_PRECONCILIATION: 'ADD_NEW_PRECONCILIATION',
  ADD_NEW_PRECONCILIATION_RESULT: 'ADD_NEW_PRECONCILIATION_RESULT',
  GET_COMPANY_BY_CHAIN: 'GET_COMPANY_BY_CHAIN',
  GET_COMPANY_BY_CHAIN_RESULT: 'GET_COMPANY_BY_CHAIN_RESULT',
  CLEAR_COMPANIES: 'CLEAR_COMPANIES',
  GET_PDF_PREBILL: 'GET_PDF_PREBILL',
  GET_PDF_PREBILL_RESULT: 'GET_PDF_PREBILL_RESULT',
  CANCEL_CONCILIATION: 'CANCEL_CONCILIATION',
  CANCEL_CONCILIATION_RESULT: 'CANCEL_CONCILIATION_RESULT',
  CLEAR_CANCEL_REQUEST_RESULT: 'CLEAR_CANCEL_REQUEST_RESULT',
  CLEAR_NEW_PRECONCILIATION_RESULT: 'CLEAR_NEW_PRECONCILIATION_RESULT',
  SEND_PREBILL: 'SEND_PREBILL',
  SEND_PREBILL_RESULT: 'SEND_PREBILL_RESULT',
  GET_ALL_PDF_PREBILL: 'GET_ALL_PDF_PREBILL',
  SEND_ALL_PREBILL: 'SEND_ALL_PREBILL',
  SEND_ALL_BILL: 'SEND_ALL_BILL',
  SEND_ALL_BILL_RESULT: 'SEND_ALL_BILL_RESULT',
  CONCILIATIONS_CURRENT_FILTERS: 'CONCILIATIONS_CURRENT_FILTERS',
  PREVISUALIZE_CONCILIATION: 'PREVISUALIZE_CONCILIATION',
  PREVISUALIZE_CONCILIATION_RESULT: 'PREVISUALIZE_CONCILIATION_RESULT',
  CLEAR_NEW_CONCILIATION_PREVISUALIZAATION_RESULT: 'CLEAR_NEW_CONCILIATION_PREVISUALIZAATION_RESULT',
  GET_SALE_PDF: 'GET_SALE_PDF',

};

export default ConstantsConciliations;
