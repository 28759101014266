import React, { useState, useEffect } from 'react';
import {
  Button, Card, Col, Form, Input, message, Row, Spin, Typography
} from 'antd';
import 'antd/dist/antd.less';
import { MailOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { validateMail } from '../../utils/Utils';
import { actionLoading } from '../../store/reducers/loading/loadingActions';
import { actionForgotPassword, actionClearForgotPasswordResult } from '../../store/reducers/login/loginActions';
import ConstantsValue from '../../constants/ConstantsValue';
import background from '../../assets/images/adobe-stock-91340865-neg.jpg';
import background2x from '../../assets/images/adobe-stock-91340865-neg@2x.jpg';
import background3x from '../../assets/images/adobe-stock-91340865-neg@3x.jpg';
import logo from '../../assets/images/group-28.svg';
import Colors from '../../assets/colors/Colors';

const { Link, Text } = Typography;

const styles = {
  rowLogo: {
    height: '100%',
    width: '100%',
    marginRight: 56,
  },
  card: {
    backgroundColor: 'transparent',
    width: 300,
  },
  input: {
    borderRadius: 4,
    borderColor: Colors.BORDER_COLOR,
    fontFamily: 'Roboto-Regular',
    fontSize: 16,
    paddingTop: 6,
  },
  icon: {
    color: Colors.PRIMARY_COLOR,
  },
  textTitle: {
    fontSize: 16,
    color: 'white',
    fontFamily: 'Roboto',
    verticalAlign: 'middle',
  },
  textLogo1: {
    fontSize: 14,
    color: 'white',
    fontFamily: 'Roboto-Regular',
    verticalAlign: 'middle',
  },
  textLogo2: {
    fontSize: 14,
    color: '#2bc6ce',
    fontFamily: 'Roboto-Regular',
    verticalAlign: 'middle',
    marginLeft: 2,
  },
};

export default function ResetPassword() {
  const navigate = useNavigate();

  const [email, setEmail] = useState('');

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const forgotPasswordResult = useSelector((state) => state.reducerForgotPasswordResult);
  const loading = useSelector((state) => state.reducerLoading);

  useEffect(() => {
    if (forgotPasswordResult.msg !== null && forgotPasswordResult.result === false) {
      message.error(forgotPasswordResult.msg);
    }
    if (forgotPasswordResult.result === true) {
      message.success(forgotPasswordResult.msg);
      dispatch(actionClearForgotPasswordResult());
      setTimeout(() => {
        navigateLogin();
      }, 1500);
    }
  });

  const validateEmail = () => {
    if (validateMail(email)) {
      dispatch(actionLoading(true));
      dispatch(actionForgotPassword(email));
    } else {
      message.error(t('error_email_format'));
    }
  };

  const navigateLogin = () => {
    navigate(-1);
  };

  if (Cookies.get(ConstantsValue.USER_DATA) !== undefined
    && Cookies.get(ConstantsValue.USER_DATA) !== null) {
    return <Navigate to="payments" />;
  }
  return (
    <div>
      <Row justify="center">
        <Col span={24}>
          <img
            alt="background"
            src={background}
            srcSet={`${background2x} 2x,${background3x} 3x`}
            className="AdobeStock_91340865-neg"
          />
        </Col>
      </Row>
      <Row justify="center">
        <Col span={24}>
          <Spin spinning={loading} size="large">
            <Row justify="center">
              <Card
                title={(
                  <Text style={styles.textTitle}>
                    {t('text_title_forgot_password')}
                  </Text>
                )}
                bordered={false}
                style={styles.card}
              >
                <Form
                  name="normal_login"
                  initialValues={{
                    remember: true,
                    size: 'middle',
                  }}
                >
                  <Form.Item
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: t('error_empty_field'),
                      },
                    ]}
                  >
                    <Input
                      prefix={<MailOutlined style={styles.icon} />}
                      placeholder={t('hint_email')}
                      onChange={(text) => {
                        setEmail(text.target.value);
                      }}
                      value={email}
                      style={styles.input}
                    />
                  </Form.Item>
                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      disabled={email.length === 0 || !validateMail(email)}
                      onClick={validateEmail}
                      className="bg bg-color-secondary text text-color-white px-3"
                    >
                      {t('button_send')}
                    </Button>
                  </Form.Item>
                  <Form.Item>
                    <Row align="middle">
                      <Col>
                        <Button
                          type="link"
                          htmlType="button"
                          onClick={navigateLogin}
                        >
                          {t('text_comeback_login')}
                        </Button>
                      </Col>
                    </Row>
                  </Form.Item>
                </Form>
              </Card>
            </Row>
          </Spin>
        </Col>
      </Row>
      <Row justify="end" style={styles.rowLogo}>
        <Col>
          <Row>
            <img
              alt="logo"
              src={logo}
            />
          </Row>
          <Row justify="end">
            <Text style={styles.textLogo1}>
              {t('text_contact_1')}
            </Text>
            <Link
              className="text text-size-0-8 text-color-secondary"
              href="mailto:info@adgtravel.com"
              target="_blank"
            >
              {t('text_contact_2')}
            </Link>
          </Row>
        </Col>
      </Row>
    </div>
  );
}
