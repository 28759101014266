// import { createSlice } from '@reduxjs/toolkit';
import { api } from './api';
import i18n from '../assets/language/i18n';

export const asyncProcessApi = api.injectEndpoints({
  tagTypes: ['asyncProcessSendPreBill'],
  endpoints: (build) => ({
    sendMultiplePreBills: build.mutation({
      query: (data) => ({
        method: 'POST',
        url: '/async-process/send-prebill',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        data: {
          id_jobs: data?.idJobs,
          mensaje: data?.message,
        }
      }),
      transformErrorResponse: () => ({
        message: i18n.t('error_send_pre_bills_process'),
      }),
      providesTags: ['asyncProcessSendPreBill']
    }),
    sendMultipleBills: build.mutation({
      query: (data) => ({
        method: 'POST',
        url: '/async-process/send-bill',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        data: {
          id_jobs: data?.idJobs,
          mensaje: data?.message,
        }
      }),
      transformErrorResponse: () => ({
        message: i18n.t('error_send_bills_process'),
      }),
      providesTags: ['asyncProcessSendPreBill']
    }),
    createPreconciliation: build.mutation({
      query: (data) => ({
        method: 'POST',
        url: '/async-process/init-conciliation',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        data: {
          id_empresas: data?.idCompanies,
          nombre: data?.name,
          fecha_inicio: data?.startDate,
          fecha_final: data?.endDate,
          moneda: data?.currency,
        }
      }),
      transformErrorResponse: () => ({
        message: i18n.t('error_send_pre_bills_process'),
      }),
      providesTags: ['asyncProcessSendPreBill']
    }),
    restartFailedProcess: build.mutation({
      query: (id) => ({
        method: 'POST',
        url: `/async-process/${id}`,
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      }),
      transformErrorResponse: () => ({
        message: i18n.t('error_restart_process'),
      }),
      providesTags: ['asyncProcessSendPreBill']
    }),
    getAsyncProcess: build.query({
      query(data) {
        return {
          url: '/async-process',
          method: 'GET',
          params: {
            start: data.start ?? 0,
            limit: data.limit ?? 10,
            status: data?.status
          },
        };
      },
    })
  }),
});

export const {
  useSendMultiplePreBillsMutation,
  useLazyGetAsyncProcessQuery,
  useGetAsyncProcessQuery,
  useCreatePreconciliationMutation,
  useRestartFailedProcessMutation,
  useSendMultipleBillsMutation,
} = asyncProcessApi;
