const ConstantsAsyncProcessStatus = {
  ERROR: 'Error',
  FINISHED: 'Completado',
  PENDING: 'Pendiente',
  IN_PROCESS: 'En proceso',
};

const SEND_PRE_BILL_KEY = 'SEND_PRE_BILL';
const SEND_BILL_KEY = 'SEND_BILL';

export {ConstantsAsyncProcessStatus, SEND_PRE_BILL_KEY, SEND_BILL_KEY};
