import React from 'react';
import {
  Routes,
  Route,
} from 'react-router-dom';
import Login from './components/login';
import NewPassword from './components/newPassword';
import ResetPassword from './components/resetPassword';
import Home from './components/home';
import Pay from './components/pay';
import './assets/scss/_overrides.scss';
import './App.less';
import PageNotFound from './PageNotFound';
import PayResult from './components/payResult';

export default function App() {
  return (
    <Routes>
      <Route
        path="/"
        element={<Home />}
      />
      <Route
        path="payments/*"
        element={<Home />}
      />
      <Route
        path="login"
        element={<Login />}
      />
      <Route
        path="resetpassword"
        element={<ResetPassword />}
      />
      <Route
        path="new-password/token/:hash"
        element={<NewPassword />}
      />
      <Route
        path="pay"
        element={(
          <div className="ant-row finalizar-pago">
            <Pay />
          </div>
        )}
      />
      <Route
        path="payResult"
        element={(
          <div className="ant-row finalizar-pago">
            <PayResult />
          </div>
        )}
      />
      <Route
        path="*"
        element={<PageNotFound />}
      />
    </Routes>
  );
}
