import React, { useEffect, useState } from 'react';
import {
  BackTop,
  Button,
  Col,
  Input,
  message,
  Row,
  Popconfirm,
  Space,
  Spin,
  Table,
  Tooltip,
  Typography
} from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  CheckCircleOutlined,
  PlusOutlined,
  SearchOutlined, QuestionCircleOutlined,
  CloseCircleOutlined,
  RightCircleOutlined,
  UpCircleOutlined,
  UploadOutlined,
  FilePdfOutlined,
  FileExcelOutlined,
  ReloadOutlined
} from '@ant-design/icons';
import 'antd/dist/antd.less';
import Highlighter from 'react-highlight-words';
import moment from 'moment';
import * as XLSX from 'xlsx';
import i18n from '../../../assets/language/i18n';
import FilterCollectionRequest from './FilterCollectionRequest';
import Colors from '../../../assets/colors/Colors';
import NewCollectionRequestModal from './NewCollectionRequestModal';
import ConstantsRoutes from '../../../constants/ConstantsRoutes';
import { getWritePermissionByTab } from '../../../utils/Utils';
import ConstantsCollectionRequestsStates from '../../../constants/ConstantsCollectionRequestsStates';
import styles from './styles/stylesIndex';
import Attachments from './Attachments';
import AttachmentModal from './AttachmentModal';
import {
  useLazyGetCollectionRequestsApiQuery, useLazyDownloadCollectionRequestPdfQuery,
  useGetFiltersCollectionRequestsApiQuery, useChangeCollectionRequestStatusApiMutation, useLazyResendCollectionRequestQuery
} from '../../../services/collectionRequests';

const { Text } = Typography;

export default function Conciliations() {
  const [showModalNewCollectionRequest, setShowModalNewCollectionRequest] = useState(false);

  const [filteredInfo, setFilteredInfo] = useState(null);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [showModalAttachment, setShowModalAttachment] = useState(false);
  const [collectionRequestSelected, setCollectionRequestSelected] = useState(null);

  const { t } = useTranslation();
  const loading = useSelector((state) => state.reducerLoading);

  const [
    getCollectionRequests,
    {
      data: collectionRequestsData,
      isFetching: isFetchingGetCollectionRequests,
      error: errorGetCollectionRequests
    }
  ] = useLazyGetCollectionRequestsApiQuery();
  const collectionRequests = collectionRequestsData || [];

  const [
    downloadPdfFile,
    {
      isFetching: isDownloadingPdfFile,
      error: errorDownloadingPdfFile
    }
  ] = useLazyDownloadCollectionRequestPdfQuery();

  const [
    resendCollectionRequest,
    {
      isFetching: isResendingCollectionRequest,
      isSuccess: isSuccessResendingCollectionRequest,
      error: errorResendingCollectionRequest
    }
  ] = useLazyResendCollectionRequestQuery();

  const [
    changeStatusCollectionRequest,
    {
      data: dataChangeStatusCollectionRequest,
      isLoading: isLoadingChangeStatusCollectionRequest,
      isSuccess: isSuccessChangeStatusCollectionRequest,
      error: errorChangeStatusCollectionRequest
    }
  ] = useChangeCollectionRequestStatusApiMutation({ });

  const {
    data: filtersCollectionRequestOptions
  } = useGetFiltersCollectionRequestsApiQuery();

  const collectionRequestsAppliedFilters = useSelector(
    (state) => state.collectionRequests.appliedFilters
  );

  useEffect(() => {
    if (collectionRequestsAppliedFilters) {
      getCollectionRequests(collectionRequestsAppliedFilters);
    }
  }, [collectionRequestsAppliedFilters, getCollectionRequests]);

  useEffect(() => {
    if (errorGetCollectionRequests) {
      message.error(errorGetCollectionRequests.message);
    }
    if (isSuccessChangeStatusCollectionRequest) {
      message.success(dataChangeStatusCollectionRequest?.message);
    }
  }, [errorGetCollectionRequests, isSuccessChangeStatusCollectionRequest]);

  useEffect(() => {
    if (errorChangeStatusCollectionRequest) {
      message.error(errorChangeStatusCollectionRequest.message);
    }
  }, [errorChangeStatusCollectionRequest]);

  useEffect(() => {
    if (errorDownloadingPdfFile) {
      message.error(errorDownloadingPdfFile.message);
    }
  }, [errorDownloadingPdfFile]);

  useEffect(() => {
    if (errorResendingCollectionRequest) {
      message.error(errorResendingCollectionRequest.message);
    }
    if (isSuccessResendingCollectionRequest) {
      message.success(dataChangeStatusCollectionRequest?.message);
    }
  }, [errorResendingCollectionRequest, isSuccessResendingCollectionRequest]);

  const handleChange = (pagination, filters) => {
    setFilteredInfo(filters);
  };

  const clearAllFilters = () => {
    setFilteredInfo(null);
  };

  const isLoading = loading || isFetchingGetCollectionRequests || isLoadingChangeStatusCollectionRequest;

  const filtersSelected = filteredInfo || {};

  const agenciesFilters = [];
  const companiesFilters = [];
  const chainsFilters = [];
  const statusFilters = [];
  const currencyFilters = [];

  collectionRequests.forEach((collectionRequest) => {
    if (collectionRequest.id_tour_operador && !agenciesFilters.some((item) => item.value === collectionRequest.id_tour_operador)) {
      agenciesFilters.push({
        text: filtersCollectionRequestOptions.agencies.find((item) => item.id === collectionRequest.id_tour_operador)?.nombre,
        value: collectionRequest.id_tour_operador,
      });
    }
    if (collectionRequest.id_empresa && !companiesFilters.some((item) => item.value === collectionRequest.id_empresa)) {
      companiesFilters.push({
        text: filtersCollectionRequestOptions.companies.find((item) => item.value === collectionRequest.id_empresa)?.label,
        value: collectionRequest.id_empresa,
      });
    }
    if (collectionRequest.id_cadena && !chainsFilters.some((item) => item.value === collectionRequest.id_cadena)) {
      chainsFilters.push({
        text: filtersCollectionRequestOptions.chains.find((item) => item.id_cadena === collectionRequest.id_cadena)?.nombre_cadena,
        value: collectionRequest.id_cadena,
      });
    }
    if (collectionRequest.estado && !statusFilters.some((item) => item.value === collectionRequest.estado)) {
      statusFilters.push({
        text: collectionRequest.estado,
        value: collectionRequest.estado,
      });
    }

    if (collectionRequest.moneda && !currencyFilters.some((item) => item.value === collectionRequest.moneda)) {
      currencyFilters.push({
        text: collectionRequest.moneda,
        value: collectionRequest.moneda,
      });
    }
  });

  agenciesFilters.sort((a, b) => a.text?.localeCompare(b.text, i18n.language));
  chainsFilters.sort((a, b) => a.text?.localeCompare(b.text, i18n.language));
  companiesFilters.sort((a, b) => a.text?.localeCompare(b.text, i18n.language));
  statusFilters.sort((a, b) => a.text?.localeCompare(b.text, i18n.language));

  const getColumnSearchProps = (dataIndex, searchInput) => ({
    filterDropdown: ({
      setSelectedKeys, selectedKeys, confirm, clearFilters,
    }) => {
      const hintSearch = t('text_headline');
      return (
        <div style={{ padding: 8 }}>
          <Input
            ref={(node) => {
              searchInput = node;
            }}
            placeholder={hintSearch}
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            className="input-collection-request-table"
          />
          <Space>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              className="button-primary"
            >
              {t('text_button_filter')}
            </Button>
            <Button onClick={() => handleReset(clearFilters)} size="small" className="button-default">
              {t('text_button_clear')}
            </Button>
          </Space>
        </div>
      );
    },
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{ color: filtered ? Colors.PRIMARY_COLOR : Colors.ICON_COLOR }}
      />
    ),
    onFilter: (value, record) => (record[dataIndex] ? record[dataIndex]?.toString().toLowerCase().includes(value?.toLowerCase()) : ''),

    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.select());
      }
    },
    render: (text, record) => {
      const recordStatus = record.estado;
      let styleHigh;
      let styleNormal;
      if (recordStatus === ConstantsCollectionRequestsStates.CLOSED) {
        styleHigh = styles.textValueHighGreenColumn;
        styleNormal = styles.textValueGreenColumn;
      } else if (recordStatus === ConstantsCollectionRequestsStates.PAYED) {
        styleHigh = styles.textValueHighDarkGreenColumn;
        styleNormal = styles.textValueDarkGreenColumn;
      } else if (recordStatus === ConstantsCollectionRequestsStates.CANCEL) {
        styleHigh = styles.textValueHighRedColumn;
        styleNormal = styles.textValueRedColumn;
      } else {
        styleHigh = styles.textValueHighYellowColumn;
        styleNormal = styles.textValueYellowColumn;
      }
      if (searchedColumn === dataIndex) {
        return (
          <Highlighter
            highlightStyle={styleHigh}
            unhighlightStyle={styleNormal}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text ? text.toString() : ''}
          />
        );
      } return (
        <Text style={styleNormal}>
          {text}
        </Text>
      );
    },
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const getExcelHeaders = () => [
    t('text_reference'),
    t('agency'),
    t('company'),
    t('chain'),
    t('text_description'),
    t('text_status'),
    t('text_amount'),
    t('text_currency'),
    t('text_date'),
  ];

  const getExcelData = () => collectionRequests?.map((collectionRequest) => ([
    collectionRequest.referencia,
    filtersCollectionRequestOptions.agencies.find((item) => item.id === collectionRequest.id_tour_operador)?.nombre,
    filtersCollectionRequestOptions.companies.find((item) => item.value === collectionRequest.id_empresa)?.label,
    filtersCollectionRequestOptions.chains.find((item) => item.id_cadena === collectionRequest.id_cadena)?.nombre_cadena,
    collectionRequest.descripcion,
    collectionRequest.estado,
    collectionRequest.importe,
    collectionRequest.moneda,
    collectionRequest.fecha,
  ]
  ));

  const approveOrPayBtn = (record, id) => {
    const isClosed = record.estado === ConstantsCollectionRequestsStates.CLOSED;
    return (
      <Popconfirm
        disabled={isClosed ? false
          : record.estado !== ConstantsCollectionRequestsStates.DRAFT}
        placement="bottomRight"
        title={(
          <Text className="text-title-popconfirm">
            {isClosed ? t('are_you_sure_set_payed_status_collection_request') : t('are_you_sure_approve_collection_request')}
          </Text>
            )}
        icon={<QuestionCircleOutlined style={styles.icon} />}
        onConfirm={() => {
          changeStatusCollectionRequest({
            id,
            status: isClosed ? ConstantsCollectionRequestsStates.PAYED.toLowerCase() : ConstantsCollectionRequestsStates.CLOSED.toLowerCase(),
          });
        }}
        okText={(
          <Text className="text-ok-button-popconfirm">
            {t('text_yes')}
          </Text>
            )}
        cancelText={(
          <Text className="text-cancel-btn-popconfirm">
            {t('text_no')}
          </Text>
            )}
      >
        <Tooltip title={isClosed ? t('text_pay_collection_request') : t('text_approve_collection_request')}>
          <Button
            type="link"
            htmlType="button"
            disabled={isClosed ? false
              : record.estado !== ConstantsCollectionRequestsStates.DRAFT}
            icon={<CheckCircleOutlined style={styles.icon} />}
            style={styles.buttonLink}
          />
        </Tooltip>
      </Popconfirm>
    );
  };

  const columns = [
    {
      title: <Text className="text">{t('text_reference')}</Text>,
      dataIndex: 'referencia',
      key: 'referencia',
      sorter: {
        compare: (a, b) => a.referencia.localeCompare(b.referencia, i18n.language),
        multiple: 10,
      },
      responsive: ['md'],
      ...getColumnSearchProps('referencia'),
      filteredValue: filtersSelected?.referencia || null
    },

    {
      title: <Text className="text">{t('text_chain')}</Text>,
      dataIndex: 'id_cadena',
      key: 'id_cadena',
      sorter: {
        compare: (a, b) => chainsFilters.find((item) => item.value === a.id_cadena)?.text?.localeCompare(chainsFilters.find((item) => item.value === b.id_cadena)?.text, i18n.language),
        multiple: 10,
      },
      filteredValue: filtersSelected?.id_cadena || null,
      filters: chainsFilters,
      onFilter: (value, record) => record.id_cadena?.indexOf(value) === 0,
      responsive: ['md'],
      render: (text) => (
        <Text className="text">
          {
            chainsFilters.find((item) => item.value === text)?.text
          }
        </Text>
      ),
    },

    {
      title: <Text className="text">{t('company')}</Text>,
      dataIndex: 'id_empresa',
      key: 'id_empresa',
      sorter: {
        compare: (a, b) => companiesFilters.find((item) => item.value === a.id_empresa)?.text?.localeCompare(companiesFilters.find((item) => item.value === b.id_empresa)?.text, i18n.language),
        multiple: 10,
      },
      filteredValue: filtersSelected?.id_empresa || null,
      filters: companiesFilters,
      onFilter: (value, record) => record.id_empresa?.indexOf(value) === 0,
      responsive: ['md'],
      render: (text) => (
        <Text className="text">
          {
            companiesFilters.find((item) => item.value === text)?.text
          }
        </Text>
      ),
    },
    {
      title: <Text className="text">{t('agency')}</Text>,
      dataIndex: 'id_tour_operador',
      key: 'id_tour_operador',
      sorter: {
        compare: (a, b) => agenciesFilters.find((item) => item.value === a.id_tour_operador)?.text?.localeCompare(agenciesFilters.find((item) => item.value === b.id_tour_operador)?.text, i18n.language),
        multiple: 10,
      },
      filteredValue: filtersSelected?.id_tour_operador || null,
      filters: agenciesFilters,
      onFilter: (value, record) => record.id_tour_operador?.indexOf(value) === 0,
      responsive: ['md'],
      render: (text) => (
        <Text className="text">
          {
            agenciesFilters.find((item) => item.value === text)?.text
          }
        </Text>
      ),
    },

    {
      title: <Text className="text">{t('text_date')}</Text>,
      dataIndex: 'fecha',
      key: 'fecha',
      sorter: {
        compare: (a, b) => moment(a.fecha).format('YYYYMMDD').localeCompare(moment(b.fecha).format('YYYYMMDD'), i18n.language),
        multiple: 4,
      },
      align: 'center',
      responsive: ['md'],
      render: (text) => (
        <Text className="text">
          {text ? moment(text).format('DD/MM/YYYY') : ''}
        </Text>
      ),
    },
    {
      title: <Text className="text">{t('text_amount')}</Text>,
      dataIndex: 'importe',
      key: 'importe',
      sorter: {
        compare: (a, b) => a.importe?.localeCompare(b.importe, i18n.language),
        multiple: 10,
      },
      responsive: ['md'],
      render: (text) => (
        <Text className="text">
          {`${text}`}
        </Text>
      )
    },
    {
      title: <Text className="text">{t('text_currency')}</Text>,
      dataIndex: 'moneda',
      key: 'moneda',
      sorter: {
        compare: (a, b) => a.moneda.localeCompare(b.moneda, i18n.language),
        multiple: 10,
      },
      filteredValue: filtersSelected?.moneda || null,
      filters: currencyFilters,
      onFilter: (value, record) => record.moneda?.indexOf(value) === 0,
      responsive: ['md'],
      render: (text) => (
        <Text className="text">
          {
            text
          }
        </Text>
      ),
    },

    {
      title: <Text className="text">{t('text_status')}</Text>,
      dataIndex: 'estado',
      key: 'estado',
      sorter: {
        compare: (a, b) => a.estado.localeCompare(b.estado, i18n.language),
        multiple: 10,
      },
      filteredValue: filtersSelected?.estado || null,
      filters: statusFilters,
      onFilter: (value, record) => record.estado?.indexOf(value) === 0,
      responsive: ['md'],
      render: (text) => (
        <Text className="text">
          {
            text
          }
        </Text>
      ),
    },
    {
      title: <Text className="text">{t('text_actions')}</Text>,
      dataIndex: 'id',
      key: 'id',
      responsive: ['md'],
      render: (id, record) => (
        <div>
          <Tooltip title={t('download_pdf')}>
            <Button
              type="link"
              htmlType="button"
              icon={<FilePdfOutlined style={styles.icon} />}
              style={styles.buttonLink}
              loading={isDownloadingPdfFile}
              onClick={() => {
                downloadPdfFile({id, name: `${t('collection_request')}-${record?.referencia}.pdf`});
              }}
            />
          </Tooltip>
          <Tooltip title={t('attach_file')}>
            <Button
              type="link"
              htmlType="button"
              disabled={
                record.estado !== ConstantsCollectionRequestsStates.DRAFT
              }
              icon={<UploadOutlined style={styles.icon} />}
              style={styles.buttonLink}
              onClick={() => {
                setCollectionRequestSelected(record);
                setShowModalAttachment(true);
              }}
            />
          </Tooltip>
          {approveOrPayBtn(record, id)}
          <Popconfirm
            disabled={
              record.estado !== ConstantsCollectionRequestsStates.DRAFT
              && record.estado !== ConstantsCollectionRequestsStates.CLOSED
            }
            placement="bottomRight"
            title={(
              <Text className="text-title-popconfirm">
                {t('text_cancel_collection_request')}
              </Text>
            )}
            icon={<QuestionCircleOutlined />}
            onConfirm={() => {
              changeStatusCollectionRequest({
                id,
                status: ConstantsCollectionRequestsStates.CANCEL.toLowerCase(),
              });
            }}
            okText={(
              <Text className="text-ok-button-popconfirm">
                {t('text_yes')}
              </Text>
            )}
            cancelText={(
              <Text className="text-cancel-btn-popconfirm">
                {t('text_no')}
              </Text>
            )}
          >
            <Tooltip title={t('text_cancel_collection_request')}>
              <Button
                className="icon-disabled"
                type="link"
                htmlType="button"
                disabled={
                  record.estado !== ConstantsCollectionRequestsStates.DRAFT
                  && record.estado !== ConstantsCollectionRequestsStates.CLOSED
                }
                icon={<CloseCircleOutlined />}
                style={styles.buttonLink}
              />
            </Tooltip>
          </Popconfirm>
          <Tooltip title={t('resend_collection_request')}>
            <Button
              type="link"
              htmlType="button"
              icon={<ReloadOutlined style={styles.icon} />}
              disabled={
                record.estado !== ConstantsCollectionRequestsStates.CLOSED
              }
              style={styles.buttonLink}
              loading={isResendingCollectionRequest}
              onClick={() => {
                resendCollectionRequest({ id });
              }}
            />
          </Tooltip>
        </div>
      ),
    },
  ];

  return (
    <Spin spinning={isLoading} size="large">
      <BackTop />
      <Row justify="end">
        <Col span={24}>
          {showModalNewCollectionRequest ? (
            <NewCollectionRequestModal
              handleOk={() => {
                setShowModalNewCollectionRequest(false);
              }}
              handleCancel={() => {
                setShowModalNewCollectionRequest(false);
              }}
              visible={showModalNewCollectionRequest}
            />
          ) : (
            ''
          )}
          {showModalAttachment ? (
            <AttachmentModal
              handleOk={() => {
                setShowModalAttachment(false);
              }}
              handleCancel={() => {
                setShowModalAttachment(false);
              }}
              collectionRequest={collectionRequestSelected}
            />
          ) : (
            ''
          )}
          <FilterCollectionRequest />
          <Row align="middle" justify="end">
            {getWritePermissionByTab(ConstantsRoutes.COLLECTION_REQUESTS) ? (
              <Col style={{ marginRight: 16 }}>
                <Button
                  type="primary"
                  icon={<PlusOutlined />}
                  size="small"
                  disabled={!getWritePermissionByTab(ConstantsRoutes.COLLECTION_REQUESTS)}
                  onClick={() => {
                    setShowModalNewCollectionRequest(true);
                  }}
                  className="btn btn-h-auto text text-w-bold px-3 py-2"
                >
                  {t('text_button_new_collection_request')}
                </Button>
              </Col>
            ) : <div />}
            <Col style={{ marginRight: 16 }}>
              <Button
                onClick={clearAllFilters}
                size="small"
                disabled={collectionRequests.length === 0}
                className="btn btn-h-auto text text-w-bold px-3 py-2"
              >
                {t('text_button_clear_filters')}
              </Button>
            </Col>
            <Col style={{ marginRight: 16 }}>
              <Button
                type="primary"
                icon={<FileExcelOutlined />}
                size="small"
                disabled={collectionRequests.length === 0}
                className="btn btn-h-auto text text-w-bold px-3 py-2"
                onClick={() => {
                  const wb = XLSX.utils.book_new();
                  const ws = XLSX.utils.json_to_sheet([]);
                  XLSX.utils.sheet_add_aoa(ws, [getExcelHeaders()]);
                  XLSX.utils.sheet_add_json(ws, getExcelData(), {
                    origin: 'A2',
                    skipHeader: true
                  });
                  XLSX.utils.book_append_sheet(wb, ws, 'solicitudesCobro');
                  XLSX.writeFile(wb, 'solicitudesCobro.xlsx');
                }}
              >
                {t('text_button_export')}
              </Button>
            </Col>
          </Row>
          <Table
            rowKey="id"
            style={{ width: '100%' }}
            pagination={{ total: collectionRequests.length, pageSizeOptions: ['10', '20', '50', '100', '200', '300', '500'] }}
            size="small"
            loading={isFetchingGetCollectionRequests}
            columns={columns}
            dataSource={collectionRequests}
            onChange={handleChange}
            expandable={{
              expandIcon: ({ expanded, onExpand, record }) => (
                expanded ? (
                  <UpCircleOutlined
                    onClick={(e) => onExpand(record, e)}
                    className="icon-collection-request"
                  />
                ) : (
                  <RightCircleOutlined
                    onClick={(e) => onExpand(record, e)}
                    className="icon-collection-request"
                  />
                )
              ),
              expandedRowRender: (record) => (
                <Attachments collectionRequest={record} />
              ),
              rowExpandable: () => true,
            }}
            footer={() => (
              <Row>
                <Col style={{ marginRight: 16 }}>
                  <Button
                    onClick={clearAllFilters}
                    size="small"
                    disabled={collectionRequests.length === 0}
                    className="btn btn-h-auto text text-w-bold px-3 py-2"
                  >
                    {t('text_button_clear_filters')}
                  </Button>
                </Col>
              </Row>
            )}
            summary={() => (
              <Table.Summary.Row>
                <Table.Summary.Cell colSpan={3}>
                  <Text className="text">
                    {t('text_total_values') + collectionRequests.length}
                  </Text>
                </Table.Summary.Cell>
              </Table.Summary.Row>
            )}
          />
        </Col>
      </Row>
    </Spin>
  );
}
