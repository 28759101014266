import { call, takeEvery, put } from 'redux-saga/effects';
import qs from 'qs';
import Cookies from 'js-cookie';
import i18n from '../assets/language/i18n';
import { actionLoading } from '../store/reducers/loading/loadingActions';
import {
  actionForgotPasswordResult,
  actionLoginResult,
  actionLoginByHashResult,
  actionResetPasswordResult
} from '../store/reducers/login/loginActions';

import { actionDecodedJWT } from '../store/reducers/home/homeActions';

import ConstantsLogin from '../store/reducers/login/loginConstants';
import AxiosInstance from './axiosConfig';
import ConstantsValue from '../constants/ConstantsValue';

function loginApi(values) {
  return AxiosInstance.post(
    '/login',
    qs.stringify({
      usuario: values.user,
      password: values.password,
    })
  )
    .then((response) => response)
    .catch((error) => error.response);
}

function* generateLogin(values) {
  try {
    const { data, status } = yield call(loginApi, values);
    if (
      status === 200
      && data !== undefined
      && data.JWT !== undefined
      && data.JWT.length > 0
    ) {
      Cookies.set(ConstantsValue.JWT, data.JWT, { expires: 7 });
      yield put(actionLoginResult(true, i18n.t('success_login')));
      yield put(actionDecodedJWT());
    } else if (data !== undefined && data.msg !== undefined) {
      console.log(data);

      yield put(actionLoginResult(false, data.msg));
    }
  } catch (error) {
    yield put(actionLoginResult(false, i18n.t('error_login')));
  } finally {
    yield put(actionLoading(false));
  }
}

function sendResetPasswordLinkApi(values) {
  return AxiosInstance.post(
    '/login/resetPassword',
    qs.stringify({
      email: values.email,
    })
  )
    .then((response) => response)
    .catch((error) => error.response);
}

function* generateSendResetPasswordLink(values) {
  try {
    const { data, status } = yield call(sendResetPasswordLinkApi, values);
    if (status === 200 && data.code === 0) {
      yield put(
        actionForgotPasswordResult(true, i18n.t('success_send_email_password'))
      );
    } else if (data !== undefined && data.msg !== undefined) {
      yield put(
        actionForgotPasswordResult(false, i18n.t('error_forgot_password'))
      );
    }
  } catch (error) {
    console.log(error);
    yield put(
      actionForgotPasswordResult(false, i18n.t('error_forgot_password'))
    );
  } finally {
    yield put(actionLoading(false));
  }
}

function loginByHashApi(values) {
  const { hash } = values;

  return AxiosInstance.get(
    `/login-by-token/${hash}`
  )
    .then((response) => response)
    .catch((error) => error);
}

function* generateLoginByHash(values) {
  try {
    const { status, data } = yield call(loginByHashApi, values);
    if (
      status === 200
    ) {
      yield put(actionLoginByHashResult(true, i18n.t('success_login')));
    } else if (data !== undefined && data.message !== undefined) {
      yield put(actionLoginByHashResult(false, data.message));
    }
  } catch (error) {
    yield put(actionLoginByHashResult(false, i18n.t('error_login')));
  } finally {
    yield put(actionLoading(false));
  }
}

function resetPasswordApi(values) {
  return AxiosInstance.post(
    '/new-password',
    qs.stringify({
      token: values.hash,
      newPassword: values.password,
    })
  )
    .then((response) => response)
    .catch((error) => error.response);
}

function* generateResetPassword(values) {
  try {
    const { data, status } = yield call(resetPasswordApi, values);
    if (status === 200 && data.code === 0) {
      yield put(
        actionResetPasswordResult(true, i18n.t('success_reset_password'))
      );
    } else if (data !== undefined && data.msg !== undefined) {
      yield put(
        actionResetPasswordResult(false, i18n.t('error_forgot_password'))
      );
    }
  } catch (error) {
    console.log(error);
    yield put(
      actionResetPasswordResult(false, i18n.t('error_forgot_password'))
    );
  } finally {
    yield put(actionLoading(false));
  }
}

export default function* loginSagas() {
  yield takeEvery(ConstantsLogin.LOGIN, generateLogin);
  yield takeEvery(ConstantsLogin.LOGIN_BY_HASH, generateLoginByHash);
  yield takeEvery(ConstantsLogin.RESET_PASSWORD, generateResetPassword);
  yield takeEvery(ConstantsLogin.FORGOT_PASSWORD, generateSendResetPasswordLink);
}
