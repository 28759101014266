import React, { useEffect, useState } from 'react';
import {
  BackTop,
  Button,
  Col,
  Input,
  message,
  Row,
  Space,
  Spin,
  Table,
  Tooltip,
  Typography
} from 'antd';
import {
  FileExcelOutlined,
  RightCircleOutlined,
  SearchOutlined,
  UpCircleOutlined,
} from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import * as XLSX from 'xlsx';
import 'antd/dist/antd.less';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import i18n from '../../../assets/language/i18n';
import FilterPayments from './FilterPayments';
import Colors from '../../../assets/colors/Colors';
import ConstantsReservationStates from '../../../constants/ConstantsReservationStates';
import ReservationPays from './ReservationPays';
import ConstantsDate from '../../../constants/ConstantsDate';
import {
  actionGetChargesReservation,
  actionSetExpandedKeyReservation
} from '../../../store/reducers/payments/paymentsActions';
import { getWritePermissionByTab } from '../../../utils/Utils';
import ConstantsRoutes from '../../../constants/ConstantsRoutes';

const { Text } = Typography;

const styles = {
  textValueGreenColumn: {
    fontSize: 14,
    fontFamily: 'Roboto-Bold',
    color: Colors.TEXT_GREEN,
  },
  textValueRedColumn: {
    fontSize: 14,
    fontFamily: 'Roboto-Bold',
    color: Colors.TEXT_RED,
  },
  textValueYellowColumn: {
    fontSize: 14,
    fontFamily: 'Roboto-Bold',
    color: Colors.TEXT_YELLOW,
  },
  textValueHighGreenColumn: {
    fontSize: 14,
    fontFamily: 'Roboto-Bold',
    color: Colors.TEXT_GREEN,
    backgroundColor: Colors.ICON_COLOR,
    padding: 0,
  },
  textValueHighRedColumn: {
    fontSize: 14,
    fontFamily: 'Roboto-Bold',
    color: Colors.TEXT_RED,
    backgroundColor: Colors.ICON_COLOR,
    padding: 0,
  },
  textValueHighYellowColumn: {
    fontSize: 14,
    fontFamily: 'Roboto-Bold',
    color: Colors.TEXT_YELLOW,
    backgroundColor: Colors.ICON_COLOR,
    padding: 0,
  },
  input: {
    borderRadius: 4,
    borderWidth: 1,
    borderColor: 'white',
    fontSize: 14,
    width: 188,
    marginBottom: 8,
    display: 'block',
  },
  buttonPrimary: {
    fontFamily: 'Roboto-Bold',
    fontWeight: '600',
    fontSize: 16,
    width: 90,
  },
  buttonDefault: {
    fontFamily: 'Roboto-Regular',
    fontSize: 14,
    width: 130,
  },
  icon: {
    color: Colors.PRIMARY_COLOR,
  },
  textOptionValue: {
    fontSize: 14,
    color: Colors.INPUT_COLOR,
    fontFamily: 'Roboto-Regular',
  },
};

export default function Payments() {
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [filteredInfo, setFilteredInfo] = useState(null);
  const [recordClick, setRecordClick] = useState(null);

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const reservations = useSelector((state) => state.reducerReservations);
  const applyFilters = useSelector((state) => state.reducerFilters);
  const payChargeReservationResult = useSelector(
    (state) => state.reducerPayChargeReservationResult
  );
  const expandedKeys = useSelector((state) => state.reducerExpandedKeysReservation);
  const loading = useSelector((state) => state.reducerLoading);

  useEffect(() => {
    if (reservations.msg !== null && reservations.result === false) {
      message.error(reservations.msg);
    }

    if (payChargeReservationResult.result === true) {
      dispatch(actionGetChargesReservation(recordClick.id_reservation, recordClick.reservation_id));
    }
  });

  const handleChange = (pagination, filters) => {
    setFilteredInfo(filters);
  };

  const clearAllFilters = () => {
    setFilteredInfo(null);
  };

  const getColumnSearchProps = (dataIndex, searchInput) => ({
    filterDropdown: ({
      setSelectedKeys, selectedKeys, confirm, clearFilters,
    }) => {
      let hintSearch;
      if (dataIndex === 'reservation_id') {
        hintSearch = t('hint_number_locator');
      } else if (dataIndex === 'localizador_agencia') {
        hintSearch = t('text_external_booking_locator');
      } else {
        hintSearch = t('text_headline');
      }
      return (
        <div style={{ padding: 8 }}>
          <Input
            ref={(node) => {
              searchInput = node;
            }}
            placeholder={hintSearch}
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={styles.input}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              style={styles.buttonPrimary}
            >
              {t('text_button_filter')}
            </Button>
            <Button onClick={() => handleReset(clearFilters)} size="small" style={styles.buttonDefault}>
              {t('text_button_clear')}
            </Button>
          </Space>
        </div>
      );
    },
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{ color: filtered ? Colors.PRIMARY_COLOR : Colors.ICON_COLOR }}
      />
    ),
    onFilter: (value, record) => (record[dataIndex] ? record[dataIndex]?.toString().toLowerCase().includes(value?.toLowerCase()) : ''),

    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.select());
      }
    },
    render: (text, record) => {
      if (dataIndex === 'reservation_id') {
        const recordStatus = record.status;
        let styleHigh;
        let styleNormal;
        if (recordStatus === ConstantsReservationStates.Book) {
          styleHigh = styles.textValueHighGreenColumn;
          styleNormal = styles.textValueGreenColumn;
        } else if (recordStatus === ConstantsReservationStates.Cancel) {
          styleHigh = styles.textValueHighRedColumn;
          styleNormal = styles.textValueRedColumn;
        } else {
          styleHigh = styles.textValueHighYellowColumn;
          styleNormal = styles.textValueYellowColumn;
        }
        if (searchedColumn === dataIndex) {
          return (
            <Highlighter
              highlightStyle={styleHigh}
              unhighlightStyle={styleNormal}
              searchWords={[searchText]}
              autoEscape
              textToHighlight={text ? text.toString() : ''}
            />
          );
        } return (
          <Text style={styleNormal}>
            {text}
          </Text>
        );
      }
      if (searchedColumn === dataIndex) {
        return (
          <Highlighter
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text ? text.toString() : ''}
          />
        );
      } return (
        <Text className="text">
          {text}
        </Text>
      );
    },
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const filtersSelected = filteredInfo || {};

  const channelFilters = [];
  const agencyFilters = [];
  const hotelFilters = [];
  const otaCodesFilters = [];
  const currencyFilters = [];
  reservations.forEach((reservation) => {
    if (reservation.channel !== null && reservation.channel.length > 0) {
      if (!channelFilters.some((channel) => channel.value === reservation.channel)) {
        channelFilters.push({
          text: reservation.channel,
          value: reservation.channel,
        });
      }
    }
    if (reservation.cliente !== null && reservation.cliente.length > 0) {
      if (!agencyFilters.some(
        (agency) => agency.value === reservation.cliente,
      )) {
        agencyFilters.push({
          text: reservation.cliente,
          value: reservation.cliente,
        });
      }
    }
    if (reservation.hotel !== null && reservation.hotel.length > 0 && reservation.hotel_chain) {
      if (!hotelFilters.some(
        (hotel) => hotel.value === reservation.hotel,
      )) {
        hotelFilters.push({
          text: reservation.hotel_chain,
          value: reservation.hotel,
        });
      }
    }
    if (reservation.codigo_ota !== null && reservation.codigo_ota.length > 0) {
      if (!otaCodesFilters.some(
        (otaCode) => otaCode.value === reservation.codigo_ota,
      )) {
        otaCodesFilters.push({
          text: reservation.tipo_ota ?? reservation.codigo_ota,
          value: reservation.codigo_ota,
        });
      }
    }
    if (reservation.currency !== null && reservation.currency.length > 0) {
      if (!currencyFilters.some((currency) => currency.value === reservation.currency
        && reservation.currency?.length > 0)) {
        currencyFilters.push({
          text: applyFilters.currencies?.filter(
            (i) => i.currency === reservation?.currency
          )[0]?.label,
          value: reservation.currency,
        });
      }
    }
  });

  channelFilters.sort((a, b) => a.text.localeCompare(b.text, i18n.language));
  agencyFilters.sort((a, b) => a.text.localeCompare(b.text, i18n.language));
  hotelFilters.sort((a, b) => a.text.localeCompare(b.text, i18n.language));
  otaCodesFilters.sort((a, b) => a.text.localeCompare(b.text, i18n.language));

  const columns = [
    {
      title: (
        <Text className="text">
          {t('text_booking_locator')}
        </Text>),
      dataIndex: 'reservation_id',
      key: 'reservation_id',
      ...getColumnSearchProps('reservation_id'),
      filteredValue: filtersSelected?.reservation_id || null
    },
    {
      title: (
        <Text className="text">
          {t('text_external_booking_locator')}
        </Text>),
      dataIndex: 'localizador_agencia',
      key: 'localizador_agencia',
      ...getColumnSearchProps('localizador_agencia'),
      filteredValue: filtersSelected?.reservation_id || null
    },
    {
      title: (
        <Text className="text">
          {t('text_channel')}
        </Text>),
      dataIndex: 'channel',
      key: 'channel',
      sorter: {
        compare: (a, b) => a.channel?.localeCompare(b.channel, i18n.language),
        multiple: 1,
      },
      filteredValue: filtersSelected?.channel || null,
      filters: channelFilters,
      onFilter: (value, record) => record.channel?.indexOf(value) === 0,
      responsive: ['md'],
      render: (text) => (
        <Tooltip title={applyFilters.channels.find((item) => item.channel === text)?.label}>
          <Text className="text">
            {text}
          </Text>
        </Tooltip>
      ),
    },
    {
      title: (
        <Text className="text">
          {t('text_client')}
        </Text>),
      dataIndex: 'cliente',
      key: 'cliente',
      sorter: {
        compare: (a, b) => a.cliente?.localeCompare(b.cliente, i18n.language),
        multiple: 2,
      },
      filteredValue: filtersSelected?.cliente || null,
      filters: agencyFilters,
      onFilter: (value, record) => record.cliente?.indexOf(value) === 0,
      responsive: ['md'],
      render: (text) => (
        <Tooltip title={applyFilters.clients.find((item) => item.cliente === text)?.label}>
          <Text className="text">
            {text === null ? '' : text}
          </Text>
        </Tooltip>
      ),
    },
    {
      title: (
        <Text className="text">
          {t('text_hotel')}
        </Text>),
      dataIndex: 'hotel_chain',
      key: 'hotel_chain',
      sorter: {
        compare: (a, b) => a.hotel_chain?.localeCompare(b.hotel_chain, i18n.language),
        multiple: 3,
      },
      filteredValue: filtersSelected?.hotel_chain || null,
      filters: hotelFilters,
      onFilter: (value, record) => record.hotel?.indexOf(value) === 0,
      responsive: ['md'],
      render: (text) => (
        <Text className="text">
          {text}
        </Text>
      ),
    },
    {
      title: (
        <Text className="text">
          {t('text_create')}
        </Text>),
      dataIndex: 'fecha_creacion',
      key: 'fecha_creacion',
      sorter: {
        compare: (a, b) => a.fecha_creacion - b.fecha_creacion,
        multiple: 4,
      },
      align: 'center',
      responsive: ['md'],
      render: (text) => (
        <Text className="text">
          {text.length === 0 ? '' : format(text, ConstantsDate.DATE_FORMAT, {
            locale: es,
          })}
        </Text>
      ),
    },
    {
      title: (
        <Text className="text">
          {t('text_in')}
        </Text>),
      dataIndex: 'arrival',
      key: 'arrival',
      sorter: {
        compare: (a, b) => a.arrival - b.arrival,
        multiple: 7,
      },
      align: 'center',
      responsive: ['md'],
      render: (text) => (
        <Text className="text">
          {text.length === 0 ? '' : format(text, ConstantsDate.DATE_FORMAT, {
            locale: es,
          })}
        </Text>
      ),
    },
    {
      title: (
        <Text className="text">
          {t('text_out')}
        </Text>),
      dataIndex: 'checkout',
      key: 'checkout',
      sorter: {
        compare: (a, b) => a.checkout - b.checkout,
        multiple: 8,
      },
      align: 'center',
      responsive: ['md'],
      render: (text) => (
        <Text className="text">
          {text.length === 0 ? '' : format(text, ConstantsDate.DATE_FORMAT, {
            locale: es,
          })}
        </Text>
      ),
    },
    {
      title: (
        <Text className="text">
          {t('text_headline')}
        </Text>),
      dataIndex: 'usuario',
      key: 'usuario',
      responsive: ['md'],
      sorter: {
        compare: (a, b) => a.usuario?.localeCompare(b.usuario, i18n.language),
        multiple: 9,
      },
      filterSearch: true,
      ...getColumnSearchProps('usuario'),
      filteredValue: filtersSelected?.usuario || null
    },
    {
      title: (
        <Text className="text">
          {t('text_ota_code')}
        </Text>),
      dataIndex: 'tipo_ota',
      key: 'tipo_ota',
      sorter: {
        compare: (a, b) => a.tipo_ota?.localeCompare(b.tipo_ota, i18n.language),
        multiple: 10,
      },
      filteredValue: filtersSelected?.tipo_ota || null,
      filters: otaCodesFilters,
      onFilter: (value, record) => record.codigo_ota?.indexOf(value) === 0,
      responsive: ['md'],
      render: (text) => (
        <Tooltip title={applyFilters.payForms.find((item) => item.tipo === text)?.label}>
          <Text className="text">
            {text}
          </Text>
        </Tooltip>
      ),
    },
    {
      title: (
        <Text className="text">
          {t('text_currency')}
        </Text>),
      dataIndex: 'currency',
      key: 'currency',
      filteredValue: filtersSelected?.currency || null,
      filters: currencyFilters,
      onFilter: (value, record) => record.currency?.indexOf(value) === 0,
      responsive: ['md'],
      render: (text) => (
        <Text className="text">
          {text}
        </Text>
      ),
    },
    {
      title: (
        <Text className="text">
          {t('text_pay_total')}
        </Text>),
      dataIndex: 'gross',
      key: 'gross',
      sorter: {
        compare: (a, b) => (Number(a.gross) ?? 0) - (Number(b.gross) ?? 0),
        multiple: 13,
      },
      responsive: ['md'],
      render: (text) => (
        <Text className="text">
          {text === 0 ? '0.00' : text}
        </Text>
      ),
    },
    /* {
      title: (
        <Text className="text">
          {t('text_pay_out')}
        </Text>),
      dataIndex: 'total_pagado',
      key: 'total_pagado',
      sorter: {
        compare: (a, b) => (Number(a.total_pagado) ?? 0) - (Number(b.total_pagado) ?? 0),
        multiple: 11,
      },
      responsive: ['md'],
      render: (text) => (
        <Text className="text">
          {text === 0 ? '0.00' : text}
        </Text>
      ),
    },
    {
      title: (
        <Text className="text">
          {t('text_pending_p')}
        </Text>),
      dataIndex: 'pendiente_pago',
      key: 'pendiente_pago',
      sorter: {
        compare: (a, b) => (Number(a.pendiente_pago) ?? 0) - (Number(b.pendiente_pago) ?? 0),
        multiple: 12,
      },
      responsive: ['md'],
      render: (text) => (
        <Text className="text">
          {text === 0 ? '0.00' : text}
        </Text>
      ),
    }, */
  ];

  const headers = [
    [t('text_booking_locator'),
      t('text_external_booking_locator'),
      t('text_status'),
      t('text_crs'),
      t('text_channel'),
      t('text_client'),
      t('text_chain'),
      t('text_hotel'),
      t('text_create'),
      t('text_in'),
      t('text_out'),
      t('text_headline'),
      t('text_ota_code'),
      t('text_currency'),
      t('text_pay_out'),
      t('text_pending_p'),
      t('text_pay_total'),
      t('text_charges'),
      t('text_refund'),
      t('text_pay_state'),
      t('text_concept')
    ]
  ];

  const exportToCsvReservations = () => {
    if (!reservations) {
      return [];
    }
    return reservations.map((item) => {
      const resultItem = {};
      resultItem.reservation_id = item.reservation_id;
      resultItem.localizador_agencia = item.localizador_agencia;
      resultItem.status = item.status;
      resultItem.crs = item.crs;
      resultItem.channel = item.channel;
      resultItem.cliente = item.cliente;
      resultItem.chain = item.cadena;
      resultItem.hotel_chain = item.hotel_chain;
      resultItem.fecha_creacion_string = item.fecha_creacion_string;
      resultItem.arrival_string = item.arrival_string;
      resultItem.checkout_string = item.checkout_string;
      resultItem.usuario = item.usuario;
      resultItem.codigo_ota = item.codigo_ota;
      resultItem.currency = item.currency;
      resultItem.total_pagado = Number(item.total_pagado) ?? Number(0.00);
      resultItem.pendiente_pago = Number(item.pendiente_pago) ?? Number(0.00);
      resultItem.gross = Number(item.gross) ?? Number(0.00);
      resultItem.charges = item.transacciones?.filter((itemIt) => itemIt.action === 'Charge' && itemIt.transaccion).map((itemIt) => itemIt.transaccion).join('\n');
      resultItem.refunds = item.transacciones?.filter((itemIt) => (itemIt.action === 'Refund' && itemIt.transaccion)).map((itemIt) => itemIt.transaccion).join('\n');
      resultItem.payState = item.estado_cobro;
      resultItem.concept = item.concepto;
      return resultItem;
    });
  };

  const csvArrayReservation = exportToCsvReservations();

  return (
    <Spin spinning={loading} size="large">
      <BackTop />
      <Row justify="end">
        <Col span={24}>
          <FilterPayments />
          <Row align="middle" justify="end">
            <Col style={{ marginRight: 16 }}>
              <Button
                onClick={clearAllFilters}
                size="small"
                disabled={reservations.length === 0}
                className="btn btn-h-auto text text-w-bold px-3 py-2"
              >
                {t('text_button_clear_filters')}
              </Button>
            </Col>
            <Col style={{ marginRight: 16 }}>
              <Button
                type="primary"
                icon={<FileExcelOutlined />}
                size="small"
                disabled={reservations.length === 0}
                className="btn btn-h-auto text text-w-bold px-3 py-2"
                onClick={() => {
                  const wb = XLSX.utils.book_new();
                  const ws = XLSX.utils.json_to_sheet([]);
                  XLSX.utils.sheet_add_aoa(ws, headers);
                  XLSX.utils.sheet_add_json(ws, csvArrayReservation, { origin: 'A2', skipHeader: true });
                  XLSX.utils.book_append_sheet(wb, ws, 'Reservas');
                  XLSX.writeFile(wb, 'reservas.xlsx');
                }}
              >
                {t('text_button_export')}
              </Button>
            </Col>
          </Row>
          <Table
            rowKey="id_reservation"
            style={{ width: '100%' }}
            pagination={{ total: reservations.length, pageSizeOptions: ['10', '20', '50', '100', '200', '300', '500'] }}
            size="small"
            loading={loading}
            columns={columns}
            dataSource={reservations}
            onChange={handleChange}
            expandable={{
              expandRowByClick: true,
              rowExpandable: () => getWritePermissionByTab(ConstantsRoutes.PAYMENTS),
              expandIcon: ({ expanded, onExpand, record }) => (expanded ? (
                <UpCircleOutlined onClick={(e) => onExpand(record, e)} style={styles.icon} />
              ) : (
                <RightCircleOutlined
                  onClick={(e) => {
                    onExpand(record, e);
                  }}
                  style={styles.icon}
                />
              )),
              expandedRowRender: (record) => <ReservationPays reservation={record} />,
              onExpand: (expanded, record) => {
                dispatch(actionSetExpandedKeyReservation(record.id_reservation, expanded));
                setRecordClick(record);
                dispatch(
                  actionGetChargesReservation(record.id_reservation, record.reservation_id)
                );
              },
              expandedRowKeys: expandedKeys
            }}
            footer={() => (
              <Row>
                <Col style={{ marginRight: 16 }}>
                  <Button
                    onClick={clearAllFilters}
                    size="small"
                    disabled={reservations.length === 0}
                    className="btn btn-h-auto text text-w-bold px-3 py-2"
                  >
                    {t('text_button_clear_filters')}
                  </Button>
                </Col>
                <Col>
                  <Button
                    type="primary"
                    icon={<FileExcelOutlined />}
                    size="small"
                    disabled={reservations.length === 0}
                    className="btn btn-h-auto text text-w-bold px-3 py-2"
                    onClick={() => {
                      const wb = XLSX.utils.book_new();
                      const ws = XLSX.utils.json_to_sheet([]);
                      XLSX.utils.sheet_add_aoa(ws, headers);
                      XLSX.utils.sheet_add_json(ws, csvArrayReservation, { origin: 'A2', skipHeader: true });
                      XLSX.utils.book_append_sheet(wb, ws, 'Reservas');
                      XLSX.writeFile(wb, 'reservas.xlsx');
                    }}
                  >
                    {t('text_button_export')}
                  </Button>
                </Col>
              </Row>
            )}
            summary={() => (
              <Table.Summary.Row>
                <Table.Summary.Cell colSpan={3}>
                  <Text className="text">
                    {t('text_total_values') + reservations.length}
                  </Text>
                </Table.Summary.Cell>
              </Table.Summary.Row>
            )}
          />
        </Col>
      </Row>
    </Spin>
  );
}
