import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import {
  message,
  Button,
  Form,
  Input,
  InputNumber,
  Modal,
  Select,
  Typography,
  Row, Col, Radio, Space
} from 'antd';
import {
  CloseCircleOutlined,
  EuroCircleOutlined,
  UserOutlined,
  PlusOutlined,
  MinusCircleOutlined
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import '../styles/styles.css';
import {useCreateNewManualLineApiMutation } from '../../../../services/conciliationLines';
import { defaultRequiredRules} from '../../../../utils/Utils';

const { Text } = Typography;
const { TextArea } = Input;
const { Option } = Select;

const DEVOLUTION_OPERATION_TYPE = 'devolucion';
const CHARGE_OPERATION_TYPE = 'cargo';

export default function NewManualLinesModal({
  conciliation, handleCancel, handleOk, hotel, jobId
}) {
  const { t } = useTranslation();

  const applyFilters = useSelector((state) => state.reducerFiltersPreConciliation);
  console.log("filtros aplicados", applyFilters);
  const hotelOptions = [];
  const hotels = [];
  const [form] = Form.useForm();

  const [
    createManualLine,
    {
      data: createManualLineResult,
      isLoading: isLoadingCreateManualLine,
      error: errorCreateManualLine,
    }
  ] = useCreateNewManualLineApiMutation();

  const onFinishForm = async (values) => {
    const operationTypeMultiplier = values?.operationType === DEVOLUTION_OPERATION_TYPE ? 1 : -1;

    const promises = [];
    promises.push(createManualLine({
      idJob: jobId,
      idHotel: values?.hotelCode,
      concept: values?.typeOfConcept,
      reservation: values?.localizator,
      nameHotel: hotels?.find((i) => i.value === form.getFieldValue('hotelCode')).text,
      paymentAmount: values?.paymentAmount,
      conceptoType: values?.conceptoType,
      type: values?.operationType,
      observation: values?.observation,
    }));
    const result = await Promise.all(promises);
    console.log(result);
  };

  useEffect(() => {
    if (errorCreateManualLine || createManualLineResult?.code === 1) {
      message.error(errorCreateManualLine?.message ?? createManualLineResult?.message);
    } else if (createManualLineResult) {
      message.success(createManualLineResult?.message);
      handleOk();
    }
  }, [createManualLineResult, errorCreateManualLine]);

  const onOk = () => {
    form.submit();
  };

  const operationTypes = [
    { label: 'Devolución al cliente', value: DEVOLUTION_OPERATION_TYPE },
    { label: 'Cargo al cliente', value: CHARGE_OPERATION_TYPE },
  ];

  const conceptoTypes = [
    { label: 'Coste Financiero', value: "Coste financiero" },
    { label: 'Coste Tecnológico', value: "Coste Tecnológico" },
    { label: 'Coste Marketing', value: "Coste Marketing" },
  ];

  conciliation?.lineas?.forEach((i) => {
    if (i.hotel !== null && i.hotel.length > 0) {
      if (!hotels.some(
        (item) => item.value === i.id_hotel,
      )) {
        hotelOptions.push(
          <Option key={i.id_hotel} value={i.id_hotel}>
            <Text className="text-option-value">
              {i.hotel}
            </Text>
          </Option>
        );
        hotels.push({
          text: i.hotel,
          value: i.id_hotel,
        });
      }
    }
  });

  return (
    <Modal
      title={(
        <Text className="text-title-popconfirm">
          {t('text_add_manual_lines')}
        </Text>
      )}
      open
      onCancel={handleCancel}
      closeIcon={<CloseCircleOutlined className="icon" />}
      onOk={onOk}
      okText={t('text_add_manual_lines')}
      confirmLoading={isLoadingCreateManualLine}
    >
      <Form
        name="manual_line"
        initialValues={{
          remember: true,
          size: 'middle',
          hotelCode: hotel,
          operationType: 'devolucion',
        }}
        form={form}
        onFinish={onFinishForm}

      >
        <Form.Item
          name="hotelCode"
          rules={defaultRequiredRules(t('error_empty_field'))}
        >
          <Select
            disabled={hotel !== null}
            className="select-modal"
            placeholder={t('hint_hotel_name')}
          >
            {hotelOptions}
          </Select>
        </Form.Item>
        <Form.Item
          name="operationType"
          rules={defaultRequiredRules(t('error_empty_field'))}
        >
          <Select
            className="select-modal"
            allowClear
            placeholder={t('operation_type')}
            optionFilterProp="children"
            fieldNames={{ label: 'label', value: 'value' }}
            options={operationTypes}
          />
        </Form.Item>
        <div style={{ fontWeight: 'bold', fontSize: '16px', marginBottom: '8px' }}>
          Seleccione un concepto:
        </div>
        <Form.Item
          name="conceptoType"
          rules={defaultRequiredRules(t('error_empty_field'))}
        >
          <Radio.Group>
            <Space direction="vertical">
              {conceptoTypes.map((type) => (
                <Radio key={type.value} value={type.value}>
                  {type.label}
                </Radio>
              ))}
            </Space>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          name="localizator"
          rules={defaultRequiredRules(t('error_empty_field'))}
        >
          <Input
            prefix={<UserOutlined className="icon" />}
            placeholder={t('text_booking_locator')}
            className="input-modal"
          />
        </Form.Item>
        <Form.Item
          name="typeOfConcept"
          rules={defaultRequiredRules(t('error_empty_field'))}
        >
          <Select
            className="select-modal"
            allowClear
            placeholder={t('operation_type_lm')}
            optionFilterProp="children"
            fieldNames={{ label: 'label', value: 'value' }}
            options={applyFilters.concepts_lm}
          />
        </Form.Item>
        <Form.Item
          name="paymentAmount"
          rules={defaultRequiredRules(t('error_empty_field'))}
        >
          <InputNumber
            prefix={<EuroCircleOutlined className="icon" />}
            decimalSeparator=","
            placeholder={t('text_abono_lm')}
            controls={false}
            className="input-modal"
          />
        </Form.Item>
        <Form.Item
          name="observation"
          rules={defaultRequiredRules(t('error_empty_field'))}
        >
          <TextArea
            showCount
            allowClear
            placeholder={t('text_observation')}
            rows={3}
            maxLength={130}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
}

NewManualLinesModal.defaultProps = {
  conciliation: null,
  handleOk: undefined,
  handleCancel: undefined,
  hotel: null,
};

NewManualLinesModal.propTypes = {
  conciliation: PropTypes.shape({
    lineas: PropTypes.arrayOf(PropTypes.shape({
      id_hotel: PropTypes.number,
      hotel: PropTypes.string || PropTypes.number,
    })),
    conceptos: PropTypes.arrayOf(PropTypes.shape({
    })),
  }),
  handleOk: PropTypes.func,
  handleCancel: PropTypes.func,
  hotel: PropTypes.string || PropTypes.number,
  jobId: PropTypes.number.isRequired,
};
