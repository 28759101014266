import React, { useState } from 'react';
import {
  Button, Col, Collapse, DatePicker, Form, Input, message, Row, Select, Typography,
} from 'antd';
import 'antd/dist/antd.less';
import {
  SearchOutlined,
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import Colors from '../../../assets/colors/Colors';
import ConstantsDate from '../../../constants/ConstantsDate';

import {useGetFiltersInvitationsApiQuery, setAppliedFilters } from '../../../services/invitations';

const { Text } = Typography;
const { Panel } = Collapse;
const { Option } = Select;
const { RangePicker } = DatePicker;

const formRef = React.createRef();

const styles = {
  input: {
    borderRadius: 4,
    borderWidth: 1,
    fontSize: 14,
    paddingTop: 6,
    width: '100%',
  },
  buttonPrimary: {
    fontFamily: 'Roboto-Bold',
    fontWeight: '600',
    fontSize: 16,
    width: '100%',
    marginLeft: 8,
  },
  buttonDefault: {
    fontFamily: 'Roboto-Regular',
    fontSize: 14,
    width: '100%',
  },
  textTitle: {
    fontSize: 14,
    color: Colors.TEXT_COLOR,
    fontWeight: '500',
    verticalAlign: 'middle',
  },
  rowFilter: {
    width: '100%',
    padding: 16,
    backgroundColor: Colors.BACKGROUND_COLOR_2,
  },
  textTitleDescription: {
    fontSize: 14,
    color: Colors.INPUT_COLOR,
  },
  icon: {
    color: Colors.ICON_COLOR,
  },
  select: {
    fontSize: 14,
    borderRadius: 4,
    borderWidth: 1,
    borderColor: 'white',
  },
  textOptionValue: {
    fontSize: 14,
    color: Colors.INPUT_COLOR,
  },
};

function getOptionsForSelect(list, className, value = 'value', label = 'label') {
  return list?.map((i) => (
    <Option key={`${i[value]}`} value={i[value]}>
      <Text className={className}>
        {i[label]}
      </Text>
    </Option>
  ));
}

export default function FilterInvitations() {
  const [dateInitSend, setDateInitSend] = useState('');
  const [dateEndSend, setDateEndSend] = useState('');
  const [dateInitTransaction, setDateInitTransaction] = useState('');
  const [dateEndTransaction, setDateEndTransaction] = useState('');
  const [currency, setCurrency] = useState('');
  const [statePay, setStatePay] = useState('');
  const [idTransaction, setIdTransaction] = useState('');
  const [hotel, setHotel] = useState('');
  const [idReservation, setIdReservation] = useState('');
  const [internalReference, setInternalReference] = useState('');

  const [dateInitSendStart, setDateInitSendStart] = useState('');
  const [dateInitSendEnd, setDateInitSendEnd] = useState('');

  const [dateEndSendStart, setDateEndSendStart] = useState('');
  const [dateEndSendEnd, setDateEndSendEnd] = useState('');
  const [chain, setChain] = useState('');

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const jwtData = useSelector(
    (state) => state.reducerJwtData
  );

  const {
    data: filters, error
  } = useGetFiltersInvitationsApiQuery();

  if (error) {
    message.error(error.message);
  }
  const isCubatourProfile = jwtData?.userInfo?.permisos?.tipo_agente === 'ctt';

  const clickClearFilters = () => {
    setDateInitSend('');
    setDateEndSend('');
    setDateInitTransaction('');
    setDateEndTransaction('');
    setCurrency('');
    setStatePay('');
    setIdTransaction('');
    setHotel('');
    setIdReservation('');
    setInternalReference('');
    setDateInitSendStart('');
    setDateInitSendEnd('');
    setDateEndSendStart('');
    setDateEndSendEnd('');
    formRef.current.resetFields();
  };

  function clickFilterButton() {
    dispatch(setAppliedFilters({
      idTransaction,
      statePay,
      hotel,
      currency,
      chain,
      dateInitSend: dateInitSend.length === 0 ? '' : format(dateInitSend.toDate(), ConstantsDate.DATE_FORMAT_REQUEST, {
        locale: es,
      }),
      dateEndSend: dateEndSend.length === 0 ? '' : format(dateEndSend.toDate(), ConstantsDate.DATE_FORMAT_REQUEST, {
        locale: es,
      }),
      dateInitTransaction: dateInitTransaction.length === 0 ? '' : format(dateInitTransaction.toDate(), ConstantsDate.DATE_FORMAT_REQUEST, {
        locale: es,
      }),
      dateEndTransaction: dateEndTransaction.length === 0 ? '' : format(dateEndTransaction.toDate(), ConstantsDate.DATE_FORMAT_REQUEST, {
        locale: es,
      }),
      idReservation,
      internalReference,
      dateInitSendStart: dateInitSendStart.length === 0 ? '' : format(dateInitSendStart.toDate(), ConstantsDate.DATE_FORMAT_REQUEST, {
        locale: es,
      }),
      dateInitSendEnd: dateInitSendEnd.length === 0 ? '' : format(dateInitSendEnd.toDate(), ConstantsDate.DATE_FORMAT_REQUEST, {
        locale: es,
      }),
      dateEndSendStart: dateEndSendStart.length === 0 ? '' : format(dateEndSendStart.toDate(), ConstantsDate.DATE_FORMAT_REQUEST, {
        locale: es,
      }),
      dateEndSendEnd: dateEndSendEnd.length === 0 ? '' : format(dateEndSendEnd.toDate(), ConstantsDate.DATE_FORMAT_REQUEST, {
        locale: es,
      }),
    }));
  }

  const statesPayOptions = getOptionsForSelect(filters?.payStates, 'text-option-value', 'estado', 'label');
  const chainsOptions = getOptionsForSelect(filters?.chains, 'text-option-value', 'id_cadena', 'nombre_cadena');
  const currencyOptions = getOptionsForSelect(filters?.currencies, 'text-option-value', 'currency', 'label');
  const hotelsOptions = getOptionsForSelect(filters?.hotels.filter((item) => !chain || item.id_cadena === chain), 'text-option-value');

  const isDisabledSubmitButton = () => dateInitSend?.length === 0
                          && dateEndSend?.length === 0
                          && dateInitTransaction?.length === 0
                          && dateEndTransaction?.length === 0
                          && (currency === undefined || currency?.length === 0)
                          && (statePay === undefined || statePay?.length === 0)
                          && idTransaction?.length === 0
                          && (hotel === undefined || hotel?.length === 0)
                          && (chain === undefined || chain?.length === 0)
                          && idReservation?.length === 0
                          && internalReference?.length === 0
                          && dateInitSendStart?.length === 0
                          && dateInitSendEnd?.length === 0
                          && dateEndSendStart?.length === 0
                          && dateEndSendEnd?.length === 0;

  return (
    <Row align="middle" style={styles.rowFilter}>
      <Collapse
        accordion
        defaultActiveKey={['1']}
        style={{ width: '100%' }}
        expandIconPosition="end"
      >
        <Panel
          key="1"
          header={(
            <Text style={styles.textTitle}>
              {t('text_search_filters')}
            </Text>
          )}
        >
          <Form
            name="advanced_search"
            className="ant-advanced-search-form"
            labelCol={{
              span: 10,
            }}
            ref={formRef}
          >
            <Row gutter={24}>
              <Col xs={24} md={8}>
                <Form.Item
                  name="internal_reference"
                  label={(
                    <Text style={styles.textTitleDescription}>
                      {t('text_locator')}
                    </Text>
                  )}
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                >
                  <Input
                    suffix={<SearchOutlined style={styles.icon} />}
                    placeholder={t('text_locator')}
                    onChange={(text) => {
                      setInternalReference(text.target.value);
                    }}
                    value={internalReference}
                    style={styles.input}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={8}>
                <Form.Item
                  name="chain"
                  label={(
                    <Text style={styles.textTitleDescription}>
                      {t('text_chain')}
                    </Text>
                  )}
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                >
                  <Select
                    showSearch
                    allowClear
                    defaultActiveFirstOption={false}
                    style={styles.select}
                    placeholder={t('text_chain')}
                    optionFilterProp="children"
                    onChange={(value) => {
                      setChain(value);
                    }}
                    filterOption={
                      (
                        input,
                        option
                      ) => option.children.props.children.toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    value={chain}
                  >
                    {chainsOptions}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} md={8}>
                <Form.Item
                  name="dateSend"
                  label={(
                    <Text style={styles.textTitleDescription}>
                      {t('text_date_send')}
                    </Text>
                  )}
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                >
                  <RangePicker
                    style={styles.input}
                    format={ConstantsDate.DATE_FORMAT_PICKER}
                    onChange={(dates) => {
                      setDateInitSend(dates === null ? '' : dates[0]);
                      setDateEndSend(dates === null ? '' : dates[1]);
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col xs={24} md={8}>
                <Form.Item
                  name="idReservation"
                  label={(
                    <Text style={styles.textTitleDescription}>
                      {t('text_external_booking_locator')}
                    </Text>
                  )}
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                >
                  <Input
                    suffix={<SearchOutlined style={styles.icon} />}
                    placeholder={t('text_external_booking_locator')}
                    onChange={(text) => {
                      setIdReservation(text.target.value);
                    }}
                    value={idReservation}
                    style={styles.input}
                  />
                </Form.Item>
              </Col>

              <Col xs={24} md={8}>
                <Form.Item
                  name="nameHotel"
                  label={(
                    <Text style={styles.textTitleDescription}>
                      {isCubatourProfile ? t('text_office') : t('text_hotel')}
                    </Text>
                  )}
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                >
                  <Select
                    showSearch
                    allowClear
                    defaultActiveFirstOption={false}
                    style={styles.select}
                    placeholder={isCubatourProfile ? t('text_office') : t('hint_hotel_name')}
                    optionFilterProp="children"
                    onChange={(value) => {
                      setHotel(value);
                    }}
                    filterOption={
                      (
                        input,
                        option
                      ) => option.children.props.children.toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    value={hotel}
                  >
                    {hotelsOptions}
                  </Select>
                </Form.Item>
              </Col>

              <Col xs={24} md={8}>
                <Form.Item
                  name="dateTransaction"
                  label={(
                    <Text style={styles.textTitleDescription}>
                      {t('text_date_transaction')}
                    </Text>
                  )}
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                >
                  <RangePicker
                    style={styles.input}
                    format={ConstantsDate.DATE_FORMAT_PICKER}
                    onChange={(dates) => {
                      setDateInitTransaction(dates === null ? '' : dates[0]);
                      setDateEndTransaction(dates === null ? '' : dates[1]);
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col xs={24} md={8}>
                <Form.Item
                  name="idTransaction"
                  label={(
                    <Text style={styles.textTitleDescription}>
                      {t('text_id_transaction')}
                    </Text>
                  )}
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                >
                  <Input
                    suffix={<SearchOutlined style={styles.icon} />}
                    placeholder={t('text_id_transaction')}
                    onChange={(text) => {
                      setIdTransaction(text.target.value);
                    }}
                    value={idTransaction}
                    style={styles.input}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={8}>
                <Form.Item
                  name="stateReservation"
                  label={(
                    <Text style={styles.textTitleDescription}>
                      {t('text_status')}
                    </Text>
                  )}
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                >
                  <Select
                    showSearch
                    style={styles.select}
                    allowClear
                    placeholder={t('text_status')}
                    optionFilterProp="children"
                    onChange={(value) => {
                      setStatePay(value);
                    }}
                    filterOption={
                      (
                        input,
                        option
                      ) => option.children.props.children.toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    value={statePay}
                  >
                    {statesPayOptions}
                  </Select>
                </Form.Item>
              </Col>

              <Col xs={24} md={8}>
                <Form.Item
                  name="currency"
                  label={(
                    <Text style={styles.textTitleDescription}>
                      {t('text_currency')}
                    </Text>
                  )}
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                >
                  <Select
                    showSearch
                    style={styles.select}
                    allowClear
                    placeholder={t('text_currency')}
                    optionFilterProp="children"
                    onChange={(value) => {
                      setCurrency(value);
                    }}
                    filterOption={
                      (
                        input,
                        option
                      ) => option.children.props.children.toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    value={currency}
                  >
                    {currencyOptions}
                  </Select>
                </Form.Item>
              </Col>
              {(isCubatourProfile) ? (
                <Col xs={24} md={8}>
                  <Form.Item
                    name="dateInitSend"
                    label={(
                      <Text style={styles.textTitleDescription}>
                        {t('text_init_service_date')}
                      </Text>
                  )}
                    rules={[
                      {
                        required: false,
                      },
                    ]}
                  >
                    <RangePicker
                      style={styles.input}
                      format={ConstantsDate.DATE_FORMAT_PICKER}
                      onChange={(dates) => {
                        setDateInitSendStart(dates === null ? '' : dates[0]);
                        setDateInitSendEnd(dates === null ? '' : dates[1]);
                      }}
                    />
                  </Form.Item>
                </Col>
              ) : ''}
            </Row>
            {isCubatourProfile ? (
              <Row gutter={24}>
                <Col offset={16} span={8}>
                  <Form.Item
                    name="dateEndSend"
                    label={(
                      <Text style={styles.textTitleDescription}>
                        {t('text_end_service_date')}
                      </Text>
                  )}
                    rules={[
                      {
                        required: false,
                      },
                    ]}
                  >
                    <RangePicker
                      style={styles.input}
                      format={ConstantsDate.DATE_FORMAT_PICKER}
                      onChange={(dates) => {
                        setDateEndSendStart(dates === null ? '' : dates[0]);
                        setDateEndSendEnd(dates === null ? '' : dates[1]);
                      }}
                    />
                  </Form.Item>
                </Col>

              </Row>
            ) : ''}

            <Row gutter={24}>
              <Col offset={8} span={16}>
                <Form.Item
                  name="buttons"
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                >
                  <Row justify="end">
                    <Col>
                      <Button
                        htmlType="submit"
                        onClick={clickClearFilters}
                        style={styles.buttonDefault}
                      >
                        {t('text_button_clear')}
                      </Button>
                    </Col>
                    <Col>
                      <Button
                        type="primary"
                        htmlType="submit"
                        onClick={() => {
                          clickFilterButton();
                        }}
                        disabled={
                          isDisabledSubmitButton()
                        }
                        style={styles.buttonPrimary}
                      >
                        {t('text_button_filter')}
                      </Button>
                    </Col>
                  </Row>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Panel>
      </Collapse>
    </Row>
  );
}
