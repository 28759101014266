import React, { useEffect } from 'react';
import {
  Col, Drawer, message, Row, Table, Typography
} from 'antd';

import 'antd/dist/antd.less';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  actionGetCommentPreConciliationResult
} from '../../../store/reducers/preConciliation/preConciliationActions';

const { Text } = Typography;

export default function CommentsPreConciliation() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const comments = useSelector((state) => state.reducerPreConciliationCommentResult);

  useEffect(() => {
    if (comments?.comments?.length === 0) {
      message.error(t('error_no_comments'));
      dispatch(actionGetCommentPreConciliationResult(false, null, null));
    }
  });

  const columns = [
    {
      title: (
        <Text className="text">
          {t('hint_user')}
        </Text>),
      dataIndex: 'nombre_usuario',
      key: 'nombre_usuario',
      width: 180,
      responsive: ['md'],
      render: (text) => (
        <Text className="text">
          {text}
        </Text>
      ),
    },
    {
      title: (
        <Text className="text">
          {t('text_date')}
        </Text>),
      dataIndex: 'fecha_hora',
      key: 'fecha_hora',
      width: 180,
      responsive: ['md'],
      render: (text) => (
        <Text className="text">
          {text}
        </Text>
      ),
    },
    {
      title: (
        <Text className="text">
          {t('text_status_origin')}
        </Text>),
      dataIndex: 'label_origen',
      key: 'label_origen',
      width: 150,
      responsive: ['md'],
      render: (text) => (
        <Text className="text">
          {text}
        </Text>
      ),
    },
    {
      title: (
        <Text className="text">
          {t('text_status_destiny')}
        </Text>),
      dataIndex: 'label_destino',
      key: 'label_destino',
      width: 150,
      responsive: ['md'],
      render: (text) => (
        <Text className="text">
          {text}
        </Text>
      ),
    },
    {
      title: (
        <Text className="text">
          {t('text_comment')}
        </Text>),
      dataIndex: 'comentario',
      key: 'comentario',
      responsive: ['md'],
      render: (text) => (
        <Text className="text">
          {text}
        </Text>
      ),
    },
  ];

  return (
    <Drawer
      title={t('text_comments')}
      placement="right"
      open={comments?.result && comments?.comments?.length > 0}
      width={1000}
      onClose={() => {
        dispatch(actionGetCommentPreConciliationResult(false, null, null));
      }}
    >
      <Row justify="end">
        <Col span={24}>
          <Table
            style={{ width: '100%' }}
            rowKey="id_historico"
            pagination={{ total: comments?.comments?.length }}
            size="small"
            columns={columns}
            dataSource={comments?.comments}
            summary={() => (
              <Table.Summary.Row>
                <Table.Summary.Cell colSpan={3}>
                  <Text className="text">
                    {`${t('text_total_values')} ${comments?.comments?.length}`}
                  </Text>
                </Table.Summary.Cell>
              </Table.Summary.Row>
            )}
          />
        </Col>
      </Row>
    </Drawer>
  );
}
