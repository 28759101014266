const Colors = {
  PRIMARY_COLOR: '#2BC6CE',
  BORDER_COLOR: '#D9D9D9',
  BACKGROUND_COLOR: '#114D69',
  BACKGROUND_COLOR_2: '#F0F2F5',
  BACKGROUND_COLOR_3: '#FAFAFA',
  ICON_COLOR: 'rgba(0, 0, 0, 0.25)',
  INPUT_COLOR: 'rgba(0, 0, 0, 0.65)',
  TEXT_COLOR: 'rgba(0, 0, 0, 0.85)',
  TEXT_GREEN: '#2FC25B',
  TEXT_DARK_GREEN: '#32635d',
  TEXT_RED: '#FB3254',
  TEXT_YELLOW: '#FFCC00',
  PROGRESS_GREY_LINE: '#E8E8E8',
  PENDING: '#17A2B8',
  VALIDATE: '#28A745',
  EXCEPTION: '#DC3543',
  EXCLUDE: '#FFA62B',
  PRE_CONCILIATE: '#6C757D',
  CONCILIATE: '#35605A',
  FACTURE: '#99C24D',
  OTHERS: '#007BFF',
  PAY_CHARGE: '#2FC25B',
  PAY_RETURN: '#DE5612',
  ADG_COLLECT: '#FFCC00',
  HOTEL_COLLECT: '#00474F',
};

export default Colors;
