import React, { useEffect, useState } from 'react';
import {
  Popover, Select, Typography, message
} from 'antd';
import { HddOutlined, ReloadOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import AsyncProcessesList from './AsyncProcessesList';
import { useLazyGetAsyncProcessQuery } from '../../../services/asyncProcess';
import {ConstantsAsyncProcessStatus} from '../../../constants/ConstantsAsyncProcess';

const { Text } = Typography;

const selectStatusOptions = [
  {
    value: ConstantsAsyncProcessStatus.PENDING,
    label: ConstantsAsyncProcessStatus.PENDING,
  },
  {
    value: ConstantsAsyncProcessStatus.IN_PROCESS,
    label: ConstantsAsyncProcessStatus.IN_PROCESS,
  },
  {
    value: ConstantsAsyncProcessStatus.FINISHED,
    label: ConstantsAsyncProcessStatus.FINISHED,
  },
  {
    value: ConstantsAsyncProcessStatus.ERROR,
    label: ConstantsAsyncProcessStatus.ERROR,
  },
  {
    value: 'all',
    label: 'Todos',
  }
];

const AsyncProcessSubMenu = React.memo(() => {
  const { t } = useTranslation();
  const [currentProcessStatusFilter, setCurrentProcessStatusFilter] = useState(null);

  const [
    getAsyncProcess,
    {
      data: asyncProcessData,
      isFetching: isFetchingAsyncProcess,
      isError: isErrorAsyncProcess,
      error: errorAsyncProcess
    }
  ] = useLazyGetAsyncProcessQuery();

  useEffect(
    () => {
      if (errorAsyncProcess) {
        message.error('Error al obtener los procesos asincronos');
        console.log(errorAsyncProcess);
      }
    },
    [isErrorAsyncProcess]
  );

  const onChange = (value) => {
    const status = value === 'all' ? null : value;
    setCurrentProcessStatusFilter(status);
    getAsyncProcess({
      start: 0,
      limit: 10,
      status
    });
  };

  return (
    <Popover
      placement="bottomRight"
      title={(
        <div className="py-2 flex items-center justify-between">
          <Text className="text-base">
            {t('async_processes')}
          </Text>
          <div>
            <Select
              className="mr-3 min-w-28"
              placeholder="Estado"
              optionFilterProp="children"
              onChange={onChange}
              options={selectStatusOptions}
            />
            <ReloadOutlined
              onClick={() => {
                getAsyncProcess({
                  start: 0,
                  limit: 10,
                  status: currentProcessStatusFilter
                });
              }}
              className="cursor-pointer"
            />
          </div>
        </div>
                )}
      content={(<AsyncProcessesList bulkActions={asyncProcessData} isLoading={isFetchingAsyncProcess} />)}
      trigger="click"
      onOpenChange={(open) => {
        if (open) {
          getAsyncProcess({
            start: 0,
            limit: 10,
            status: currentProcessStatusFilter
          });
        }
      }}
    >
      <HddOutlined className="mr-2 text-white text-2xl" />
    </Popover>
  );
});

export default AsyncProcessSubMenu;
