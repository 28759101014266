import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Col,
  Modal,
  Row,
  Table,
  Tooltip,
  Typography,
  message,
  Space
} from 'antd';

import { DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import Colors from '../../../assets/colors/Colors';
import ConstantsDate from '../../../constants/ConstantsDate';
import i18n from '../../../assets/language/i18n';
import styles from './styles/stylesIndex';
import { actionLoading } from '../../../store/reducers/loading/loadingActions';
import {
  actionCancelDevolution,
  actionClearCancelDevolutionResult,
  actionCreateDevolution,
  actionClearCreateDevolutionResult
} from '../../../store/reducers/tpvTransactions/tpvTransactionsActions';

import {
  getDevolutionPermission
} from '../../../utils/Utils';

import RefundModal from './RefundModal';

const statusList = [
  { rightKey: 'PENDING', key: 'pendiente', value: 'Pendiente' },
  { rightKey: 'CANCEL', key: 'cancel', value: 'Cancelada' },
  { rightKey: 'ERROR', key: 'error', value: 'Error' },
  { rightKey: 'DONE', key: 'Ejecutada', value: 'Ejecutada' }
];

export default function RefundTable(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { Text } = Typography;
  const { confirm } = Modal;

  const loading = useSelector((state) => state.reducerLoading);
  const cancelDevolutionResult = useSelector(
    (state) => state.reducerCancelDevolutionResult
  );
  const createDevolutionResult = useSelector(
    (state) => state.reducerCreateDevolutionResult
  );

  const { transaction, refreshDevolutionsHandler } = props;
  const [showModalDevolutions, setShowModalDevolutions] = useState(false);
  const devolutions = transaction?.devolutions || [];

  useEffect(() => {
    if (createDevolutionResult?.result) {
      message.success(t('success_create_devolution'));
      dispatch(actionClearCreateDevolutionResult());
      refreshDevolutionsHandler();
    } else if (createDevolutionResult?.msg) {
      message.error(createDevolutionResult?.msg);
      dispatch(actionClearCreateDevolutionResult());
    }
  });

  useEffect(() => {
    if (cancelDevolutionResult?.result) {
      message.success(t('success_cancel_devolution'));
      dispatch(actionClearCancelDevolutionResult());
      refreshDevolutionsHandler();
    } else if (cancelDevolutionResult?.msg) {
      message.error(cancelDevolutionResult?.msg);
      dispatch(actionClearCancelDevolutionResult());
    }
  });

  const cancelDevolutionHandler = (record) => {
    confirm({
      title: t('text_cancel_devolution'),
      icon: <ExclamationCircleOutlined />,
      onOk: () => {
        dispatch(actionLoading(true));
        dispatch(actionCancelDevolution(record.id_tpv_devolucion_solicitud));
      }
    });
  };

  const createNewDevolutionHandler = (amount, comments) => {
    actionLoading(true);
    dispatch(
      actionCreateDevolution(
        transaction.id_paycomet_transacciones,
        amount,
        comments
      )
    );
    setShowModalDevolutions(false);
  };

  const devolutionPermissions = getDevolutionPermission();

  const canAddDevolution = () => {
    if (!devolutionPermissions.escritura) {
      return false;
    }
    if (!transaction.devolutions) {
      return true;
    }
    let totalPayed = transaction.devolutions.filter(
      (item) => item.estado_solicitud
        === statusList.find((keys) => keys.rightKey === 'DONE')?.key
    );
    totalPayed = totalPayed.reduce(
      (partialSum, item) => item.importe + partialSum,
      0
    );
    return totalPayed < Number(transaction.amount);
  };

  const columns = [
    {
      title: <Text className="text">Estado</Text>,
      dataIndex: 'estado_solicitud',
      key: 'estado_solicitud',
      responsive: ['md'],
      render: (text, record) => {
        const currentStatus = statusList.find(
          (item) => item.key.toLowerCase() === text.toLowerCase()
        );
        let style = {};
        switch (currentStatus?.rightKey) {
          case 'CANCEL':
          case 'ERROR':
            style = styles.textValueRedColumn;
            break;
          case 'PENDING':
            style = styles.textValueYellowColumn;
            break;
          case 'DONE':
            style = styles.textValueGreenColumn;
            break;

          default:
            break;
        }

        return {
          props: {
            style: { background: Colors.BACKGROUND_COLOR_3 }
          },
          children: (
            <Text className="text" style={style}>
              {currentStatus?.value ? currentStatus.value : text}
            </Text>
          )
        };
      }
    },
    {
      title: <Text className="text">{t('hint_user')}</Text>,
      dataIndex: 'nombre_usuario',
      key: 'nombre_usuario',
      sorter: (a, b) => a.nombre_usuario?.localeCompare(b.nombre_usuario, i18n.language),
      responsive: ['md'],
      render: (text) => <Text className="text">{text}</Text>
    },
    {
      title: <Text className="text">{t('text_currency')}</Text>,
      dataIndex: 'moneda',
      key: 'moneda',
      responsive: ['md'],
      render: () => <Text className="text">{transaction.moneda}</Text>
    },
    {
      title: <Text className="text">Importe</Text>,
      dataIndex: 'importe',
      key: 'importe',
      sorter: (a, b) => Number(a.amount) - Number(b.amount),
      responsive: ['md'],
      render: (text) => <Text className="text">{text}</Text>
    },
    {
      title: <Text className="text">{t('text_creation_date')}</Text>,
      dataIndex: 'fecha_creacion',
      key: 'fecha_creacion',
      responsive: ['md'],
      sorter: (a, b) => new Date(a.fecha_creacion) - new Date(b.fecha_creacion),
      render: (text, record) => (
        <Text className="text">
          {text?.length === 0 || record?.fecha_creacion === '0000-00-00'
            ? ''
            : format(new Date(text), ConstantsDate.DATE_HOUR_FORMAT, {
              locale: es
            })}
        </Text>
      )
    },
    {
      title: <Text className="text">{t('text_update_date')}</Text>,
      dataIndex: 'fecha_actualizacion',
      key: 'fecha_actualizacion',
      responsive: ['md'],
      sorter: (a, b) => new Date(a.fecha_actualizacion) - new Date(b.fecha_actualizacion),
      render: (text, record) => (
        <Text className="text">
          {text?.length === 0 || record?.fecha_actualizacion === '0000-00-00'
            ? ''
            : format(new Date(text), ConstantsDate.DATE_HOUR_FORMAT, {
              locale: es
            })}
        </Text>
      )
    },
    {
      title: '',
      dataIndex: 'id_tpv_devolucion_solicitud',
      key: 'id_tpv_devolucion_solicitud',
      responsive: ['md'],
      render: (text, record) => (
        <Row>
          <Col>
            {devolutionPermissions.escritura
              && statusList.find(
                (item) => item.key.toLowerCase() === record.estado_solicitud.toLowerCase()
              )?.rightKey === 'PENDING' ? (
                <Tooltip title={t('cancel_devolution')}>
                  <Button
                    type="link"
                    htmlType="button"
                    onClick={() => {
                      cancelDevolutionHandler(record);
                    }}
                    icon={<DeleteOutlined />}
                    style={styles.buttonLink}
                  />
                </Tooltip>
              ) : (
                <div />
              )}
          </Col>
        </Row>
      )
    }
  ];

  return (
    <div style={styles.div}>
      <Space size="middle" direction="vertical">
        <Row align="middle">
          <Table
            rowKey="id_tpv_devolucion_solicitud"
            pagination={{ hideOnSinglePage: true }}
            size="small"
            loading={loading}
            columns={columns}
            dataSource={devolutions ?? []}
          />
        </Row>
        <Row>
          <Col>
            <Button
              disabled={!canAddDevolution()}
              type="primary"
              htmlType="submit"
              onClick={() => {
                setShowModalDevolutions(true);
              }}
              style={styles.buttonPrimary}
            >
              {t('text_button_add_devolution')}
            </Button>
          </Col>
        </Row>
        {showModalDevolutions ? (
          <RefundModal
            submitFormHandler={createNewDevolutionHandler}
            transaction={transaction}
            closeModal={() => setShowModalDevolutions(false)}
          />
        ) : (
          <div />
        )}
      </Space>
    </div>
  );
}

RefundTable.defaultProps = {
  transaction: undefined,
  refreshDevolutionsHandler: () => { }
};

RefundTable.propTypes = {
  refreshDevolutionsHandler: PropTypes.func,
  transaction: PropTypes.shape({
    moneda: PropTypes.string,
    id_paycomet_transacciones: PropTypes.string,
    devolutions: PropTypes.arrayOf(PropTypes.shape({})),
    amount: PropTypes.string
  })
};
