import ConstantsTpvTransactions from './tpvTransactionsConstants';
import StoreGlobalConstants from '../storeGlobalConstants';

export const reducerFiltersTpvTransactions = (state = {
  result: false,
  msg: null,
  hotels: [],
  payStates: [],
  chains: [],
  currencies: []
}, action = {}) => {
  switch (action.type) {
    case ConstantsTpvTransactions.GET_FILTERS_TRANSACTIONS_RESULT:
      return {
        ...state,
        result: action.result,
        msg: action.msg,
        hotels: action.filters.hotels,
        payStates: action.filters.payStates,
        chains: action.filters.chains,
        operationTypes: action.filters.operationTypes,
        currencies: action.filters.currencies
      };
    case StoreGlobalConstants.CLEAR_DATA:
      return {
        ...state,
        result: false,
        msg: null,
        hotels: [],
        payStates: [],
        chains: [],
        operationTypes: [],
        currencies: []
      };
    default:
      return state;
  }
};

export const reducerTpvTransactions = (state = [], action = {}) => {
  switch (action.type) {
    case ConstantsTpvTransactions.GET_TPV_TRANSACTIONS_RESULT:
      return action.charges;
    default:
      return state;
  }
};

export const reducerDevolutions = (state = [], action = {}) => {
  switch (action.type) {
    case ConstantsTpvTransactions.GET_DEVOLUTIONS_RESULT:
      return action.devolutions;
    default:
      return state;
  }
};

export const reducerCancelDevolutionResult = (state = [], action = {}) => {
  switch (action.type) {
    case ConstantsTpvTransactions.CANCEL_DEVOLUTION_RESULT:
      return {
        result: action.result,
        msg: action.msg,
      };
    case ConstantsTpvTransactions.CLEAR_CANCEL_DEVOLUTION_RESULT:
      return {
        result: false,
        msg: null,
      };
    default:
      return state;
  }
};

export const reducerCreateDevolutionResult = (state = [], action = {}) => {
  switch (action.type) {
    case ConstantsTpvTransactions.CREATE_DEVOLUTION_RESULT:
      return {
        result: action.result,
        msg: action.msg,
      };
    case ConstantsTpvTransactions.CLEAR_CREATE_DEVOLUTION_RESULT:
      return {
        result: false,
        msg: null,
      };
    default:
      return state;
  }
};
