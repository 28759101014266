import {
  combineReducers,
} from 'redux';

import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import rootSaga from '../sagas/Sagas';
import reducerLoading from './reducers/loading/loading';
import {reducerLoginResult, reducerLoginByHashResult} from './reducers/login/login';
import reducerForgotPasswordResult from './reducers/login/forgotPassword';

import reducerResetPasswordResult from './reducers/login/resetPassword';

import {reducerIndex, reducerJwtData} from './reducers/home/home';
import {
  reducerAddPayReservationResult,
  reducerExpandedKeysReservation,
  reducerFilters,
  reducerPayChargeReservationResult,
  reducerRemovePayReservationResult,
  reducerReservations,
  reducerSetReservation,
} from './reducers/payments/payments';
import {
  reducerAddNewPreconciliation,
  reducerCancelConciliation,
  reducerConciliationCurrentFilters,
  reducerConciliations,
  reducerFiltersConciliations,
  reducerGetCompanyByChain,
  reducerGetPdfPrebill,
  reducerSendAllBillResult,
  reducerSendPreBillResult,
  reducerPrevisualizeConciliation
} from './reducers/conciliations/conciliations';

import reducerDataPayment from './reducers/pay/pay';
import {
  reducerApplyRecalificationReservationsResult,
  reducerChangeStatusReservationsResult,
  reducerDeleteReclasificationReservationsResult,
  reducerFiltersPreConciliation,
  reducerPreConciliationCommentResult,
  reducerPreConciliationReservations,
  reducerPreConciliationResumeResult,
  reducerPreConciliationSendCommentResult,
} from './reducers/preConciliation/preConciliation';

import { reducerGetFiltersTpvManualResult, reducerTpvManual } from './reducers/tpvManual/tpvManual';
import {
  reducerFiltersTpvTransactions,
  reducerTpvTransactions,
  reducerDevolutions,
  reducerCancelDevolutionResult,
  reducerCreateDevolutionResult
} from './reducers/tpvTransactions/tpvTransactions';

import { api } from '../services/api';
import invitationsReducer from '../services/invitations';
import collectionRequestsReducer from '../services/collectionRequests';
import paymentRequestsReducer from '../services/paymentRequests';
import tpvTransactionRequestsReducer from '../services/tpvTransactionRequests';

const sagaMiddleWare = createSagaMiddleware();

const reducers = combineReducers({
  invitations: invitationsReducer,
  collectionRequests: collectionRequestsReducer,
  paymentRequests: paymentRequestsReducer,
  tpvTransactionRequests: tpvTransactionRequestsReducer,
  [api.reducerPath]: api.reducer,
  reducerLoading,
  reducerLoginResult,
  reducerLoginByHashResult,
  reducerForgotPasswordResult,
  reducerIndex,
  reducerJwtData,
  reducerFilters,
  reducerReservations,
  reducerAddPayReservationResult,
  reducerRemovePayReservationResult,
  reducerDataPayment,
  reducerSetReservation,
  reducerPayChargeReservationResult,
  reducerExpandedKeysReservation,
  reducerFiltersPreConciliation,
  reducerPreConciliationReservations,
  reducerApplyRecalificationReservationsResult,
  reducerChangeStatusReservationsResult,
  reducerPreConciliationResumeResult,
  reducerPreConciliationSendCommentResult,
  reducerDeleteReclasificationReservationsResult,
  reducerFiltersConciliations,
  reducerConciliations,
  reducerGetCompanyByChain,
  reducerAddNewPreconciliation,
  reducerGetPdfPrebill,
  reducerSendPreBillResult,
  reducerSendAllBillResult,
  reducerCancelConciliation,
  reducerPreConciliationCommentResult,
  reducerConciliationCurrentFilters,
  reducerTpvManual,
  reducerGetFiltersTpvManualResult,
  reducerTpvTransactions,
  reducerFiltersTpvTransactions,
  reducerPrevisualizeConciliation,
  reducerDevolutions,
  reducerCancelDevolutionResult,
  reducerCreateDevolutionResult,
  reducerResetPasswordResult,
});

const store = configureStore({
  reducer: reducers,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(api.middleware, sagaMiddleWare),
});

sagaMiddleWare.run(rootSaga);

export default store;
