import {
  call, put, takeEvery, select
} from 'redux-saga/effects';
import { parse } from 'date-fns';
import qs from 'qs';
import {
  actionGetFiltersTpvTransactionsResult,
  actionGetTpvTransactionsResult,
  actionGetDevolutionsByTransactionResult,
  actionCancelDevolutionResult,
  actionCreateDevolutionResult,
} from '../store/reducers/tpvTransactions/tpvTransactionsActions';
import i18n from '../assets/language/i18n';
import { actionLoading } from '../store/reducers/loading/loadingActions';
import {request} from './axiosConfig';
import ConstantsTpvTransactions from '../store/reducers/tpvTransactions/tpvTransactionsConstants';

function getFiltersTpvTransactionsApi() {
  return request.post('/transacciones/getFiltros', new FormData())
    .then((response) => response)
    .catch((error) => error.response);
}

function* generateGetFiltersTpvTransactions() {
  const filters = {
    hotels: [],
    payStates: [],
    chains: [],
    currencies: [],
    operationTypes: [],
  };
  try {
    const { status, data } = yield call(getFiltersTpvTransactionsApi);
    if (status === 200) {
      filters.hotels = data.hoteles_asociados?.sort((a, b) => a?.label.localeCompare(b?.label, i18n.language));
      filters.payStates = data.estado?.sort((a, b) => a?.label.localeCompare(b?.label, i18n.language));
      filters.chains = data.cadena_hotelera?.sort((a, b) => a?.nombre_cadena.localeCompare(b?.nombre_cadena, i18n.language));
      filters.currencies = data.moneda?.sort((a, b) => a?.label.localeCompare(b?.label, i18n.language));

      filters.operationTypes = data.tipo_operacion?.sort((a, b) => a?.label.localeCompare(b?.label, i18n.language));

      yield put(actionGetFiltersTpvTransactionsResult(true, null, filters));
    } else {
      yield put(
        actionGetFiltersTpvTransactionsResult(
          false,
          i18n.t('error_get_filters'),
          filters
        )
      );
    }
  } catch (error) {
    console.log(error);
    yield put(
      actionGetFiltersTpvTransactionsResult(
        false,
        i18n.t('error_get_filters'),
        filters
      )
    );
  }

  yield put(actionLoading(false));
}

function getTpvTransactionsApi(values) {
  const parameters = {};
  if (values.hotel?.length !== 0) {
    parameters.hotel = values.hotel;
  }
  if (values.numberLocator?.length !== 0) {
    parameters.reserva = values.numberLocator;
  }
  if (values.dateCreateStart?.length !== 0) {
    parameters.fecha_creacion = values.dateCreateStart;
  }
  if (values.dateCreateEnd?.length !== 0) {
    parameters.fecha_creacion_final = values.dateCreateEnd;
  }
  if (values.payState?.length) {
    parameters.estado = values.payState;
  }
  if (values.currency?.length !== 0) {
    parameters.moneda = values.currency;
  }
  if (values.chain?.length !== 0) {
    parameters.cadena_hotelera = values.chain;
  }
  if (values.cardLastNumber?.length !== 0) {
    parameters.tarjeta = values.cardLastNumber;
  }
  if (values.idTransaction?.length !== 0) {
    parameters.transaccion = values.idTransaction;
  }

  // nuevos parametros cubatur
  if (values.internalReference?.length !== 0) {
    parameters.referencia_interna = values.internalReference;
  }

  // nuevos parametros cubatur
  if (values.operationType?.length !== 0) {
    parameters.tipo_operacion = values.operationType;
  }

  if (values.dateInitSendStart?.length !== 0) {
    parameters.fecha_inicio_servicio = values.dateInitSendStart;
  }
  if (values.dateInitSendEnd?.length !== 0) {
    parameters.fecha_inicio_servicio_final = values.dateInitSendEnd;
  }

  if (values.dateEndSendStart?.length !== 0) {
    parameters.fecha_fin_servicio = values.dateEndSendStart;
  }
  if (values.dateEndSendEnd?.length !== 0) {
    parameters.fecha_fin_servicio_final = values.dateEndSendEnd;
  }

  return request.post(
    '/transacciones/getTransacciones',
    qs.stringify(parameters)
  )
    .then((response) => response)
    .catch((error) => error.response);
}

function* generateGetTpvTransactions(values) {
  try {
    const { status, data } = yield call(getTpvTransactionsApi, values);
    if (status === 200) {
      const charges = data.transacciones.map((charge) => ({
        ...charge,
        fecha_transaccion:
          charge.fecha_transaccion === null
            ? ''
            : parse(
              charge.fecha_transaccion,
              'yyyy-MM-dd HH:mm:ss',
              new Date()
            ),
        fecha_transaccion_string: charge.fecha_transaccion,
      }));
      yield put(actionGetTpvTransactionsResult(true, null, charges));
    } else {
      yield put(
        actionGetTpvTransactionsResult(false, i18n.t('error_get_charges'), [])
      );
    }
  } catch (error) {
    console.log(error);
    yield put(
      actionGetTpvTransactionsResult(false, i18n.t('error_get_charges'), [])
    );
  }

  yield put(actionLoading(false));
}

function generateGetDevolutionsApi(values) {
  const parameters = {};
  if (values.idTransaction) {
    parameters.id_tpv_transaccion = values.idTransaction;
  }

  return request.post(
    '/devoluciones/getDevolucionesByIdTPVTransaccion',
    qs.stringify(parameters)
  )
    .then((response) => response)
    .catch((error) => error.response);
}

function* generateGetDevolutions(values) {
  try {
    const { status, data } = yield call(generateGetDevolutionsApi, values);
    if (status === 200) {
      const transactions = yield select(
        (state) => state.reducerTpvTransactions
      );
      const resultedTransactions = transactions.map((item) => (item.id_paycomet_transacciones === values.idTransaction
        ? { ...item, devolutions: data.solicitudes }
        : item));
      yield put(
        actionGetTpvTransactionsResult(true, null, resultedTransactions)
      );

      yield put(
        actionGetDevolutionsByTransactionResult(true, null, data.solicitudes)
      );
    } else {
      yield put(
        actionGetDevolutionsByTransactionResult(
          false,
          i18n.t('error_get_devolutions'),
          []
        )
      );
    }
  } catch (error) {
    console.log(error);
    yield put(
      actionGetDevolutionsByTransactionResult(
        false,
        i18n.t('error_get_devolutions'),
        []
      )
    );
  }

  yield put(actionLoading(false));
}

function generatCancelDevolutionApi(values) {
  const parameters = {};
  if (values.idDevolution) {
    parameters.id_tpv_devolucion_solicitud = values.idDevolution;
  }

  return request.post(
    '/devoluciones/cancelSolicitudDevolucion',
    qs.stringify(parameters)
  )
    .then((response) => response)
    .catch((error) => error.response);
}

function* generateCancelDevolution(values) {
  try {
    const { status, data } = yield call(generatCancelDevolutionApi, values);
    if (status === 200) {
      yield put(actionCancelDevolutionResult(true, null));
    } else {
      yield put(
        actionCancelDevolutionResult(false, i18n.t('error_get_devolutions'))
      );
    }
  } catch (error) {
    console.log(error);
    yield put(
      actionCancelDevolutionResult(false, i18n.t('error_get_devolutions'))
    );
  }

  yield put(actionLoading(false));
}

function generateCreateDevolutionApi(values) {
  const parameters = {
    importe: values.amount,
    id_tpv_transaccion: values.idTransaction,
    comentario: values.comments,
  };

  return request.post(
    '/devoluciones/newSolicitudDevolucion',
    qs.stringify(parameters),
  )
    .then((response) => response)
    .catch((error) => error.response);
}

function* generateCreateDevolution(values) {
  try {
    const { status, data } = yield call(generateCreateDevolutionApi, values);
    if (status === 200 && data?.code === 0) {
      yield put(actionCreateDevolutionResult(true, null));
    } else {
      yield put(
        actionCreateDevolutionResult(
          false,
          data?.mensaje ? data.mensaje : i18n.t('error_create_devolution')
        )
      );
    }
  } catch (error) {
    console.log(error);
    yield put(
      actionCreateDevolutionResult(false, i18n.t('error_create_devolution'))
    );
  }

  yield put(actionLoading(false));
}

export default function* tpvTransactionsSagas() {
  yield takeEvery(
    ConstantsTpvTransactions.GET_FILTERS_TRANSACTIONS,
    generateGetFiltersTpvTransactions
  );
  yield takeEvery(
    ConstantsTpvTransactions.GET_TPV_TRANSACTIONS,
    generateGetTpvTransactions
  );
  yield takeEvery(
    ConstantsTpvTransactions.GET_DEVOLUTIONS,
    generateGetDevolutions
  );
  yield takeEvery(
    ConstantsTpvTransactions.CANCEL_DEVOLUTION,
    generateCancelDevolution
  );
  yield takeEvery(
    ConstantsTpvTransactions.CREATE_DEVOLUTION,
    generateCreateDevolution
  );
}
