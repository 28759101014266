const ConstantsPreConciliation = {
  GET_FILTERS_PRE_CONCILIATION: 'GET_FILTERS_PRE_CONCILIATION',
  GET_FILTERS_PRE_CONCILIATION_RESULT: 'GET_FILTERS_PRE_CONCILIATION_RESULT',
  GET_PRE_CONCILIATION_RESERVATIONS: 'GET_PRE_CONCILIATION_RESERVATIONS',
  GET_PRE_CONCILIATION_RESERVATIONS_RESULT: 'GET_PRE_CONCILIATION_RESERVATIONS_RESULT',
  APPLY_RECALIFICATION_RESERVATIONS: 'APPLY_RECALIFICATION_RESERVATIONS',
  APPLY_RECALIFICATION_RESERVATIONS_RESULT: 'APPLY_RECALIFICATION_RESERVATIONS_RESULT',
  CHANGE_STATUS_RESERVATIONS: 'CHANGE_STATUS_RESERVATIONS',
  CHANGE_STATUS_RESERVATIONS_RESULT: 'CHANGE_STATUS_RESERVATIONS_RESULT',
  GET_RESUME_PRE_CONCILIATION: 'GET_RESUME_PRE_CONCILIATION',
  GET_RESUME_PRE_CONCILIATION_RESULT: 'GET_RESUME_PRE_CONCILIATION_RESULT',
  GET_COMMENT_PRE_CONCILIATION: 'GET_COMMENT_PRE_CONCILIATION',
  GET_COMMENT_PRE_CONCILIATION_RESULT: 'GET_COMMENT_PRE_CONCILIATION_RESULT',
  SEND_COMMENT_RESERVATIONS: 'SEND_COMMENT_RESERVATIONS',
  SEND_COMMENT_RESERVATIONS_RESULT: 'SEND_COMMENT_RESERVATIONS_RESULT',
  DELETE_RECALIFICATION_RESERVATIONS: 'DELETE_RECALIFICATION_RESERVATIONS',
  DELETE_RECALIFICATION_RESERVATIONS_RESULT: 'DELETE_RECALIFICATION_RESERVATIONS_RESULT',
};

export default ConstantsPreConciliation;
