import React, { useRef } from 'react';
import {
  Modal,
  Button,
  Result,
  Row, Typography, Descriptions
} from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import ReactToPrint from 'react-to-print';

const styles = {
  buttonPrimary: {
    fontFamily: 'Roboto-Bold',
    fontWeight: '600',
    fontSize: 16,
    marginLeft: 8,
  },
};

export default function PaymentResultModal(props) {
  const { t } = useTranslation();
  const loading = useSelector((state) => state.reducerLoading);
  const { Text } = Typography;
  const componentRef = useRef();

  const {
    paymentData, isSuccess, visible, closeModal
  } = props;

  const summaryContent = (
    <div ref={componentRef}>
      <Row
        align="center"
        justify="center"
      >
        <Result
          status={isSuccess ? 'success' : 'error'}
          title={(
            <Text style={styles.textResultValue}>
              {paymentData.message}
            </Text>
          )}
        />
      </Row>

      {isSuccess ? (
        <Row
          align="center"
          justify="center"
        >
          <Descriptions size="small" column={1} bordered>
            <Descriptions.Item label={t('text_reference')} key="reference">
              {paymentData?.reserva}
            </Descriptions.Item>
            <Descriptions.Item label={t('text_booking_locator')} key="localizator">
              {paymentData?.referencia_externa}
            </Descriptions.Item>
            <Descriptions.Item label={t('text_transaction')} key="transaction">
              {paymentData?.transaccion}
            </Descriptions.Item>
            <Descriptions.Item label={t('text_transaction_date')} key="fecha_transaccion">
              {paymentData?.fecha_transaccion}
            </Descriptions.Item>
            <Descriptions.Item label={t('text_hotel')} key="hotel">
              {paymentData?.hotel}
            </Descriptions.Item>
            <Descriptions.Item label={t('text_import')} key="importe">
              {paymentData?.importe}
            </Descriptions.Item>
            <Descriptions.Item label={t('text_currency')} key="moneda">
              {paymentData?.moneda}
            </Descriptions.Item>
            <Descriptions.Item label={t('text_credit_card')} key="tarjeta">
              {paymentData?.tarjeta}
            </Descriptions.Item>
          </Descriptions>
        </Row>
      ) : ''}
    </div>
  );

  return (
    <Modal
      title={t('text_payment_result')}
      open={visible}
      confirmLoading={loading}
      closable={false}
      footer={[
        <ReactToPrint
          trigger={() => (
            <Button
              disabled={!isSuccess}
              type="primary"
              style={styles.buttonPrimary}
              key="print"
            >
              {t('text_button_print')}
            </Button>
          )}
          content={() => componentRef.current}
        />,
        <Button
          type="primary"
          onClick={() => {
            if (isSuccess) {
              window.location.reload();
            }
            closeModal();
          }}
          style={styles.buttonPrimary}
          key="accept"
        >
          {t('text_button_accept')}
        </Button>
      ]}
    >
      {summaryContent}
    </Modal>
  );
}

PaymentResultModal.defaultProps = {
  visible: false,
  paymentData: null,
  isSuccess: false,
  closeModal: () => { },

};

PaymentResultModal.propTypes = {
  visible: PropTypes.bool,
  closeModal: PropTypes.func,
  paymentData: PropTypes.objectOf({
    reserva: PropTypes.string,
    referencia_externa: PropTypes.string,
    transaccion: PropTypes.string,
    fecha_transaccion: PropTypes.string,
    hotel: PropTypes.string,
    importe: PropTypes.string,
    moneda: PropTypes.string,
    tarjeta: PropTypes.string,
  }),
  isSuccess: PropTypes.bool,
};
