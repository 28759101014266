import { createSlice } from '@reduxjs/toolkit';
import qs from 'qs';
import { api } from './api';
import i18n from '../assets/language/i18n';
import { downloadFile } from '../utils/Utils';

export const collectionRequestsApi = api.injectEndpoints({
  tagTypes: ['collectionRequests'],
  endpoints: (build) => ({
    getFiltersCollectionRequestsApi: build.query({
      query: () => ({ method: 'POST', url: '/SolicitudCobro/getFiltros', data: new FormData() }),
      transformResponse: (response) => ({
        companies: response?.empresa?.sort(
          (a, b) => a?.label.localeCompare(b?.label, i18n.language),
        ),
        status: response?.estado?.sort(
          (a, b) => a?.label.localeCompare(b?.label, i18n.language),
        ),
        currencies: response?.moneda?.sort(
          (a, b) => a?.label.localeCompare(b?.label, i18n.language),
        ),
        chains: response?.cadena_hotelera?.sort(
          (a, b) => a?.nombre_cadena.localeCompare(b?.nombre_cadena, i18n.language),
        ),
        agencies: response?.touroperadores?.sort(
          (a, b) => a?.nombre.localeCompare(b?.nombre, i18n.language),
        ),

      }),
      transformErrorResponse: () => ({
        message: i18n.t('error_get_filters'),
      }),
    }),
    getCollectionRequestsApi: build.query({
      query: (values) => {
        const dataToSend = {...values};
        return { method: 'POST', url: '/SolicitudCobro/getSolicitudesCobro', data: qs.stringify(dataToSend) };
      },
      transformResponse: (response) => (response.solicitud_cobro.map((invitation) => (
        {
          ...invitation,
          estado: invitation.estado.toUpperCase(),
        }
      ))),
      transformErrorResponse: () => ({
        message: i18n.t('error_get_collectionRequests'),
      }),
      providesTags: ['collectionRequests'],
    }),
    createCollectionRequestApi: build.mutation({
      query: (data) => {
        const dataToSend = {
          fecha: data.date,
          id_cadena: data.chain,
          id_empresa: data.company,
          id_tour_operador: data.agency,
          importe: data.amount,
          moneda: data.currency,
          descripcion: data.description,
          email_empresa_cc: data.emailCompany,
          email_envio: data.agencyEmail,
          envio: data.sendEmailToAgency,
        };
        return ({ method: 'POST', url: '/SolicitudCobro/insertarSolicitudCobro', data: qs.stringify(dataToSend) });
      },
      transformResponse: (response) => ({ ...response, message: `${i18n.t('success_create_new_collection')} ${response?.referencia}`}),
      transformErrorResponse: () => ({
        message: i18n.t('error_create_new_collection'),
      }),
      invalidatesTags: ['collectionRequests'],
    }),
    changeCollectionRequestStatusApi: build.mutation({
      query: (data) => {
        const dataToSend = {
          id_sc: data.id,
          estado: data.status,
        };
        return ({ method: 'POST', url: '/SolicitudCobro/cambiarEstadoSolicitudCobro', data: qs.stringify(dataToSend) });
      },
      transformResponse: (response) => ({ ...response, message: i18n.t('success_changing_collection_status')}),
      transformErrorResponse: () => ({
        message: i18n.t('error_changing_collection_status'),
      }),
      invalidatesTags: ['collectionRequests'],
    }),
    attachDocToCollectionRequestApi: build.mutation({
      query: (data) => {
        const { id, file } = data;
        const formData = new FormData();
        formData.append('file', file);

        return ({
          method: 'POST',
          url: `/SolicitudCobro/insertAttachmentSC/${id}`,
          data: formData,
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
      },
      transformErrorResponse: () => ({
        message: i18n.t('error_attaching_collection_file'),
      }),
      invalidatesTags: ['collectionRequests'],
    }),

    getBanksApi: build.query({
      query: (values) => {
        const dataToSend = {
          id_cadena: values?.chain,
          id_touroperador: values?.agency,
        };
        return { method: 'POST', url: '/SolicitudCobro/getBancoByCadenaAndTourOperador', data: qs.stringify(dataToSend) };
      },
      transformResponse: (response) => (response),
      transformErrorResponse: () => ({
        message: i18n.t('error_get_bank_info'),
      }),
    }),
    getAttachedFileContentApi: build.query({
      query: (values) => {
        const dataToSend = {id: values.id};
        return { method: 'POST', url: '/SolicitudCobro/descargarSolicitudCobro', data: qs.stringify(dataToSend) };
      },
      transformResponse: (response, meta, arg) => {
        downloadFile(response, arg?.name);
        return {message: i18n.t('success_download_file')};
      },
      transformErrorResponse: () => ({
        message: i18n.t('error_download_file'),
      }),
    }),
    downloadCollectionRequestPdf: build.query({
      query: (values) => {
        const dataToSend = {id: values.id};
        return { method: 'POST', url: '/SolicitudCobro/generarPdf', data: qs.stringify(dataToSend) };
      },
      transformResponse: (response, meta, arg) => {
        downloadFile(response, arg?.name);
        return {message: i18n.t('success_download_file')};
      },
      transformErrorResponse: () => ({
        message: i18n.t('error_download_file'),
      }),
    }),
    resendCollectionRequest: build.query({
      query: (values) => {
        const dataToSend = {id_sc: values.id};
        return { method: 'POST', url: '/SolicitudCobro/reenviarSolicitudCobro', data: qs.stringify(dataToSend) };
      },
      transformResponse: () => ({message: i18n.t('success_resend_file')}),
      transformErrorResponse: () => ({
        message: i18n.t('error_resend_file'),
      }),
    }),
  }),
});

export const {
  useGetFiltersCollectionRequestsApiQuery,
  useGetCollectionRequestsApiQuery,
  useLazyGetCollectionRequestsApiQuery,
  useCreateCollectionRequestApiMutation,
  useChangeCollectionRequestStatusApiMutation,
  useAttachDocToCollectionRequestApiMutation,
  useLazyGetBanksApiQuery,
  useLazyGetAttachedFileContentApiQuery,
  useLazyDownloadCollectionRequestPdfQuery,
  useLazyResendCollectionRequestQuery,
} = collectionRequestsApi;

const collectionRequestsSlice = createSlice({
  name: 'collectionRequests',
  initialState: {
    appliedFilters: null,
  },
  reducers: {
    setAppliedFilters(state, action) {
      state.appliedFilters = action.payload;
    },
  },
});

export const { setAppliedFilters } = collectionRequestsSlice.actions;
export default collectionRequestsSlice.reducer;
