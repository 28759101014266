import ConstantsPreConciliation from './preConciliationConstants';

export const actionGetFiltersPreConciliation = () => ({
  type: ConstantsPreConciliation.GET_FILTERS_PRE_CONCILIATION,
});

export const actionGetFiltersPreConciliationResult = (result, msg, filters) => ({
  type: ConstantsPreConciliation.GET_FILTERS_PRE_CONCILIATION_RESULT,
  result,
  msg,
  filters,
});

export const actionGetPreConciliationReservations = (
  idTransaction,
  numberLocator,
  stateReservation,
  payState,
  concept,
  channel,
  validations,
  chain,
  nameHotel,
  dateCreateStart,
  dateCreateEnd,
  dateCheckinStart,
  dateCheckinEnd,
  otaCode,
  user,
  client,
  crs,
  currency,
  externalNumberLocator
) => ({
  type: ConstantsPreConciliation.GET_PRE_CONCILIATION_RESERVATIONS,
  idTransaction,
  numberLocator,
  stateReservation,
  payState,
  concept,
  channel,
  validations,
  chain,
  nameHotel,
  dateCreateStart,
  dateCreateEnd,
  dateCheckinStart,
  dateCheckinEnd,
  otaCode,
  user,
  client,
  crs,
  currency,
  externalNumberLocator
});

export const actionGetPreConciliationReservationsResult = (result, msg, reservations) => ({
  type: ConstantsPreConciliation.GET_PRE_CONCILIATION_RESERVATIONS_RESULT,
  result,
  msg,
  reservations,
});

export const actionApplyRecalificationReservations = (
  reservations,
) => ({
  type: ConstantsPreConciliation.APPLY_RECALIFICATION_RESERVATIONS,
  reservations
});

export const actionApplyRecalificationReservationsResult = (result, msg) => ({
  type: ConstantsPreConciliation.APPLY_RECALIFICATION_RESERVATIONS_RESULT,
  result,
  msg,
});

export const actionChangeStatusReservations = (
  reservations,
) => ({
  type: ConstantsPreConciliation.CHANGE_STATUS_RESERVATIONS,
  reservations
});

export const actionChangeStatusReservationsResult = (result, msg) => ({
  type: ConstantsPreConciliation.CHANGE_STATUS_RESERVATIONS_RESULT,
  result,
  msg,
});

export const actionGetResumePreConciliation = (dateString) => ({
  type: ConstantsPreConciliation.GET_RESUME_PRE_CONCILIATION,
  dateString,
});

export const actionGetResumePreConciliationResult = (result, msg, resume) => ({
  type: ConstantsPreConciliation.GET_RESUME_PRE_CONCILIATION_RESULT,
  result,
  msg,
  resume,
});

export const actionGetCommentPreConciliation = (id) => ({
  type: ConstantsPreConciliation.GET_COMMENT_PRE_CONCILIATION,
  id,
});

export const actionGetCommentPreConciliationResult = (result, msg, comments) => ({
  type: ConstantsPreConciliation.GET_COMMENT_PRE_CONCILIATION_RESULT,
  result,
  msg,
  comments,
});

export const actionSendCommentReservations = (
  reservations,
) => ({
  type: ConstantsPreConciliation.SEND_COMMENT_RESERVATIONS,
  reservations
});

export const actionSendCommentReservationsResult = (result, msg) => ({
  type: ConstantsPreConciliation.SEND_COMMENT_RESERVATIONS_RESULT,
  result,
  msg,
});

export const actionDeleteReclasificationReservations = (
  reservations,
) => ({
  type: ConstantsPreConciliation.DELETE_RECALIFICATION_RESERVATIONS,
  reservations
});

export const actionDeleteReclasificationReservationsResult = (result, msg) => ({
  type: ConstantsPreConciliation.DELETE_RECALIFICATION_RESERVATIONS_RESULT,
  result,
  msg,
});
