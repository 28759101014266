import React from 'react';
import {
  Row, Table, Typography
} from 'antd';
import PropTypes from 'prop-types';

import 'antd/dist/antd.less';

import { useTranslation } from 'react-i18next';
import Colors from '../../../assets/colors/Colors';
import i18n from '../../../assets/language/i18n';
import {
  getStatePayInvitation,
} from '../../../utils/Utils';

const { Text } = Typography;

const styles = {
  div: {
    width: '100%',
    backgroundColor: Colors.BACKGROUND_COLOR_3,
    paddingLeft: 46,
    paddingTop: 20,
    paddingRight: 46,
    paddingBottom: 20,
  },
  textValueGreenColumn: {
    fontSize: 14,
    fontFamily: 'Roboto-Regular',
    color: Colors.TEXT_GREEN,
  },
  textValueRedColumn: {
    fontSize: 14,
    fontFamily: 'Roboto-Regular',
    color: Colors.TEXT_RED,
  },
  textValueYellowColumn: {
    fontSize: 14,
    fontFamily: 'Roboto-Regular',
    color: Colors.TEXT_YELLOW,
  },
};

export default function InvitationsHistory(props) {
  const { t } = useTranslation();
  const { invitation } = props;

  const columns = [
    {
      title: (
        <Text className="text">
          {t('text_status')}
        </Text>),
      dataIndex: 'estado',
      key: 'estado',
      responsive: ['md'],
      render: (text) => (
        <Text className="text">
          {getStatePayInvitation(text.toUpperCase())}
        </Text>
      ),
    },
    {
      title: (
        <Text className="text">
          {t('text_date')}
        </Text>),
      dataIndex: 'fecha_cambio',
      key: 'fecha_cambio',
      sorter: {
        compare: (a, b) => a.fecha_cambio.localeCompare(b.fecha_cambio, i18n.language),
        multiple: 10,
      },
      responsive: ['md'],
      render: (text) => (
        <Text>
          {text}
        </Text>
      ),
    }
  ];

  return (
    <div style={styles.div}>
      <Row align="middle">
        <Table
          rowKey="id"
          style={{ width: '100%' }}
          pagination={
                        { hideOnSinglePage: true }
                    }
          size="small"
          columns={columns}
          dataSource={invitation.historial}
        />
      </Row>
    </div>
  );
}

InvitationsHistory.defaultProps = {
  invitation: undefined
};

InvitationsHistory.propTypes = {
  invitation: PropTypes.shape({
    historial: PropTypes.arrayOf(PropTypes.shape({
      estado: PropTypes.string,
      fecha_cambio: PropTypes.string,
      id: PropTypes.string
    }))
  })
};
