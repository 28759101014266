import { v4 as uuidv4 } from 'uuid';
import ConstantsPayments from './paymentsConstants';
import StoreGlobalConstants from '../storeGlobalConstants';

export const reducerFilters = (state = {
  result: false,
  msg: null,
  hotels: [],
  channels: [],
  payStates: [],
  payTypes: [],
  payActions: [],
  reservationStates: [],
  payForms: [],
  clients: [],
  chains: [],
  currencies: [],
  concepts: [],
  crs: []
}, action = {}) => {
  switch (action.type) {
    case ConstantsPayments.GET_FILTERS_RESULT:
      return {
        ...state,
        result: action.result,
        msg: action.msg,
        hotels: action.filters.hotels,
        channels: action.filters.channels,
        payStates: action.filters.payStates,
        payTypes: action.filters.payTypes,
        payActions: action.filters.payActions,
        reservationStates: action.filters.reservationStates,
        payForms: action.filters.payForms,
        clients: action.filters.clients,
        chains: action.filters.chains,
        currencies: action.filters.currencies,
        concepts: action.filters.concepts,
        crs: action.filters.crs
      };
    case StoreGlobalConstants.CLEAR_DATA:
      return {
        ...state,
        result: false,
        msg: null,
        hotels: [],
        channels: [],
        payStates: [],
        payTypes: [],
        payActions: [],
        reservationStates: [],
        payForms: [],
        clients: [],
        chains: [],
        currencies: [],
        concepts: [],
      };
    default:
      return state;
  }
};

export const reducerReservations = (state = [], action = {}) => {
  switch (action.type) {
    case ConstantsPayments.GET_RESERVATIONS_RESULT:
      return action.reservations;
    case ConstantsPayments.ADD_EMPTY_PAY_RESERVATION:
      return state.map((reservation) => (
        reservation.reservation_id === action.reservationId ? {
          ...reservation,
          transacciones: [...reservation.transacciones.concat({
            id_cobro: uuidv4(),
            reserva: action.reservationId,
            transaccion: '',
            amount: '',
            fecha_transaccion: '',
            tipo: '',
            estado: '',
            action: '',
            activo: '1',
            edit: false,
            create: true,
          })],
        } : reservation
      ));
    case ConstantsPayments.REMOVE_NEW_PAY_RESERVATION:
      return state.map((reservation) => (
        reservation.reservation_id === action.reservationId ? {
          ...reservation,
          transacciones: reservation.transacciones.filter((pay) => pay.id_cobro !== action.payId),
        } : reservation
      ));
    case ConstantsPayments.ADD_PAY_RESERVATION_RESULT:
      if (action.result) {
        return state.map((reservation) => (
          reservation.reservation_id === action.pay.reservationId ? {
            ...reservation,
            transacciones: reservation.transacciones.map((transaction) => (
              transaction.id_cobro === action.pay.payId ? {
                ...transaction,
                id_cobro: action.newPayId,
                reserva: action.pay.reservationId,
                transaccion: action.pay.transaction,
                amount: action.pay.amount,
                fecha_transaccion: action.pay.date,
                tipo: action.pay.payType,
                estado: action.pay.statePay,
                action: action.pay.actionPay,
                activo: '1',
                edit: false,
                create: false,
              } : transaction
            )),
          } : reservation
        ));
      }
      return state;
    case ConstantsPayments.REMOVE_PAY_RESERVATION_RESULT:
      if (action.result) {
        return state.map((reservation) => (
          reservation.reservation_id === action.reservationId ? {
            ...reservation,
            transacciones: reservation.transacciones.filter((pay) => pay.id_cobro !== action.payId),
          } : reservation
        ));
      }
      return state;
    case StoreGlobalConstants.CLEAR_DATA:
      return [];
    case ConstantsPayments.GET_CHARGES_RESERVATION_RESULT:
      if (action.result) {
        return state.map((reservation) => (
          reservation.id_reservation === action.reservationId ? {
            ...reservation,
            transacciones: action.charges.cobros,
            total_pagado: action.charges.total_pagado,
            pendiente_pago: action.charges.pendiente_pago,
          } : reservation
        ));
      }
      return state;
    default:
      return state;
  }
};

export const reducerAddPayReservationResult = (
  state = { result: false, msg: null },
  action = {}
) => {
  switch (action.type) {
    case ConstantsPayments.ADD_PAY_RESERVATION_RESULT:
      return action;
    default:
      return state;
  }
};

export const reducerRemovePayReservationResult = (
  state = { result: false, msg: null },
  action = {}
) => {
  switch (action.type) {
    case ConstantsPayments.REMOVE_PAY_RESERVATION_RESULT:
      return action;
    default:
      return state;
  }
};

export const reducerSetReservation = (state = { reservation: null }, action = {}) => {
  switch (action.type) {
    case ConstantsPayments.SET_RESERVATION:
      return action;
    default:
      return state;
  }
};

export const reducerPayChargeReservationResult = (state = { reservation: null }, action = {}) => {
  switch (action.type) {
    case ConstantsPayments.PAY_CHARGES_RESERVATION_RESULT:
      return action;
    default:
      return state;
  }
};

export const reducerExpandedKeysReservation = (state = [], action = {}) => {
  switch (action.type) {
    case ConstantsPayments.SET_EXPANDED_KEY_RESERVATION:
      if (action.expanded) {
        return [...state, action.reservationId];
      }
      return state.filter((item) => item !== action.reservationId);
    case ConstantsPayments.GET_RESERVATIONS_RESULT:
      return [];
    default:
      return state;
  }
};
