import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import es from './es/translation.json';
import en from './en/translation.json';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: !process.env.NODE_ENV || process.env.NODE_ENV === 'development',
    fallbackLng: 'es', // use en if detected lng is not available
    supportedLngs: ['es', 'en'],
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: true,
    },
    resources: {
      es: {
        translations: es,
      },
      en: {
        translations: en,
      },
    },
    ns: ['translations'],
    defaultNS: 'translations',
  });

export default i18n;
