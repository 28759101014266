import ConstantsConciliations from './conciliationsConstants';
import StoreGlobalConstants from '../storeGlobalConstants';

export const reducerFiltersConciliations = (
  state = {
    result: false,
    msg: null,
    hotels: [],
    conciliationStates: [],
    chains: [],
    facturationGroups: [],
    moneda: [],
  },
  action = {}
) => {
  switch (action.type) {
    case ConstantsConciliations.GET_FILTERS_CONCILIATIONS_RESULT:
      return {
        ...state,
        result: action.result,
        msg: action.msg,
        facturationGroups: action.filters.facturationGroups,
        conciliationStates: action.filters.conciliationStates,
        chains: action.filters.chains,
        hotels: action.filters.hotels,
        moneda: action.filters.moneda,
      };
    case StoreGlobalConstants.CLEAR_DATA:
      return {
        ...state,
        result: false,
        msg: null,
        hotels: [],
        conciliationStates: [],
        facturationGroups: [],
        moneda: [],
      };
    default:
      return state;
  }
};

export const reducerConciliationCurrentFilters = (state = [], action = {}) => {
  switch (action.type) {
    case ConstantsConciliations.CONCILIATIONS_CURRENT_FILTERS:
      return action.currentFilters;
    default:
      return state;
  }
};

export const reducerConciliations = (state = [], action = {}) => {
  switch (action.type) {
    case ConstantsConciliations.GET_CONCILIATIONS_RESULT:
      return action.conciliations;
    case StoreGlobalConstants.CLEAR_DATA:
      return [];
    default:
      return state;
  }
};

export const reducerAddNewPreconciliation = (
  state = { result: false, msg: null },
  action = {}
) => {
  switch (action.type) {
    case ConstantsConciliations.ADD_NEW_PRECONCILIATION_RESULT:
      return {
        result: action.result,
        msg: action.msg,
        preconciliation: action.preconciliation
      };
    case ConstantsConciliations.CLEAR_NEW_PRECONCILIATION_RESULT:
      return {
        result: false,
        msg: null,
      };
    default:
      return state;
  }
};

export const reducerGetCompanyByChain = (
  state = { result: false, msg: null },
  action = {}
) => {
  switch (action.type) {
    case ConstantsConciliations.GET_COMPANY_BY_CHAIN_RESULT:
      return action.companies;
    case ConstantsConciliations.CLEAR_COMPANIES:
      return [];
    default:
      return state;
  }
};

export const reducerGetPdfPrebill = (
  state = { result: false, msg: null },
  action = {}
) => {
  switch (action.type) {
    case ConstantsConciliations.GET_PDF_PREBILL_RESULT:
      return {
        result: action.result,
        msg: action.msg,
        preconciliation: action.pdf
      };
    default:
      return state;
  }
};

export const reducerCancelConciliation = (
  state = { result: false, msg: null },
  action = {}
) => {
  switch (action.type) {
    case ConstantsConciliations.CANCEL_CONCILIATION_RESULT:
      return {
        result: action.result,
        msg: action.msg,
      };
    case ConstantsConciliations.CLEAR_CANCEL_REQUEST_RESULT:
      return {
        result: false,
        msg: null,
      };
    default:
      return state;
  }
};

export const reducerSendPreBillResult = (state = {}, action = {}) => {
  switch (action.type) {
    case ConstantsConciliations.SEND_PREBILL_RESULT:
      return action;
    default:
      return state;
  }
};

export const reducerSendAllBillResult = (state = {}, action = {}) => {
  switch (action.type) {
    case ConstantsConciliations.SEND_ALL_BILL_RESULT:
      return action;
    default:
      return state;
  }
};

export const reducerPrevisualizeConciliation = (
  state = { result: false, msg: null },
  action = {}
) => {
  switch (action.type) {
    case ConstantsConciliations.PREVISUALIZE_CONCILIATION_RESULT:
      return {
        result: action.result,
        msg: action.msg,
        logs: action.logs
      };
    case ConstantsConciliations.CLEAR_NEW_CONCILIATION_PREVISUALIZAATION_RESULT:
      return {
        result: false,
        msg: null,
      };
    default:
      return state;
  }
};
