import React, { useState, useEffect} from 'react';

import {
  useParams, useNavigate
} from 'react-router-dom';
import {
  Button, Card, Col, Form, Input, Row, Spin, Typography, message
} from 'antd';
import 'antd/dist/antd.less';
import {
  LockOutlined,
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { actionLoading } from '../../store/reducers/loading/loadingActions';
import background from '../../assets/images/adobe-stock-91340865-neg.jpg';
import background2x from '../../assets/images/adobe-stock-91340865-neg@2x.jpg';
import background3x from '../../assets/images/adobe-stock-91340865-neg@3x.jpg';
import logo from '../../assets/images/group-28.svg';
import { actionLoginByHash, actionResetPassword, actionClearResetPasswordResult } from '../../store/reducers/login/loginActions';
import PageNotFound from '../../PageNotFound';

import PasswordFeature from './PasswordFeature';

const { Link, Text } = Typography;

// function to check if password has a number
function hasNumber(myString) {
  return /\d/.test(myString);
}

// function to check if password has a symbol
function hasSymbol(myString) {
  return /\W/.test(myString);
}

// function to check if password has an uppercase letter
function hasUppercase(myString) {
  return /[A-Z]/.test(myString);
}

// function to check if password has an uppercase letter
function hasLowercase(myString) {
  return /[a-z]/.test(myString);
}

// function to check if password has a length greater than 8
function hasLength(myString) {
  return myString.length >= 8;
}

export default function NewPassword() {
  const [passwordRepeated, setPasswordRepeat] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState({
    validateStatus: 'success',
    errorMsg: null,
  });

  const [pswHasNumber, setPswHasNumber] = useState('');
  const [pswHasSymbol, setPswHasSymbol] = useState('');
  const [pswHasUpperLowercase, setPswHasUpperLowercase] = useState('');
  const [pswHasLength, setPswHasLength] = useState('');

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.reducerLoading);
  const loginByHashResult = useSelector((state) => state.reducerLoginByHashResult);
  const resetPasswordResult = useSelector((state) => state.reducerResetPasswordResult);

  const { hash } = useParams();
  const navigate = useNavigate();

  const validateCredentials = () => {
    dispatch(actionLoading(true));
    dispatch(actionResetPassword(hash, password));
  };

  const resetPswValidation = () => {
    setPswHasNumber(false);
    setPswHasSymbol(false);
    setPswHasUpperLowercase(false);
    setPswHasLength(false);
  };

  const disabledRepeatPassword = () => {
    if (!pswHasLength || !pswHasNumber || !pswHasSymbol || !pswHasUpperLowercase) {
      return true;
    }
    return false;
  };

  const validatePassword = () => {
    resetPswValidation();
    let result = {
      validateStatus: 'success',
      errorMsg: null,
    };

    if (hasNumber(password)) {
      setPswHasNumber(true);
    }
    if (hasSymbol(password)) {
      setPswHasSymbol(true);
    }
    if (hasUppercase(password) && hasLowercase(password)) {
      setPswHasUpperLowercase(true);
    }
    if (hasLength(password)) {
      setPswHasLength(true);
    }

    if (passwordRepeated && password && passwordRepeated !== password) {
      result = {
        validateStatus: 'error',
        errorMsg: 'Las contraseñas no coinciden',
      };
    }
    setError(result);
  };

  useEffect(
    () => {
      validatePassword();
    },
    [passwordRepeated, password]
  );

  useEffect(
    () => {
      if (!loading) {
        dispatch(actionLoading(true));
        dispatch(actionLoginByHash(hash));
      }
    },
    []
  );

  useEffect(
    () => {
      if (resetPasswordResult.result) {
        message.success(resetPasswordResult.msg);
        dispatch(actionClearResetPasswordResult());
        setTimeout(() => {
          navigate('/login');
        }, 1500);
      }
      if (!resetPasswordResult.result && resetPasswordResult.msg) {
        message.error(resetPasswordResult.msg);
      }
    },
    [resetPasswordResult]
  );

  return !loginByHashResult.result && loginByHashResult.msg ? (<PageNotFound />) : (
    <Spin spinning={loading} size="large">
      <Row className="w-100">
        <Col span={24}>
          <img
            alt="background"
            src={background}
            srcSet={`${background2x} 2x,${background3x} 3x`}
            className="img-w img img-h-250 img-auto mb-5"
          />
        </Col>
        <Col
          span={20}
          md={12}
          xl={8}
          className="ant-row mb-5 mx-auto"
        >
          <Col span={24}>
            <Card bordered={false}>
              <Form
                name="new_password"
                initialValues={{
                  remember: true,
                  size: 'middle',
                }}
              >
                <Form.Item
                  label={t('new_password')}
                  labelCol={{ span: 24 }}
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: t('error_empty_field'),
                    },
                  ]}
                >
                  <Input.Password
                    prefix={<LockOutlined className="icon icon-color-secondary" />}
                    type="password"
                    placeholder={t('hint_password')}
                    onChange={(text) => {
                      setPassword(text.target.value);
                    }}
                    onPressEnter={validateCredentials}
                    value={password}
                    className="rounded-sm py-2"
                  />
                  <div className="my-2 mx-2">
                    <PasswordFeature label="Mayúsculas y minúsculas" value={pswHasUpperLowercase} />
                    <PasswordFeature label="Números" value={pswHasNumber} />
                    <PasswordFeature label="Símbolos (&+.*|{}?~/-%$)" value={pswHasSymbol} />
                    <PasswordFeature label="Longitud (>8)" value={pswHasLength} />
                  </div>

                </Form.Item>
                <Form.Item
                  name="newpassword"
                  labelCol={{ span: 24 }}
                  label={t('repeat_new_password')}
                  rules={[
                    {
                      required: true,
                      message: t('error_empty_field'),
                    },
                  ]}
                  validateStatus={error.validateStatus}
                  help={error.errorMsg}
                >
                  <Input.Password
                    prefix={<LockOutlined className="icon icon-color-secondary" />}
                    type="password"
                    placeholder="Repite la contraseña"
                    onChange={(text) => {
                      setPasswordRepeat(text.target.value);
                    }}
                    onPressEnter={validateCredentials}
                    value={passwordRepeated}
                    className="rounded-sm py-2"
                    disabled={disabledRepeatPassword()}
                  />
                </Form.Item>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    disabled={passwordRepeated.length === 0
                      || password.length === 0
                      || password
                      !== passwordRepeated}
                    onClick={validateCredentials}
                    className="btn-block text-bold"
                  >
                    {t('button_enter')}
                  </Button>
                </Form.Item>
              </Form>
            </Card>
          </Col>
        </Col>
        <Col
          span={20}
          offset={2}
          className="ant-row ant-row-end mb-5 text-right text-md-center"
        >
          <Col className="mb-3 text-right text-md-center">
            <img
              alt="logo"
              src={logo}
            />
          </Col>
          <Col>
            <Text className="text text-size-0-8 text-color-white mr-2">
              {t('text_contact_1')}
            </Text>
            <Link
              className="text text-size-0-8 text-color-secondary"
              href="mailto:info@adgtravel.com"
              target="_blank"
            >
              {t('text_contact_2')}
            </Link>
          </Col>
        </Col>
      </Row>

    </Spin>
  );
}
