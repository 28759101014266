import ConstantsPayments from './paymentsConstants';

export const actionGetFilters = () => ({
  type: ConstantsPayments.GET_FILTERS,
});

export const actionGetFiltersResult = (result, msg, filters) => ({
  type: ConstantsPayments.GET_FILTERS_RESULT,
  result,
  msg,
  filters,
});

export const actionGetReservations = (
  hotel,
  channel,
  dateCreateStart,
  dateCreateEnd,
  dateCheckinStart,
  dateCheckinEnd,
  dateCheckoutStart,
  dateCheckoutEnd,
  numberLocator,
  reservationState,
  payState,
  otaCode,
  client,
  chain,
  currency,
  concept,
  crs,
  externalLocator
) => ({
  type: ConstantsPayments.GET_RESERVATIONS,
  hotel,
  channel,
  dateCreateStart,
  dateCreateEnd,
  dateCheckinStart,
  dateCheckinEnd,
  dateCheckoutStart,
  dateCheckoutEnd,
  numberLocator,
  reservationState,
  payState,
  otaCode,
  client,
  chain,
  currency,
  concept,
  crs,
  externalLocator
});

export const actionGetReservationsResult = (result, msg, reservations) => ({
  type: ConstantsPayments.GET_RESERVATIONS_RESULT,
  result,
  msg,
  reservations,
});

export const actionAddEmptyPayReservation = (reservationId) => ({
  type: ConstantsPayments.ADD_EMPTY_PAY_RESERVATION,
  reservationId,
});

export const actionRemoveNewPayReservation = (reservationId, payId) => ({
  type: ConstantsPayments.REMOVE_NEW_PAY_RESERVATION,
  reservationId,
  payId,
});

export const actionAddPayReservation = (
  payType,
  actionPay,
  transaction,
  statePay,
  date,
  amount,
  reservationId,
  payId,
) => ({
  type: ConstantsPayments.ADD_PAY_RESERVATION,
  payType,
  actionPay,
  reservationId,
  transaction,
  statePay,
  date,
  amount,
  payId,
});

export const actionAddPayReservationResult = (result, msg, pay, newPayId) => ({
  type: ConstantsPayments.ADD_PAY_RESERVATION_RESULT,
  result,
  msg,
  pay,
  newPayId,
});

export const actionRemovePayReservation = (reservationId, payId) => ({
  type: ConstantsPayments.REMOVE_PAY_RESERVATION,
  reservationId,
  payId,
});

export const actionRemovePayReservationResult = (result, msg, reservationId, payId) => ({
  type: ConstantsPayments.REMOVE_PAY_RESERVATION_RESULT,
  result,
  msg,
  reservationId,
  payId,
});

export const actionSetReservation = (reservation) => ({
  type: ConstantsPayments.SET_RESERVATION,
  reservation,
});

export const actionGetChargesReservation = (reservationId, reservationLocator) => ({
  type: ConstantsPayments.GET_CHARGES_RESERVATION,
  reservationId,
  reservationLocator,
});

export const actionGetChargesReservationResult = (result, msg, reservationId, charges) => ({
  type: ConstantsPayments.GET_CHARGES_RESERVATION_RESULT,
  result,
  msg,
  reservationId,
  charges,
});

export const actionPayChargeReservation = (cobroId) => ({
  type: ConstantsPayments.PAY_CHARGE_RESERVATION,
  cobroId,
});

export const actionPayChargeReservationResult = (result, msg) => ({
  type: ConstantsPayments.PAY_CHARGES_RESERVATION_RESULT,
  result,
  msg,
});

export const actionSetExpandedKeyReservation = (reservationId, expanded) => ({
  type: ConstantsPayments.SET_EXPANDED_KEY_RESERVATION,
  reservationId,
  expanded,
});
