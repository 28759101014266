import ConstantsPay from './payConstants';

export const actionGetDataPayment = (token, identification) => ({
  type: ConstantsPay.GET_DATA_PAYMENT,
  token,
  identification,
});

export const actionGetDataPaymentResult = (payment) => ({
  type: ConstantsPay.GET_DATA_PAYMENT_RESULT,
  mensaje: payment.mensaje,
  code: payment.code,
  url: payment.url,
});
