import React, { useEffect } from 'react';
import {
  Modal,
  Select,
  Typography,
  Spin, Row, message
} from 'antd';
import PropTypes from 'prop-types';
import 'antd/dist/antd.less';
import {
  CloseCircleOutlined,
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import SecureFields from '../tpvManual/SecureFields';
import Colors from '../../../assets/colors/Colors';
import PaymentResultModal from '../tpvManual/paymentResult/PaymentResultModal';
import { useSendTransactionForPaymentApiMutation } from '../../../services/tpvManual';

const { Text } = Typography;
const { Option } = Select;

const styles = {
  div: {
    width: '100%',
    backgroundColor: Colors.BACKGROUND_COLOR_3,
    paddingLeft: 46,
    paddingTop: 20,
    paddingRight: 46,
    paddingBottom: 20,
  },
  row: {
    marginBottom: 40,
  },
  col: { marginRight: 100 },
  textTitle1: {
    fontSize: 22,
    color: Colors.TEXT_COLOR,
    fontFamily: 'Roboto-Medium',
    verticalAlign: 'middle',
    marginRight: 4,
  },
  textTitle2: {
    fontSize: 22,
    color: Colors.TEXT_COLOR,
    fontFamily: 'Roboto-Bold',
    fontWeight: '500',
    verticalAlign: 'middle',
  },
  textPay: {
    fontSize: 16,
    color: Colors.TEXT_COLOR,
    fontFamily: 'Roboto-Medium',
    verticalAlign: 'middle',
    fontWeight: '500',
    paddingLeft: 16,
    paddingRight: 16,
  },
  bluePoint: {
    width: 10, height: 10, backgroundColor: Colors.PRIMARY_COLOR, borderRadius: 5,
  },
  blueLine: {
    width: '100%', height: 2, backgroundColor: Colors.PRIMARY_COLOR,
  },
  greyPoint: {
    width: 10, height: 10, backgroundColor: Colors.PROGRESS_GREY_LINE, borderRadius: 5,
  },
  greyLine: {
    width: '100%', height: 2, backgroundColor: Colors.PROGRESS_GREY_LINE,
  },
  textValueGreenColumn: {
    fontSize: 14,
    fontFamily: 'Roboto-Regular',
    color: Colors.TEXT_GREEN,
  },
  textValueRedColumn: {
    fontSize: 14,
    fontFamily: 'Roboto-Regular',
    color: Colors.TEXT_RED,
  },
  textValueYellowColumn: {
    fontSize: 14,
    fontFamily: 'Roboto-Regular',
    color: Colors.TEXT_YELLOW,
  },
  buttonLink: {
    fontFamily: 'Roboto-Regular',
    fontSize: 14,
  },
  iconSave: {
    color: Colors.TEXT_GREEN,
  },
  iconEdit: {
    color: Colors.PRIMARY_COLOR,
  },
  iconDelete: {
    color: Colors.TEXT_RED,
  },
  buttonPrimary: {
    fontFamily: 'Roboto-Bold',
    fontSize: 14,
    marginTop: 34,
  },
  select: {
    width: 100,
    fontFamily: 'Roboto-Regular',
    fontSize: 14,
    borderRadius: 4,
    borderWidth: 1,
    borderColor: 'white',
  },
  select2: {
    width: 160,
    fontFamily: 'Roboto-Regular',
    fontSize: 14,
    borderRadius: 4,
    borderWidth: 1,
    borderColor: 'white',
  },
  textOptionValue: {
    fontSize: 14,
    color: Colors.INPUT_COLOR,
    fontFamily: 'Roboto-Regular',
  },
  input: {
    borderRadius: 4,
    borderWidth: 1,
    borderColor: 'white',
    fontFamily: 'Roboto-Regular',
    fontSize: 14,
    paddingTop: 6,
  },
  textTitlePopconfirm: {
    fontSize: 16,
    fontFamily: 'Roboto-Medium',
  },
  textOkButtonPopconfirm: {
    fontFamily: 'Roboto-Bold',
    fontWeight: '600',
    fontSize: 16,
    color: 'white',
  },
  textCancelButtonPopconfirm: {
    fontFamily: 'Roboto-Regular',
    fontSize: 16,
  },
  icon: {
    color: Colors.PRIMARY_COLOR,
  },
  inputModal: {
    width: '100%',
    borderRadius: 4,
    borderColor: Colors.BORDER_COLOR,
    fontFamily: 'Roboto-Regular',
    fontSize: 16,
  },
  selectModal: {
    fontFamily: 'Roboto-Regular',
    fontSize: 16,
    borderRadius: 4,
    borderWidth: 1,
    borderColor: 'white',
  },
  buttonPrimaryModal: {
    fontFamily: 'Roboto-Bold',
    fontWeight: '600',
    fontSize: 16,
    width: '100%',
  },
};

export default function TpvManualModal(props) {
  const { t } = useTranslation();
  const { closeModal, reservation } = props;

  const [
    sendTransaction,
    {
      data: dataSendTransaction,
      isLoading: isLoadingSendTransaction,
      isSuccess: isSuccessSendTransaction,
      error: errorSendTransaction,
      reset: resetSendTransaction,
    }
  ] = useSendTransactionForPaymentApiMutation({});

  const handleForm = (cardData) => {
    if (cardData !== null) {
      sendTransaction(cardData);
    }
  };

  const applyFilters = useSelector((state) => state.reducerFilters);

  const hotelsOptions = [];
  applyFilters.hotels.forEach((i) => {
    hotelsOptions.push(
      <Option key={i.value} value={i.value}>
        <Text style={styles.textOptionValue}>
          {i.label}
        </Text>
      </Option>,
    );
  });

  const currencyOptions = [];
  applyFilters.currencies.forEach((i) => {
    if (i.currency !== 'CUP') {
      currencyOptions.push(
        <Option key={i.currency} value={i.currency}>
          <Text style={styles.textOptionValue}>
            {i.label}
          </Text>
        </Option>,
      );
    }
  });

  return (
    <Row style={{ marginTop: 20 }} justify="center">
      {isSuccessSendTransaction || errorSendTransaction ? (
        <PaymentResultModal
          visible
          paymentData={isSuccessSendTransaction ? dataSendTransaction : errorSendTransaction}
          isSuccess={isSuccessSendTransaction}
          closeModal={() => {
            resetSendTransaction();
            closeModal();
          }}
        />
      ) : (
        <Modal
          title={(
            <Text style={styles.textTitlePopconfirm}>
              {t('text_button_pay_tpv_manual')}
            </Text>
        )}
          destroyOnClose
          open
          footer={null}
          onCancel={closeModal}
          closeIcon={<CloseCircleOutlined style={styles.icon} />}
        >
          <Spin spinning={isLoadingSendTransaction} size="large">

            <SecureFields
              config={{
                merchantID: process.env.REACT_APP_PCI_PROXY_MERCHANT_ID,
                formFields: {
                  localizator: {
                    initialValue: reservation.reservation_id,
                    blocked: true
                  },
                  hotel: {
                    initialValue: reservation.hotel,
                    blocked: true
                  },
                  currency: {
                    initialValue: reservation.currency,
                    blocked: true
                  },
                  amount: {
                    initialValue: reservation.gross,
                    blocked: false
                  },
                },
                fields: {
                  cardNumber: {
                    placeholderElementId: 'card-number',
                    inputType: 'tel'
                  },
                  cvv: {
                    placeholderElementId: 'cvv-number',
                    inputType: 'tel'
                  },
                },
                options: {}
              }}
              handleForm={handleForm}
              hotelsOptions={hotelsOptions}
              currencyOptions={currencyOptions}
              showDatesFields={false}
            />
          </Spin>

        </Modal>
      )}
    </Row>
  );
}

TpvManualModal.defaultProps = {
  closeModal: undefined,
  reservation: undefined
};

TpvManualModal.propTypes = {
  closeModal: PropTypes.func,
  reservation: PropTypes.shape({
    reservation_id: PropTypes.string,
    reserva: PropTypes.string,
    gross: PropTypes.string,
    currency: PropTypes.string,
    total_pagado: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]),
    hotel: PropTypes.string,
    usuario: PropTypes.string,
    email: PropTypes.string,
    pendiente_pago: PropTypes.string,

  }),
};
