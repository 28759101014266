const ConstantsPayments = {
  GET_FILTERS: 'GET_FILTERS',
  GET_FILTERS_RESULT: 'GET_FILTRES_RESULT',
  GET_RESERVATIONS: 'GET_RESERVATIONS',
  GET_RESERVATIONS_RESULT: 'GET_RESERVATIONS_RESULT',
  ADD_EMPTY_PAY_RESERVATION: 'ADD_EMPTY_PAY_RESERVATION',
  REMOVE_NEW_PAY_RESERVATION: 'REMOVE_NEW_PAY_RESERVATION',
  ADD_PAY_RESERVATION: 'ADD_PAY_RESERVATION',
  ADD_PAY_RESERVATION_RESULT: 'ADD_PAY_RESERVATION_RESULT',
  REMOVE_PAY_RESERVATION: 'REMOVE_PAY_RESERVATION',
  REMOVE_PAY_RESERVATION_RESULT: 'REMOVE_PAY_RESERVATION_RESULT',
  SET_RESERVATION: 'SET_RESERVATION',
  GET_CHARGES_RESERVATION: 'GET_CHARGES_RESERVATION',
  GET_CHARGES_RESERVATION_RESULT: 'GET_CHARGES_RESERVATION_RESULT',
  PAY_CHARGE_RESERVATION: 'PAY_CHARGE_RESERVATION',
  PAY_CHARGES_RESERVATION_RESULT: 'PAY_CHARGES_RESERVATION_RESULT',
  SET_EXPANDED_KEY_RESERVATION: 'SET_EXPANDED_KEY_RESERVATION',
};

export default ConstantsPayments;
