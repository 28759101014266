import React from 'react';
import { Row, Col, Typography } from 'antd';
import PropTypes from 'prop-types';
// import { useTranslation } from 'react-i18next';

export default function SendPrebillProcessBody(props) {
  const { Text } = Typography;

  const { bulkAction} = props;

  const title = `ID del proceso: ${bulkAction?.id} - Enviar prefactura`;

  return (
    <div>
      <Row>
        <Col>
          <Text className="font-bold">{title}</Text>
        </Col>
      </Row>
      <Row>
        <Col>
          <Text className="font-bold">{'Fecha de inicio: '}</Text>
          <Text>{bulkAction?.created_at}</Text>
        </Col>
      </Row>
      <Row className="mt-3 mb-1">
        <Col>
          <Text className="font-bold">Información de la prefactura:</Text>
        </Col>
      </Row>
      <Row>
        <Col>
          <Text className="font-bold">{'Mensaje '}</Text>
          <Text>{bulkAction?.datos.mensaje}</Text>
        </Col>
      </Row>
      <Row>
        <Col>
          <Text className="font-bold">{'ID Job: '}</Text>
          <Text>{bulkAction?.datos.id_job}</Text>
        </Col>
      </Row>
      {bulkAction?.datos.nombre_empresa ? (
        <Row>
          <Col>
            <Text className="font-bold">{'Empresa: '}</Text>
            <Text>{bulkAction?.datos?.nombre_empresa}</Text>
          </Col>
        </Row>
      ) : null}
    </div>
  );
}

SendPrebillProcessBody.defaultProps = {
  bulkAction: {},
};

SendPrebillProcessBody.propTypes = {
  bulkAction: PropTypes.shape({
    id: PropTypes.string,
    id_usuario: PropTypes.string,
    tipo: PropTypes.string,
    estado: PropTypes.string,
    created_at: PropTypes.string,
    updated_at: PropTypes.string,
    datos: PropTypes.shape({
      mensaje: PropTypes.string,
      id_job: PropTypes.string || PropTypes.number,
      nombre_empresa: PropTypes.string,
    }),
  }),
};
