import {
  call, takeEvery, put,
} from 'redux-saga/effects';
import qs from 'qs';
import { actionLoading } from '../store/reducers/loading/loadingActions';
import AxiosInstance from './axiosConfig';
import ConstantsPay from '../store/reducers/pay/payConstants';
import { actionGetDataPaymentResult } from '../store/reducers/pay/payActions';

function getDataPaymentApi(values) {
  return AxiosInstance.post(
    '/payment/procesado',
    qs.stringify({
      token: values.token,
      identificador: values.identification,
    })
  )
    .then((response) => response)
    .catch((error) => error.response);
}

function* generateGetDataPayment(values) {
  try {
    const response = yield call(getDataPaymentApi, values);
    if (response.status === 200) {
      const { data } = response;
      const result = {
        mensaje: data.mensaje,
        code: data.code === 0,
        url: data.url,
      };
      yield put(actionGetDataPaymentResult(result));
    } else {
      // Considera despachar una acción diferente para manejar errores de respuesta no exitosos
      console.log('Error en la respuesta:', response);
    }
  } catch (error) {
    console.log('Error al realizar la solicitud:', error);
    // Despacha una acción específica para manejar errores de la saga
  } finally {
    yield put(actionLoading(false));
  }
}

export default function* paySagas() {
  yield takeEvery(ConstantsPay.GET_DATA_PAYMENT, generateGetDataPayment);
}
