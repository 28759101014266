import ConstantsLogin from './loginConstants';

export const actionLogin = (user, password) => ({
  type: ConstantsLogin.LOGIN,
  user,
  password,
});

export const actionLoginResult = (result, msg) => ({
  type: ConstantsLogin.LOGIN_RESULT,
  result,
  msg,
});

export const actionClearLoginResult = () => ({
  type: ConstantsLogin.CLEAR_LOGIN_RESULT,
});

export const actionForgotPassword = (email) => ({
  type: ConstantsLogin.FORGOT_PASSWORD,
  email,
});

export const actionForgotPasswordResult = (result, msg) => ({
  type: ConstantsLogin.FORGOT_PASSWORD_RESULT,
  result,
  msg,
});

export const actionClearForgotPasswordResult = () => ({
  type: ConstantsLogin.CLEAR_FORGOT_PASSWORD_RESULT,
});

export const actionLoginByHash = (hash) => ({
  type: ConstantsLogin.LOGIN_BY_HASH,
  hash
});

export const actionLoginByHashResult = (result, msg) => ({
  type: ConstantsLogin.LOGIN_BY_HASH_RESULT,
  result,
  msg,
});

export const actionResetPassword = (hash, password) => ({
  type: ConstantsLogin.RESET_PASSWORD,
  hash,
  password
});

export const actionResetPasswordResult = (result, msg) => ({
  type: ConstantsLogin.RESET_PASSWORD_RESULT,
  result,
  msg,
});

export const actionClearResetPasswordResult = () => ({
  type: ConstantsLogin.CLEAR_RESET_PASSWORD_RESULT,
});
