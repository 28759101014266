import { DownOutlined, PlusOutlined } from '@ant-design/icons';
import {
  Row, Dropdown, Col, Button, Space
} from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { getWritePermissionByTab } from '../../../../utils/Utils';
import ConstantsRoutes from '../../../../constants/ConstantsRoutes';
import { SEND_PRE_BILL_KEY, SEND_BILL_KEY } from '../../../../constants/ConstantsAsyncProcess';

export default function ConciliationActionButtons({
  hasSelected, setShowModalNewPreconciliation, clearAllFilters, conciliations, asyncProcessClickHandler
}) {
  const { t } = useTranslation();

  const items = [
    {
      label: 'Enviar prefacturas',
      key: SEND_PRE_BILL_KEY,
      disabled: !hasSelected,
    },
    {
      label: 'Enviar facturas',
      key: SEND_BILL_KEY,
      disabled: !hasSelected,
    },

  ];

  const handleMenuClick = (e) => {
    console.log('click', e);
    asyncProcessClickHandler(e.key);
  };

  const menuProps = {
    items,
    onClick: handleMenuClick,
  };

  return (
    <Row align="middle" justify="end">
      {getWritePermissionByTab(ConstantsRoutes.CONCILIATIONS) ? (
        <Col className="mr-[16px] ">
          <Dropdown menu={menuProps} trigger={['click']}>
            <Button size="small" className="px-3 py-2" style={{ height: 'auto'}}>
              <Space>
                Procesos asíncronos
                <DownOutlined />
              </Space>
            </Button>
          </Dropdown>
        </Col>
      ) : <div />}
      {getWritePermissionByTab(ConstantsRoutes.CONCILIATIONS) ? (
        <Col className="mr-[16px]">
          <Button
            type="primary"
            icon={<PlusOutlined />}
            size="small"
            disabled={!getWritePermissionByTab(ConstantsRoutes.CONCILIATIONS)}
            onClick={() => {
              setShowModalNewPreconciliation(true);
            }}
            className="btn btn-h-auto text text-w-bold px-3 py-2"
          >
            {t('text_button_new_preconciliation')}
          </Button>
        </Col>
      ) : <div />}
      <Col className="mr-[16px]">
        <Button
          onClick={clearAllFilters}
          size="small"
          disabled={conciliations?.length}
          className="btn btn-h-auto text text-w-bold px-3 py-2"
        >
          {t('text_button_clear_filters')}
        </Button>
      </Col>
    </Row>
  );
}

ConciliationActionButtons.defaultProps = {
  hasSelected: false,
  setShowModalNewPreconciliation: () => {},
  clearAllFilters: () => { },
  asyncProcessClickHandler: () => {},
  conciliations: [],
};

ConciliationActionButtons.propTypes = {
  hasSelected: PropTypes.bool,
  setShowModalNewPreconciliation: PropTypes.func,
  clearAllFilters: PropTypes.func,
  asyncProcessClickHandler: PropTypes.func,
  conciliations: PropTypes.arrayOf(PropTypes.shape({})),
};
