import React, { useEffect } from 'react';
import {
  BackTop,
  Col,
  message,
  Row,
  Spin,
} from 'antd';
import 'antd/dist/antd.less';
import NewInvitationForm from './NewInvitationForm';

import {
  useGetFiltersInvitationsApiQuery,
  useCreateInvitationApiMutation
} from '../../../services/invitations';

export default function NewInvitationView() {
  const { isFetching } = useGetFiltersInvitationsApiQuery();

  const securePaymentData = 0;
  const [
    createInvitation,
    {
      data: createInvitationResult,
      isLoading: isLoadingCreateInvitation,
      error: errorCreateInvitation
    }
  ] = useCreateInvitationApiMutation();

  useEffect(() => {
    if (errorCreateInvitation) {
      message.error(errorCreateInvitation.message);
    }
  }, [errorCreateInvitation]);

  useEffect(() => {
    if (createInvitationResult?.message) {
      message.success(createInvitationResult.message);
    }
  }, [createInvitationResult]);

  const isLoading = isLoadingCreateInvitation || isFetching;

  const handleOk = (values) => {
    createInvitation(values);
  };

  return (
    <Spin
      spinning={isLoading}
      size="large"
    >
      <BackTop />
      <Row style={{ marginTop: 50 }} justify="center">
        <Col xs={18} md={10}>
          <NewInvitationForm 
            handleSubmit={handleOk}
            securePaymentData={securePaymentData}
          />
        </Col>

      </Row>
    </Spin>
  );
}
