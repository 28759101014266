import ConstantsTpvManual from './tpvManualConstants';

export const reducerTpvManual = (state = {
  result: false,
  msg: null,
}, action = {}) => {
  switch (action.type) {
    case ConstantsTpvManual.SEND_TRANSACTION_RESULT:
      return action;
    default:
      return state;
  }
};

export const reducerGetFiltersTpvManualResult = (state = {
  result: false,
  msg: null,
  hotels: [],
  currencies: []
}, action = {}) => {
  switch (action.type) {
    case ConstantsTpvManual.GET_FILTERS_TPV_MANUAL_RESULT:
      return {
        ...state,
        result: action.result,
        msg: action.msg,
        hotels: action.filters.hotels,
        currencies: action.filters.currencies
      };
    default:
      return state;
  }
};
