import React, { useEffect, useState } from 'react';
import {
  BackTop,
  Col,
  message,
  Row,
  Spin,
} from 'antd';
import 'antd/dist/antd.less';
import queryString from 'query-string';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import NewInvitationForm from './NewInvitationForm';
import {
  useGetFiltersInvitationsApiQuery,
  useCreateInvitationApiMutation
} from '../../../services/invitations';
import { actionGetDataPayment } from '../../../store/reducers/pay/payActions';
import actionClearData from '../../../store/reducers/storeGlobalActions';
import { actionLoading } from '../../../store/reducers/loading/loadingActions';

export default function NewTpvSecureManual() {
  const { isFetching } = useGetFiltersInvitationsApiQuery();
  const dispatch = useDispatch();
  const securePaymentData = 1;
  const paymentData = useSelector((state) => state.reducerDataPayment);
  const loading = useSelector((state) => state.reducerLoading);
  const [
    createInvitation,
    {
      data: createInvitationResult,
      isLoading: isLoadingCreateInvitation,
      error: errorCreateInvitation
    }
  ] = useCreateInvitationApiMutation();
  useEffect(() => {
    if (createInvitationResult) {
      dispatch(actionLoading(true));
      dispatch(actionGetDataPayment(createInvitationResult.id_reserva, createInvitationResult.id_inserccion));
    }
  }, [createInvitationResult, dispatch]);
  useEffect(() => {
    if (typeof paymentData.code === 'boolean' && paymentData.code) {
      window.open(paymentData.url, '_blank');
      dispatch(actionClearData());
    }
  }, [paymentData, dispatch]);
  useEffect(() => {
    if (errorCreateInvitation) {
      message.error(errorCreateInvitation.message);
    }
  }, [errorCreateInvitation]);

  useEffect(() => {
    if (createInvitationResult?.message) {
      message.success(createInvitationResult.message);
    }
  }, [createInvitationResult]);

  const isLoading = isLoadingCreateInvitation || isFetching;

  const handleOk = (values) => {
    createInvitation(values);
  };

  return (
    <Spin
      spinning={isLoading}
      size="large"
    >
      <BackTop />
      <Row style={{ marginTop: 50 }} justify="center">
        <Col xs={18} md={10}>
          <NewInvitationForm 
            handleSubmit={handleOk}
            securePaymentData={securePaymentData}
          />
        </Col>

      </Row>
    </Spin>
  );
}
