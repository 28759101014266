import decode from 'jwt-decode';
import Cookies from 'js-cookie';
import ConstantsValue from '../constants/ConstantsValue';
import ConstantsRoutes from '../constants/ConstantsRoutes';
import ConstantsInvitationStates from '../constants/ConstantsInvitationStates';
import i18n from '../assets/language/i18n';
import ConstantsOtaCode from '../constants/ConstantsOtaCode';
import ConstantsCurrency from '../constants/ConstantsCurrency';

export function validateMail(email) {
  return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/.test(email);
}

export function getDevolutionPermission() {
  const jwt = Cookies.get(ConstantsValue.JWT);
  const decoded = decode(jwt);
  return decoded.userInfo.permisos.devoluciones;
}

export function validateEmail(email) {
  // Expresión regular para validar un único correo electrónico
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return regex.test(email);
}

export function getIsAdminPermission() {
  const jwt = Cookies.get(ConstantsValue.JWT);
  const decoded = decode(jwt);
  return decoded.userInfo.es_admin === '1';
}

export function canCollectVirtualCardPermission() {
  const jwt = Cookies.get(ConstantsValue.JWT);
  const decoded = decode(jwt);
  return decoded.userInfo.permisos.cobro_tarjeta_virtual.escritura;
}

function getPermissionByTab(jwt, tab, typeOfPermission) {
  try {
    const decoded = decode(jwt);
    switch (tab) {
      case ConstantsRoutes.PAYMENTS:
        return decoded.userInfo.permisos.reservas[typeOfPermission];
      case ConstantsRoutes.TPVS:
        return decoded.userInfo.permisos.tpv[typeOfPermission];
      case ConstantsRoutes.INVITATIONS:
        return decoded.userInfo.permisos.enlaces_pago[typeOfPermission];
      case ConstantsRoutes.PRE_CONCILIATION:
        return decoded.userInfo.permisos.preconciliacion[typeOfPermission];
      case ConstantsRoutes.CONCILIATIONS:
        return decoded.userInfo.permisos.conciliacion[typeOfPermission];
      case ConstantsRoutes.CONCILIATION:
        return decoded.userInfo.permisos.conciliacion[typeOfPermission];
      case ConstantsRoutes.TPV_MANUAL:
        return decoded.userInfo.permisos.cobroManual[typeOfPermission];
      case ConstantsRoutes.TPV_TRANSACTIONS:
        return decoded.userInfo.permisos.transacciones[typeOfPermission];
      case ConstantsRoutes.COLLECTION_REQUESTS:
        return decoded.userInfo.permisos.solicitudes_cobro[typeOfPermission];
      case ConstantsRoutes.PAYMENT_REQUESTS:
        return decoded.userInfo.permisos.solicitudes_pago[typeOfPermission];
      default:
        return false;
    }
  } catch (error) {
    return false;
  }
}

export function getWritePermissionByTab(tab) {
  return getPermissionByTab(Cookies.get(ConstantsValue.JWT), tab, 'escritura');
}

export function getReadPermissionByTab(tab) {
  return getPermissionByTab(Cookies.get(ConstantsValue.JWT), tab, 'lectura');
}

export function getStatePayInvitation(value) {
  switch (value) {
    case ConstantsInvitationStates.COBRADO:
      return i18n.t('text_charged');
    case ConstantsInvitationStates.CADUCADO:
      return i18n.t('text_caducado');
    case ConstantsInvitationStates.CANCELADO:
      return i18n.t('text_cancelate');
    case ConstantsInvitationStates.PENDIENTE_PAGO:
      return i18n.t('text_pending_pay');
    case ConstantsInvitationStates.ERROR_PAGO:
      return i18n.t('text_error_pay');
    default:
      return i18n.t('text_error_pay');
  }
}

export function getOtaCode(value) {
  switch (value) {
    case ConstantsOtaCode.ADG_COLLECTC:
      return i18n.t('text_adg_collect');
    case ConstantsOtaCode.HOTEL_COLLECT:
      return i18n.t('text_hotel_collect');
    default:
      return '';
  }
}

export function getCurrencyCode(value) {
  switch (value) {
    case ConstantsCurrency.USD:
      return '$';
    case ConstantsCurrency.EUR:
      return '€';
    case ConstantsCurrency.CUP:
      return 'CUP';
    default:
      return '€';
  }
}

export function base64Decode(base64) {
  const binaryString = window.atob(base64); // el pdf viene en base64
  const len = binaryString.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; i += 1) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return bytes;
}

export function downloadFile(data, name) {
  console.log('data', data);
  const bytes = base64Decode(data);

  const url = window.URL.createObjectURL(new Blob([bytes]));
  const link = document.createElement('a');

  link.setAttribute('download', name);
  link.href = url;

  document.body.appendChild(link);

  link.click();
  return {message: i18n.t('success_download_file')};
}

export const getDefaultView = () => {
  if (getReadPermissionByTab(ConstantsRoutes.PAYMENTS)) {
    return ConstantsRoutes.PAYMENTS;
  }
  if (getReadPermissionByTab(ConstantsRoutes.TPVS)) {
    return ConstantsRoutes.TPVS;
  }
  if (getReadPermissionByTab(ConstantsRoutes.INVITATIONS)) {
    return ConstantsRoutes.INVITATIONS;
  }
  if (getReadPermissionByTab(ConstantsRoutes.TPV_TRANSACTIONS)) {
    return ConstantsRoutes.TPV_TRANSACTIONS;
  }
  if (getReadPermissionByTab(ConstantsRoutes.PRE_CONCILIATION)) {
    return ConstantsRoutes.PRE_CONCILIATION;
  }
  if (getReadPermissionByTab(ConstantsRoutes.CONCILIATIONS)) {
    return ConstantsRoutes.CONCILIATIONS;
  }
  if (getReadPermissionByTab(ConstantsRoutes.COLLECTION_REQUESTS)) {
    return ConstantsRoutes.COLLECTION_REQUESTS;
  }
  if (getReadPermissionByTab(ConstantsRoutes.PAYMENT_REQUESTS)) {
    return ConstantsRoutes.PAYMENT_REQUESTS;
  }
  return '/login';
};

export const defaultRequiredRules = (messageError) => ([
  {
    required: true,
    message: messageError,
  },
]);
