import React, { useEffect, useState } from 'react';
import {
  BackTop,
  Button,
  Col,
  Input,
  message,
  Modal,
  Row,
  Select,
  Space,
  Spin,
  Table,
  Tooltip,
  Typography
} from 'antd';
import {
  CommentOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
  FileExcelOutlined,
  ReadOutlined,
  SearchOutlined
} from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import 'antd/dist/antd.less';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import * as XLSX from 'xlsx';
import i18n from '../../../assets/language/i18n';
import FilterPreConciliation from './FilterPreConciliation';
import Colors from '../../../assets/colors/Colors';
import ConstantsDate from '../../../constants/ConstantsDate';
import { actionLoading } from '../../../store/reducers/loading/loadingActions';
import {
  actionApplyRecalificationReservations,
  actionApplyRecalificationReservationsResult,
  actionChangeStatusReservations,
  actionChangeStatusReservationsResult,
  actionDeleteReclasificationReservations,
  actionDeleteReclasificationReservationsResult,
  actionGetCommentPreConciliation,
  actionSendCommentReservations,
  actionSendCommentReservationsResult
} from '../../../store/reducers/preConciliation/preConciliationActions';
import ConstantsReservationStatus from '../../../constants/ConstantsReservationStatus';
// import ResumePreConciliation from './ResumePreConciliation';
import CommentsPreConciliation from './CommentsPreConciliation';
import { getWritePermissionByTab } from '../../../utils/Utils';
import ConstantsRoutes from '../../../constants/ConstantsRoutes';

const { Text } = Typography;
const { Option } = Select;
const { TextArea } = Input;

const styles = {
  textValuePending: {
    fontSize: 14,
    fontFamily: 'Roboto-Bold',
    color: Colors.PENDING,
  },
  textValueValidate: {
    fontSize: 14,
    fontFamily: 'Roboto-Bold',
    color: Colors.VALIDATE,
  },
  textValueException: {
    fontSize: 14,
    fontFamily: 'Roboto-Bold',
    color: Colors.EXCEPTION,
  },
  textValueExclude: {
    fontSize: 14,
    fontFamily: 'Roboto-Bold',
    color: Colors.EXCLUDE,
  },
  textValuePreConciliate: {
    fontSize: 14,
    fontFamily: 'Roboto-Bold',
    color: Colors.PRE_CONCILIATE,
  },
  textValueConciliate: {
    fontSize: 14,
    fontFamily: 'Roboto-Bold',
    color: Colors.CONCILIATE,
  },
  textValueFacture: {
    fontSize: 14,
    fontFamily: 'Roboto-Bold',
    color: Colors.FACTURE,
  },
  textValueOther: {
    fontSize: 14,
    fontFamily: 'Roboto-Bold',
    color: Colors.OTHERS,
  },
  textValueHighPending: {
    fontSize: 14,
    fontFamily: 'Roboto-Bold',
    color: Colors.PENDING,
    backgroundColor: Colors.ICON_COLOR,
    padding: 0,
  },
  textValueHighValidate: {
    fontSize: 14,
    fontFamily: 'Roboto-Bold',
    color: Colors.VALIDATE,
    backgroundColor: Colors.ICON_COLOR,
    padding: 0,
  },
  textValueHighException: {
    fontSize: 14,
    fontFamily: 'Roboto-Bold',
    color: Colors.EXCEPTION,
    backgroundColor: Colors.ICON_COLOR,
    padding: 0,
  },
  textValueHighExclude: {
    fontSize: 14,
    fontFamily: 'Roboto-Bold',
    color: Colors.EXCLUDE,
    backgroundColor: Colors.ICON_COLOR,
    padding: 0,
  },
  textValueHighPreConciliate: {
    fontSize: 14,
    fontFamily: 'Roboto-Bold',
    color: Colors.PRE_CONCILIATE,
    backgroundColor: Colors.ICON_COLOR,
    padding: 0,
  },
  textValueHighConciliate: {
    fontSize: 14,
    fontFamily: 'Roboto-Bold',
    color: Colors.CONCILIATE,
    backgroundColor: Colors.ICON_COLOR,
    padding: 0,
  },
  textValueHighFacture: {
    fontSize: 14,
    fontFamily: 'Roboto-Bold',
    color: Colors.FACTURE,
    backgroundColor: Colors.ICON_COLOR,
    padding: 0,
  },
  textValueHighOther: {
    fontSize: 14,
    fontFamily: 'Roboto-Bold',
    color: Colors.OTHERS,
    backgroundColor: Colors.ICON_COLOR,
    padding: 0,
  },
  input: {
    borderRadius: 4,
    borderWidth: 1,
    borderColor: 'white',
    fontSize: 14,
    width: 188,
    marginBottom: 8,
    display: 'block',
  },
  buttonPrimary: {
    fontFamily: 'Roboto-Bold',
    fontWeight: '600',
    fontSize: 16,
    width: 90,
  },
  buttonDefault: {
    fontFamily: 'Roboto-Regular',
    fontSize: 14,
    width: 130,
  },
  icon: {
    color: Colors.PRIMARY_COLOR,
  },
  textOptionValue: {
    fontSize: 14,
    color: Colors.INPUT_COLOR,
    fontFamily: 'Roboto-Regular',
  },
  textValuePayStatusCharge: {
    fontSize: 14,
    fontFamily: 'Roboto-Bold',
    color: Colors.PAY_CHARGE,
  },
  textValuePayStatusReturn: {
    fontSize: 14,
    fontFamily: 'Roboto-Bold',
    color: Colors.PAY_RETURN,
  },
  textValueAdgCollect: {
    fontSize: 14,
    fontFamily: 'Roboto-Bold',
    color: Colors.ADG_COLLECT,
  },
  textValueHotelCollect: {
    fontSize: 14,
    fontFamily: 'Roboto-Bold',
    color: Colors.HOTEL_COLLECT,
  },
  iconSave: {
    color: Colors.OTHERS,
  },
  buttonLink: {
    fontFamily: 'Roboto-Regular',
    fontSize: 14,
  },
};

let rows = [];
let commentRecalification = '';
let commentDeleteRecalification = '';

export default function PreConciliation() {
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [filteredInfo, setFilteredInfo] = useState(null);
  const [mySelectedRows, setSelectedRows] = useState([]);
  const [recalificationAction, setRecalificationAction] = useState(null);
  const [statusAction, setStatusAction] = useState(null);

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const reservations = useSelector((state) => state.reducerPreConciliationReservations);
  const applyFilters = useSelector((state) => state.reducerFiltersPreConciliation);
  const recalificationReservationsResult = useSelector(
    (state) => state.reducerApplyRecalificationReservationsResult
  );
  const changeStatusReservationsResult = useSelector(
    (state) => state.reducerChangeStatusReservationsResult
  );
  const sendCommentReservationsResult = useSelector(
    (state) => state.reducerPreConciliationSendCommentResult
  );
  const deleteReclasificationReservationsResult = useSelector(
    (state) => state.reducerDeleteReclasificationReservationsResult
  );
  const loading = useSelector((state) => state.reducerLoading);

  useEffect(() => {
    if (reservations.msg !== null && reservations.result === false) {
      message.error(reservations.msg);
    }

    if (recalificationReservationsResult.msg !== null
      && recalificationReservationsResult.result === false) {
      message.error(recalificationReservationsResult.msg);
      dispatch(actionApplyRecalificationReservationsResult(false, null));
    }
    if (recalificationReservationsResult.result === true) {
      message.success(recalificationReservationsResult.msg);
      dispatch(actionApplyRecalificationReservationsResult(false, null));
    }

    if (changeStatusReservationsResult.msg !== null
      && changeStatusReservationsResult.result === false) {
      message.error(changeStatusReservationsResult.msg);
      dispatch(actionChangeStatusReservationsResult(false, null));
    }
    if (changeStatusReservationsResult.result === true) {
      message.success(changeStatusReservationsResult.msg);
      rows = [];
      dispatch(actionChangeStatusReservationsResult(false, null));
    }

    if (sendCommentReservationsResult.msg !== null
      && sendCommentReservationsResult.result === false) {
      message.error(sendCommentReservationsResult.msg);
      dispatch(actionSendCommentReservationsResult(false, null));
    }
    if (sendCommentReservationsResult.result === true) {
      message.success(sendCommentReservationsResult.msg);
      rows = [];
      setSelectedRows([]);
      dispatch(actionSendCommentReservationsResult(false, null));
    }

    if (deleteReclasificationReservationsResult.msg !== null
      && deleteReclasificationReservationsResult.result === false) {
      message.error(deleteReclasificationReservationsResult.msg);
      dispatch(actionDeleteReclasificationReservationsResult(false, null));
    }
    if (deleteReclasificationReservationsResult.result === true) {
      message.success(deleteReclasificationReservationsResult.msg);
      rows = [];
      setSelectedRows([]);
      dispatch(actionDeleteReclasificationReservationsResult(false, null));
    }
  });

  const handleChange = (pagination, filters) => {
    setFilteredInfo(filters);
  };

  const clearAllFilters = () => {
    setFilteredInfo(null);
  };

  const getColumnSearchProps = (dataIndex, searchInput) => ({
    filterDropdown: ({
      setSelectedKeys, selectedKeys, confirm, clearFilters,
    }) => {
      let hintSearch;
      if (dataIndex === 'id_reserva') {
        hintSearch = t('hint_number_locator');
      } else if (dataIndex === 'localizador_agencia') {
        hintSearch = t('hint_number_locator');
      } else {
        hintSearch = t('text_headline');
      }
      return (
        <div style={{ padding: 8 }}>
          <Input
            ref={(node) => {
              searchInput = node;
            }}
            placeholder={hintSearch}
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={styles.input}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              style={styles.buttonPrimary}
            >
              {t('text_button_filter')}
            </Button>
            <Button onClick={() => handleReset(clearFilters)} size="small" style={styles.buttonDefault}>
              {t('text_button_clear')}
            </Button>
          </Space>
        </div>
      );
    },
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{ color: filtered ? Colors.PRIMARY_COLOR : Colors.ICON_COLOR }}
      />
    ),
    onFilter: (value, record) => (record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : ''),

    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.select());
      }
    },
    render: (text, record) => {
      if (dataIndex === 'id_reserva') {
        const recordStatus = record.validado;
        let styleHigh;
        let styleNormal;
        let tooltipStatus;
        if (recordStatus === ConstantsReservationStatus.Pending) {
          styleHigh = styles.textValueHighPending;
          styleNormal = styles.textValuePending;
          tooltipStatus = t('text_pending');
        } else if (recordStatus === ConstantsReservationStatus.Validate) {
          styleHigh = styles.textValueHighValidate;
          styleNormal = styles.textValueValidate;
          tooltipStatus = t('text_validate');
        } else if (recordStatus === ConstantsReservationStatus.Exception) {
          styleHigh = styles.textValueHighException;
          styleNormal = styles.textValueException;
          tooltipStatus = t('text_exception');
        } else if (recordStatus === ConstantsReservationStatus.Exclude) {
          styleHigh = styles.textValueHighExclude;
          styleNormal = styles.textValueExclude;
          tooltipStatus = t('text_exclude');
        } else if (recordStatus === ConstantsReservationStatus.PreConciliate) {
          styleHigh = styles.textValueHighPreConciliate;
          styleNormal = styles.textValuePreConciliate;
          tooltipStatus = t('text_pre_conciliate');
        } else if (recordStatus === ConstantsReservationStatus.Conciliate) {
          styleHigh = styles.textValueHighConciliate;
          styleNormal = styles.textValueConciliate;
          tooltipStatus = t('text_conciliate');
        } else if (recordStatus === ConstantsReservationStatus.Facture) {
          styleHigh = styles.textValueHighFacture;
          styleNormal = styles.textValueFacture;
          tooltipStatus = t('text_facture');
        } else {
          styleHigh = styles.textValueHighOther;
          styleNormal = styles.textValueOther;
        }
        if (searchedColumn === dataIndex) {
          return (
            <Tooltip title={tooltipStatus}>
              <Highlighter
                highlightStyle={styleHigh}
                unhighlightStyle={styleNormal}
                searchWords={[searchText]}
                autoEscape
                textToHighlight={text ? text.toString() : ''}
              />
            </Tooltip>
          );
        } return (
          <Tooltip title={tooltipStatus}>
            <Text style={styleNormal}>
              {text}
            </Text>
          </Tooltip>
        );
      }
      if (searchedColumn === dataIndex) {
        return (
          <Highlighter
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text ? text.toString() : ''}
          />
        );
      } return (
        <Text className="text">
          {text}
        </Text>
      );
    },
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const rowSelection = () => ({
    selectedRowKeys: rows,
    onChange: (selectedRowKeys, selectedRows) => {
      rows = selectedRowKeys;
      setSelectedRows(selectedRows);
    },
    getCheckboxProps: (record) => ({
      disabled: !getWritePermissionByTab(ConstantsRoutes.PRE_CONCILIATION),
      name: record.id_arreglo,
    })
  });

  const showConfirm = (value) => {
    const { confirm } = Modal;

    confirm({
      title: t('text_question_recalification'),
      icon: <ExclamationCircleOutlined />,
      content: <TextArea
        showCount
        allowClear
        placeholder={t('text_select_reservation')}
        rows={3}
        maxLength={130}
        onChange={(e) => {
          commentRecalification = e.target.value;
        }}
      />,
      onOk: () => {
        const res = [];
        mySelectedRows.forEach((item) => {
          res.push({
            id_arreglo: item.id_arreglo,
            codigo_ota: value,
            mensaje: commentRecalification,
          });
        });
        dispatch(actionLoading(true));
        dispatch(actionApplyRecalificationReservations(res));
      },
      onCancel: () => {
        setRecalificationAction(null);
      },
      afterClose: () => {
        setRecalificationAction(null);
      },
    });
  };

  const showConfirmChangeStatus = (value) => {
    const { confirm } = Modal;
    let inputComment = '';

    confirm({
      title: t('text_question_change_status'),
      icon: <ExclamationCircleOutlined />,
      content: <TextArea
        showCount
        allowClear
        placeholder={t('text_select_reservation')}
        rows={3}
        maxLength={130}
        onChange={(e) => {
          inputComment = e.target.value;
        }}
      />,
      onOk: () => {
        const res = [];
        mySelectedRows.forEach((item) => {
          res.push({
            id_arreglo: item.id_arreglo,
            validado: value,
            mensaje: inputComment,
          });
        });
        dispatch(actionLoading(true));
        dispatch(actionChangeStatusReservations(res));
      },
      onCancel: () => {
        setStatusAction(null);
      },
      afterClose: () => {
        setStatusAction(null);
      },
    });
  };

  const showAddComment = (row) => {
    const { confirm } = Modal;
    let inputComment = '';

    confirm({
      title: t('text_add_comment'),
      icon: <CommentOutlined />,
      content: <TextArea
        showCount
        allowClear
        placeholder={t('text_select_reservation')}
        rows={3}
        maxLength={130}
        onChange={(e) => {
          inputComment = e.target.value;
        }}
      />,
      onOk: () => {
        const res = [];
        if (row) {
          res.push({
            id_arreglo: row.id_arreglo,
            mensaje: inputComment,
          });
        } else {
          mySelectedRows.forEach((item) => {
            res.push({
              id_arreglo: item.id_arreglo,
              mensaje: inputComment,
            });
          });
        }
        dispatch(actionLoading(true));
        dispatch(actionSendCommentReservations(res));
      },
    });
  };

  const showDeleteReclasificationOta = () => {
    const { confirm } = Modal;

    confirm({
      title: t('text_question_delete_recl_ota'),
      icon: <CommentOutlined />,
      content: <TextArea
        showCount
        allowClear
        placeholder={t('text_select_reservation')}
        rows={3}
        maxLength={130}
        onChange={(e) => {
          commentDeleteRecalification = e.target.value;
        }}
      />,
      onOk: () => {
        const res = [];
        mySelectedRows.forEach((item) => {
          res.push({
            id_arreglo: item.id_arreglo,
            mensaje: commentDeleteRecalification,
          });
        });
        dispatch(actionLoading(true));
        dispatch(actionDeleteReclasificationReservations(res));
      },
    });
  };

  function exportToCsvReservations() {
    if (!reservations) {
      return [];
    }
    return reservations.map((item) => {
      const resultItem = {};
      resultItem.id_reserva = item.id_reserva;
      resultItem.localizador_agencia = item.localizador_agencia;
      resultItem.canal = item.canal;
      resultItem.estado_reserva = item.estado_reserva;
      resultItem.estado = item.estado;
      resultItem.hotel_code = item.hotel_code;
      resultItem.nombre_hotel = item.nombre_hotel;
      resultItem.cadena = item.cadena;
      resultItem.fecha_creacion_string = item.fecha_creacion_string;
      resultItem.fecha_checkin_string = item.fecha_checkin_string;
      resultItem.fecha_checkout_string = item.fecha_checkout_string;
      resultItem.noches = item.noches;
      resultItem.usuario = item.usuario;
      resultItem.habitaciones = item.habitaciones;
      resultItem.codigo_ota = item.codigo_ota;
      resultItem.codigo_cliente = item.codigo_cliente;
      resultItem.tipo_collect = item.tipo_collect;
      resultItem.importe_usd = Number(item.importe_usd) ?? Number(0.00);
      resultItem.moneda = item.moneda;
      resultItem.importe_eur = Number(item.importe_eur) ?? Number(0.00);
      resultItem.tasa_cambio = Number(item.tasa_cambio) ?? Number(0.00);
      resultItem.reclasificacion_ota = item.reclasificacion_ota;
      resultItem.id_transaccion = item.id_transaccion;
      return resultItem;
    });
  }

  const filtersSelected = filteredInfo || {};

  const headers = [
    [t('text_booking_locator'),
      t('text_external_booking_locator'),
      t('text_channel'),
      t('text_reservation_state'),
      t('text_pay_state'),
      t('text_hotel_code'),
      t('text_hotel'),
      t('text_chain'),
      t('text_create'),
      t('text_in'),
      t('text_out'),
      t('text_nights'),
      t('hint_user'),
      t('text_rooms'),
      t('text_ota_code'),
      t('text_client'),
      t('text_type'),
      t('text_amount_usd'),
      t('text_currency'),
      t('text_amount_eur'),
      t('text_change_rate'),
      t('text_ota_recalification_action'),
      t('text_id_transaction')
    ]
  ];

  const channelFilters = [];
  const agencyFilters = [];
  const statusFilters = [];
  const payStateFilters = [];
  const hotelFilters = [];
  const otaCodesFilters = [];
  const currencyFilters = [];

  const actionsReclasificationOptions = [];

  reservations.forEach((reservation) => {
    if (reservation?.canal !== null && reservation?.canal?.length > 0) {
      if (!channelFilters.some((channel) => channel.value === reservation.canal)) {
        channelFilters.push({
          text: reservation.canal,
          value: reservation.canal,
        });
      }
    }
    if (reservation?.codigo_cliente !== null && reservation?.codigo_cliente?.length > 0) {
      if (!agencyFilters.some(
        (agency) => agency.value === reservation.codigo_cliente,
      )) {
        agencyFilters.push({
          text: reservation.codigo_cliente,
          value: reservation.codigo_cliente,
        });
      }
    }
    if (reservation?.estado !== null && reservation?.estado?.length > 0) {
      if (!statusFilters.some(
        (status) => status.value === reservation.estado,
      )) {
        statusFilters.push({
          text: reservation.estado,
          value: reservation.estado,
        });
      }
    }
    if (reservation?.estado_reserva !== null && reservation?.estado_reserva?.length > 0) {
      if (!payStateFilters.some(
        (payState) => payState.value === reservation.estado_reserva,
      )) {
        payStateFilters.push({
          text: reservation.estado_reserva,
          value: reservation.estado_reserva,
        });
      }
    }
    if (reservation?.nombre_hotel !== null && reservation?.nombre_hotel?.length > 0) {
      if (!hotelFilters.some(
        (hotel) => hotel.value === reservation.hotel_code,
      )) {
        hotelFilters.push({
          text: reservation.nombre_hotel,
          value: reservation.hotel_code,
        });
      }
    }
    if (reservation?.codigo_ota !== null && reservation?.codigo_ota?.length > 0) {
      if (!otaCodesFilters.some(
        (otaCode) => otaCode.value === reservation.codigo_ota,
      )) {
        otaCodesFilters.push({
          text: reservation.tipo_ota ?? reservation.codigo_ota,
          value: reservation.codigo_ota,
        });
      }
    }
    if (reservation?.moneda !== null && reservation?.moneda?.length > 0) {
      if (!currencyFilters.some((currency) => currency.value === reservation.moneda
        && reservation.moneda?.length > 0)) {
        currencyFilters.push({
          text: reservation.moneda,
          value: reservation.moneda,
        });
      }
    }
  });

  channelFilters.sort((a, b) => a.text.localeCompare(b.text, i18n.language));
  agencyFilters.sort((a, b) => a.text.localeCompare(b.text, i18n.language));
  statusFilters.sort((a, b) => a.text.localeCompare(b.text, i18n.language));
  payStateFilters.sort((a, b) => a.text.localeCompare(b.text, i18n.language));
  hotelFilters.sort((a, b) => a.text.localeCompare(b.text, i18n.language));
  otaCodesFilters.sort((a, b) => a.text.localeCompare(b.text, i18n.language));

  applyFilters?.payForms?.forEach((payForm) => {
    actionsReclasificationOptions.push(
      <Option key={payForm.tipo} value={payForm.tipo}>
        <Text style={styles.textOptionValue}>
          {payForm.label}
        </Text>
      </Option>,
    );
  });

  const actionsChangeStatusOptions = [];
  actionsChangeStatusOptions.push(
    <Option key={1} value={1}>
      <Text style={styles.textOptionValue}>
        {t('text_validate')}
      </Text>
    </Option>,
  );
  actionsChangeStatusOptions.push(
    <Option key={3} value={3}>
      <Text style={styles.textOptionValue}>
        {t('text_exclude')}
      </Text>
    </Option>,
  );
  actionsChangeStatusOptions.push(
    <Option key={4} value={0}>
      <Text style={styles.textOptionValue}>
        {t('text_pending')}
      </Text>
    </Option>,
  );

  const columns = [
    {
      title: (
        <Text className="text">
          {t('text_locator')}
        </Text>),
      dataIndex: 'id_reserva',
      key: 'id_reserva',
      fixed: 'left',
      width: 180,
      sorter: {
        compare: (a, b) => a.id_reserva?.localeCompare(b.id_reserva, i18n.language),
        multiple: 1,
      },
      ...getColumnSearchProps('id_reserva'),
      filteredValue: filtersSelected.id_reserva
    },
    {
      title: (
        <Text className="text">
          {t('text_external_booking_locator')}
        </Text>),
      dataIndex: 'localizador_agencia',
      key: 'localizador_agencia',
      fixed: 'left',
      width: 180,
      sorter: {
        compare: (a, b) => a.localizador_agencia?.localeCompare(b.localizador_agencia, i18n.language),
        multiple: 1,
      },
      ...getColumnSearchProps('localizador_agencia'),
      filteredValue: filtersSelected.localizador_agencia
    },
    {
      title: (
        <Text className="text">
          {t('text_channel')}
        </Text>),
      dataIndex: 'canal',
      key: 'canal',
      width: 150,
      sorter: {
        compare: (a, b) => a.canal?.localeCompare(b.canal, i18n.language),
        multiple: 2,
      },
      filteredValue: filtersSelected.channel,
      filters: channelFilters,
      onFilter: (value, record) => record?.canal?.indexOf(value) === 0,
      responsive: ['md'],
      render: (text) => (
        <Tooltip title={applyFilters.channels.find((item) => item.label === text)?.label}>
          <Text className="text">
            {text}
          </Text>
        </Tooltip>
      ),
    },
    {
      title: (
        <Text className="text">
          {t('text_client')}
        </Text>),
      dataIndex: 'codigo_cliente',
      key: 'codigo_cliente',
      width: 150,
      sorter: {
        compare: (a, b) => a.codigo_cliente?.localeCompare(b.codigo_cliente, i18n.language),
        multiple: 12,
      },
      filteredValue: filtersSelected.cliente,
      filters: agencyFilters,
      onFilter: (value, record) => record?.codigo_cliente?.indexOf(value) === 0,
      responsive: ['md'],
      render: (text, record) => (
        <Tooltip title={record.nombre_cliente}>
          <Text className="text">
            {text}
          </Text>
        </Tooltip>
      ),
    },
    {
      title: (
        <Text className="text">
          {t('text_reservation_state')}
        </Text>),
      dataIndex: 'estado_reserva',
      key: 'estado_reserva',
      width: 180,
      sorter: {
        compare: (a, b) => a?.estado_reserva?.localeCompare(b?.estado_reserva, i18n.language),
        multiple: 3,
      },
      filteredValue: filtersSelected.payState,
      filters: payStateFilters,
      onFilter: (value, record) => record?.estado_reserva?.indexOf(value) === 0,
      responsive: ['md'],
      render: (text) => (
        <Text className="text">
          {text && text.length > 0 ? text.charAt(0).toUpperCase() + text.slice(1) ?? '' : ''}
        </Text>
      ),
    },
    {
      title: (
        <Text className="text">
          {t('text_pay_state')}
        </Text>),
      dataIndex: 'estado',
      key: 'estado',
      width: 180,
      sorter: {
        compare: (a, b) => a?.estado?.localeCompare(b?.estado, i18n.language),
        multiple: 4,
      },
      filteredValue: filtersSelected.estado,
      filters: statusFilters,
      onFilter: (value, record) => record?.estado?.indexOf(value) === 0,
      responsive: ['md'],
      render: (text, record) => {
        let styleValue = {};
        if (record.estado === 'cargo') {
          styleValue = styles.textValuePayStatusCharge;
        } else if (record.estado === 'devolucion') {
          styleValue = styles.textValuePayStatusReturn;
        }
        return (
          <Text className="text" style={styleValue}>
            {text && text.length > 0 ? text.charAt(0).toUpperCase() + text.slice(1) ?? '' : ''}
          </Text>
        );
      },
    },
    {
      title: (
        <Text className="text">
          {t('text_hotel')}
        </Text>),
      dataIndex: 'nombre_hotel',
      key: 'nombre_hotel',
      width: 180,
      sorter: {
        compare: (a, b) => a.nombre_hotel?.localeCompare(b.nombre_hotel, i18n.language),
        multiple: 5,
      },
      filteredValue: filtersSelected.hotel_code,
      filters: hotelFilters,
      onFilter: (value, record) => record?.hotel_code?.indexOf(value) === 0,
      responsive: ['md'],
      render: (text) => (
        <Text className="text">
          {text}
        </Text>
      ),
    },
    {
      title: (
        <Text className="text">
          {t('text_chain')}
        </Text>),
      dataIndex: 'cadena',
      key: 'cadena',
      width: 180,
      sorter: {
        compare: (a, b) => a.cadena?.localeCompare(b.cadena, i18n.language),
        multiple: 6,
      },
      responsive: ['md'],
      render: (text) => (
        <Text className="text">
          {text}
        </Text>
      ),
    },
    {
      title: (
        <Text className="text">
          {t('text_create')}
        </Text>),
      dataIndex: 'fecha_creacion',
      key: 'fecha_creacion',
      width: 120,
      sorter: {
        compare: (a, b) => a.fecha_creacion - b.fecha_creacion,
        multiple: 7,
      },
      align: 'center',
      responsive: ['md'],
      render: (text, record) => {
        if (record.nota_excepcion === null) {
          return (
            <Text className="text">
              {text.length === 0 ? '' : format(text, ConstantsDate.DATE_FORMAT, {
                locale: es,
              })}
            </Text>
          );
        }
        return (
          <Tooltip title={record.nota_excepcion}>
            <Text className="text">
              {text.length === 0 ? '' : format(text, ConstantsDate.DATE_FORMAT, {
                locale: es,
              })}
            </Text>
          </Tooltip>
        );
      },
    },
    {
      title: (
        <Text className="text">
          {t('text_in')}
        </Text>),
      dataIndex: 'fecha_checkin',
      key: 'fecha_checkin',
      width: 120,
      sorter: {
        compare: (a, b) => a.fecha_checkin - b.fecha_checkin,
        multiple: 8,
      },
      align: 'center',
      responsive: ['md'],
      render: (text) => (
        <Text className="text">
          {text.length === 0 ? '' : format(text, ConstantsDate.DATE_FORMAT, {
            locale: es,
          })}
        </Text>
      ),
    },
    {
      title: (
        <Text className="text">
          {t('hint_user')}
        </Text>),
      dataIndex: 'usuario',
      key: 'usuario',
      width: 120,
      sorter: {
        compare: (a, b) => a.usuario?.localeCompare(b.usuario, i18n.language),
        multiple: 9,
      },
      responsive: ['md'],
      render: (text) => (
        <Text className="text">
          {text}
        </Text>
      ),
    },
    {
      title: (
        <Text className="text">
          {t('text_noc')}
        </Text>),
      dataIndex: 'noches',
      key: 'noches',
      width: 60,
      sorter: {
        compare: (a, b) => a.noches - b.noches,
        multiple: 10,
      },
      align: 'center',
      responsive: ['md'],
      render: (text) => (
        <Text className="text">
          {text}
        </Text>
      ),
    },
    {
      title: (
        <Text className="text">
          {t('text_ota_code')}
        </Text>),
      dataIndex: 'codigo_ota',
      key: 'codigo_ota',
      width: 150,
      sorter: {
        compare: (a, b) => a.codigo_ota?.localeCompare(b.codigo_ota, i18n.language),
        multiple: 11,
      },
      filteredValue: filtersSelected.codigo_ota,
      filters: otaCodesFilters,
      onFilter: (value, record) => record.codigo_ota?.indexOf(value) === 0,
      responsive: ['md'],
      render: (text) => (
        <Tooltip title={applyFilters.payForms.find((item) => item.label === text)?.label}>
          <Text className="text">
            {text}
          </Text>
        </Tooltip>
      ),
    },
    {
      title: (
        <Text className="text">
          {t('text_type')}
        </Text>),
      dataIndex: 'tipo_collect',
      key: 'tipo_collect',
      width: 120,
      sorter: {
        compare: (a, b) => a.tipo_collect?.localeCompare(b.tipo_collect, i18n.language),
        multiple: 13,
      },
      filteredValue: filtersSelected.tipo_collect,
      responsive: ['md'],
      render: (text, record) => {
        let styleValue = {};
        if (record.tipo_collect === 'ADG Collect') {
          styleValue = styles.textValueAdgCollect;
        } else if (record.tipo_collect === 'Hotel Collect') {
          styleValue = styles.textValueHotelCollect;
        }
        return (
          <Text className="text" style={styleValue}>
            {text}
          </Text>
        );
      },
    },
    {
      title: (
        <Text className="text">
          {t('text_amount_usd')}
        </Text>),
      dataIndex: 'importe_usd',
      key: 'importe_usd',
      width: 180,
      sorter: {
        compare: (a, b) => Number(a.importe_usd) - Number(b.importe_usd),
        multiple: 14,
      },
      responsive: ['md'],
      render: (text) => (
        <Text className="text">
          {text === 0 ? '0.00' : Number(text ?? 0).toFixed(2)}
        </Text>
      ),
    },
    {
      title: (
        <Text className="text">
          {t('text_currency')}
        </Text>),
      dataIndex: 'moneda',
      key: 'moneda',
      width: 100,
      filteredValue: filtersSelected.moneda,
      filters: currencyFilters,
      onFilter: (value, record) => record.moneda?.indexOf(value) === 0,
      responsive: ['md'],
      render: (text) => (
        <Text className="text">
          {text}
        </Text>
      ),
    },
    {
      title: (
        <Text className="text">
          {t('text_amount_eur')}
        </Text>),
      dataIndex: 'importe_eur',
      key: 'importe_eur',
      width: 180,
      sorter: {
        compare: (a, b) => Number(a.importe_eur) - Number(b.importe_eur),
        multiple: 15,
      },
      responsive: ['md'],
      render: (text) => (
        <Text className="text">
          {text === 0 ? '0.00' : Number(text ?? 0).toFixed(2)}
        </Text>
      ),
    },
    {
      title: (
        <Text className="text">
          {t('text_change_rate')}
        </Text>),
      dataIndex: 'tasa_cambio',
      key: 'tasa_cambio',
      width: 180,
      sorter: {
        compare: (a, b) => Number(a.tasa_cambio) - Number(b.tasa_cambio),
        multiple: 16,
      },
      responsive: ['md'],
      render: (text) => (
        <Text className="text">
          {text === 0 ? '0.00' : text}
        </Text>
      ),
    },
    {
      title: (
        <Text className="text">
          {t('text_ota_recalification')}
        </Text>),
      dataIndex: 'reclasificacion_ota',
      key: 'reclasificacion_ota',
      width: 180,
      sorter: {
        compare: (a, b) => Number(a.reclasificacion_ota) - Number(b.reclasificacion_ota),
        multiple: 17,
      },
      responsive: ['md'],
      render: (text) => (
        <Text className="text">
          {text}
        </Text>
      ),
    },
    {
      title: (
        <Text className="text">
          {t('text_id_transaction')}
        </Text>),
      dataIndex: 'id_transaccion',
      key: 'id_transaccion',
      width: 180,
      sorter: {
        compare: (a, b) => a?.id_transaccion?.localeCompare(b?.id_transaccion, i18n.language),
        multiple: 18,
      },
      responsive: ['md'],
      render: (text) => (
        <Text className="text">
          {text === 0 ? '0.00' : text}
        </Text>
      ),
    },
    {
      title: '',
      responsive: ['md'],
      fixed: 'right',
      width: 80,
      render: (record) => ({
        props: {
          style: { background: Colors.BACKGROUND_COLOR_3 },
        },
        children: (
          <Row>
            {getWritePermissionByTab(ConstantsRoutes.PRE_CONCILIATION) ? (
              <Tooltip title={t('text_add_comment')}>
                <Button
                  type="link"
                  htmlType="button"
                  disabled={!getWritePermissionByTab(ConstantsRoutes.PRE_CONCILIATION)}
                  onClick={() => {
                    showAddComment(record);
                  }}
                  icon={<CommentOutlined style={styles.iconSave} />}
                  style={styles.buttonLink}
                />
              </Tooltip>
            ) : <div />}
            <Tooltip title={t('text_comments')}>
              <Button
                type="link"
                htmlType="button"
                onClick={() => {
                  dispatch(actionLoading(true));
                  dispatch(actionGetCommentPreConciliation(record.id_arreglo));
                }}
                icon={<ReadOutlined style={styles.iconSave} />}
                style={styles.buttonLink}
              />
            </Tooltip>
          </Row>),
      }),
    },
  ];

  const csvArrayReservation = exportToCsvReservations();

  return (
    <Spin spinning={loading} size="large">
      <BackTop />
      <Row justify="end">
        <Col span={24}>
          <CommentsPreConciliation />
          {/* <ResumePreConciliation /> */}
          <FilterPreConciliation />
          <Row align="middle" justify="end">
            <Col style={{ marginRight: 16 }}>
              <Button
                type="primary"
                icon={<CommentOutlined />}
                size="small"
                disabled={mySelectedRows.length === 0
                  || !getWritePermissionByTab(ConstantsRoutes.PRE_CONCILIATION)}
                className="btn btn-h-auto text text-w-bold px-3 py-2"
                onClick={() => {
                  showAddComment();
                }}
              >
                {t('text_add_comment')}
              </Button>
            </Col>
            <Col style={{ marginRight: 16 }}>
              <Button
                icon={<DeleteOutlined />}
                size="small"
                disabled={mySelectedRows.length === 0
                  || !getWritePermissionByTab(ConstantsRoutes.PRE_CONCILIATION)}
                className="btn btn-h-auto text text-w-bold px-3 py-2"
                onClick={() => {
                  showDeleteReclasificationOta();
                }}
              >
                {t('text_button_delete_recl_ota')}
              </Button>
            </Col>
            <Col style={{ marginRight: 16 }}>
              <Select
                dropdownStyle={{ minWidth: '25%' }}
                showSearch
                disabled={mySelectedRows.length === 0
                  || !getWritePermissionByTab(ConstantsRoutes.PRE_CONCILIATION)}
                style={styles.select}
                placeholder={t('text_ota_recalification_action')}
                optionFilterProp="children"
                onChange={(value) => {
                  setRecalificationAction(value);
                  showConfirm(value);
                }}
                filterOption={
                  (
                    input,
                    option
                  ) => option.children.props.children.toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                value={recalificationAction}
              >
                {actionsReclasificationOptions}
              </Select>
            </Col>
            <Col style={{ marginRight: 16 }}>
              <Select
                showSearch
                disabled={mySelectedRows.length === 0
                  || !getWritePermissionByTab(ConstantsRoutes.PRE_CONCILIATION)}
                style={styles.select}
                placeholder={t('text_change_state')}
                optionFilterProp="children"
                onChange={(value) => {
                  setStatusAction(value);
                  showConfirmChangeStatus(value);
                }}
                filterOption={
                  (
                    input,
                    option
                  ) => option.children.props.children.toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                value={statusAction}
              >
                {actionsChangeStatusOptions}
              </Select>
            </Col>
            <Col style={{ marginRight: 16 }}>
              <Button
                onClick={clearAllFilters}
                size="small"
                disabled={reservations.length === 0}
                className="btn btn-h-auto text text-w-bold px-3 py-2"
              >
                {t('text_button_clear_filters')}
              </Button>
            </Col>
            <Col style={{ marginRight: 16 }}>
              <Button
                type="primary"
                icon={<FileExcelOutlined />}
                size="small"
                disabled={reservations.length === 0}
                className="btn btn-h-auto text text-w-bold px-3 py-2"
                onClick={() => {
                  const wb = XLSX.utils.book_new();
                  const ws = XLSX.utils.json_to_sheet([]);
                  XLSX.utils.sheet_add_aoa(ws, headers);
                  XLSX.utils.sheet_add_json(ws, csvArrayReservation, { origin: 'A2', skipHeader: true });
                  XLSX.utils.book_append_sheet(wb, ws, 'Reservas');
                  XLSX.writeFile(wb, 'reservas.xlsx');
                }}
              >
                {t('text_button_export')}
              </Button>
            </Col>
          </Row>
          <Table
            style={{ width: '100%' }}
            rowKey="id_arreglo"
            pagination={{ total: reservations.length, pageSizeOptions: ['10', '20', '50', '100', '200', '300', '500'] }}
            size="small"
            scroll={{ x: 1300 }}
            loading={loading}
            columns={columns}
            dataSource={reservations}
            onChange={handleChange}
            rowSelection={{
              type: 'checkbox',
              ...rowSelection(),
            }}
            footer={() => (
              <Row>
                <Col style={{ marginRight: 16 }}>
                  <Button
                    onClick={clearAllFilters}
                    size="small"
                    disabled={reservations.length === 0}
                    className="btn btn-h-auto text text-w-bold px-3 py-2"
                  >
                    {t('text_button_clear_filters')}
                  </Button>
                </Col>
                <Col>
                  <Button
                    type="primary"
                    icon={<FileExcelOutlined />}
                    size="small"
                    disabled={reservations.length === 0}
                    className="btn btn-h-auto text text-w-bold px-3 py-2"
                    onClick={() => {
                      const wb = XLSX.utils.book_new();
                      const ws = XLSX.utils.json_to_sheet([]);
                      XLSX.utils.sheet_add_aoa(ws, headers);
                      XLSX.utils.sheet_add_json(ws, csvArrayReservation, { origin: 'A2', skipHeader: true });
                      XLSX.utils.book_append_sheet(wb, ws, 'Reservas');
                      XLSX.writeFile(wb, 'reservas.xlsx');
                    }}
                  >
                    {t('text_button_export')}
                  </Button>
                </Col>
              </Row>
            )}
            summary={() => (
              <Table.Summary.Row>
                <Table.Summary.Cell colSpan={3}>
                  <Text className="text">
                    {t('text_total_values') + reservations.length}
                  </Text>
                </Table.Summary.Cell>
              </Table.Summary.Row>
            )}
          />
        </Col>
      </Row>
    </Spin>
  );
}
