export const addFilterItemToArray = (array, value, text = null) => {
  if (value?.length) {
    if (!array.some((item) => item.value === value)) {
      array.push({
        text: text ?? value,
        value,
      });
    }
  }
};

export default {addFilterItemToArray};
