import qs from 'qs';
import { api } from './api';
import i18n from '../assets/language/i18n';

export const tpvManualRequestsApi = api.injectEndpoints({
  tagTypes: ['tpvManual'],
  endpoints: (build) => ({
    sendTransactionForPaymentApi: build.mutation({
      query: (data) => {
        const dataToSend = {
          transaccionId: data.transactionId,
          importe: data.serviceAmount,
          moneda: data.currency,
          fechaCaducidad: data.date,
          conceptoPago: data.payConcept,
          hotelCode: data.hotelCode,
          localizador: data.localizator,
          fecha_inicio_servicio: data.dateInitSend,
          fecha_fin_servicio: data.dateEndSend,
        };
        return { method: 'POST', url: '/cobros/cobroTpvManualReservas', data: qs.stringify(dataToSend) };
      },
      transformResponse: (response) => ({ ...response, message: `${i18n.t('text_pay_send')}`}),
      transformErrorResponse: () => ({
        message: i18n.t('error_send_invitation'),
      }),

    }),
    sendTransactionForTpvManualApi: build.mutation({
      query: (data) => {
        const dataToSend = {
          transaccionId: data.transactionId,
          importe: data.serviceAmount,
          moneda: data.currency,
          fechaCaducidad: data.date,
          conceptoPago: data.payConcept,
          hotelCode: data.hotelCode,
          localizador: data.localizator,
          fecha_inicio_servicio: data.dateInitSend,
          fecha_fin_servicio: data.dateEndSend,
        };
        return { method: 'POST', url: '/cobros/cobroTpvManualServicios', data: qs.stringify(dataToSend) };
      },
      transformResponse: (response) => ({ ...response, message: `${i18n.t('text_pay_send')}`}),
      transformErrorResponse: () => ({
        message: i18n.t('error_send_invitation'),
      }),
    }),
  }),
});

export const {
  useSendTransactionForPaymentApiMutation,
  useSendTransactionForTpvManualApiMutation,
} = tpvManualRequestsApi;
