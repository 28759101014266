import React, { useEffect } from 'react';
import {
  Modal,
} from 'antd';
import queryString from 'query-string';
import 'antd/dist/antd.less';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { actionLoading } from '../../store/reducers/loading/loadingActions';
import { actionGetDataPayment } from '../../store/reducers/pay/payActions';

export default function Pay() {
  const location = useLocation();

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const paymentData = useSelector((state) => state.reducerDataPayment);
  const loading = useSelector((state) => state.reducerLoading);

  useEffect(() => {
    if (!loading) {
      const values = queryString.parse(location.search);
      dispatch(actionLoading(true));
      dispatch(actionGetDataPayment(values.loc, values.id));
    }
  }, []);

  useEffect(() => {
    if (paymentData.code) {
      window.open(paymentData.url, '_self');
    } else if (paymentData.mensaje !== null) {
      const { error } = Modal;

      error({
        title: t('app_name'),
        content: paymentData.mensaje,
      });
    }
  });

  return (
    <div />
  );
}
