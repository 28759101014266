const ConstantsLogin = {
  LOGIN: 'LOGIN',
  LOGIN_RESULT: 'LOGIN_RESULT',
  CLEAR_LOGIN_RESULT: 'CLEAR_LOGIN_RESULT',

  FORGOT_PASSWORD: 'FORGOT_PASSWORD',
  FORGOT_PASSWORD_RESULT: 'FORGOT_PASSWORD_RESULT',
  CLEAR_FORGOT_PASSWORD_RESULT: 'CLEAR_FORGOT_PASSWORD_RESULT',
  LOGIN_BY_HASH: 'LOGIN_BY_HASH',
  LOGIN_BY_HASH_RESULT: 'LOGIN_BY_HASH_RESULT',

  RESET_PASSWORD: 'RESET_PASSWORD',
  RESET_PASSWORD_RESULT: 'RESET_PASSWORD_RESULT',
  CLEAR_RESET_PASSWORD_RESULT: 'CLEAR_RESET_PASSWORD_RESULT',
};

export default ConstantsLogin;
