import React, { useState } from 'react';
import {
  Button,
  Col,
  Collapse,
  DatePicker,
  Form,
  Input,
  message,
  Row,
  Select,
  Typography
} from 'antd';
import 'antd/dist/antd.less';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { SearchOutlined } from '@ant-design/icons';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import ConstantsDate from '../../../constants/ConstantsDate';
import {
  useGetFiltersPaymentRequestsApiQuery,
  setAppliedFilters
} from '../../../services/paymentRequests';
import './styles/styles.css';

const { Text } = Typography;
const { Panel } = Collapse;
const { Option } = Select;
const { RangePicker } = DatePicker;

const formRef = React.createRef();

function getOptionsForSelect(
  list,
  className,
  value = 'value',
  label = 'label'
) {
  return list?.map((i) => (
    <Option key={i[value]} value={i[value]}>
      <Text className={className}>{i[label]}</Text>
    </Option>
  ));
}

export default function FilterPaymentRequest() {
  const [statePaymentRequest, setStatePaymentRequest] = useState(null);
  const [company, setCompany] = useState(null);
  const [chain, setChain] = useState(null);
  const [dateCreateStart, setDateCreateStart] = useState(null);
  const [dateCreateEnd, setDateCreateEnd] = useState(null);
  const [numberLocator, setNumberLocator] = useState(null);

  const [datePaymentStart, setDatePaymentStart] = useState(null);
  const [datePaymentEnd, setDatePaymentEnd] = useState(null);
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const { data: filters, error } = useGetFiltersPaymentRequestsApiQuery();

  if (error) {
    message.error(error.message);
  }

  const clickClearFilters = () => {
    setStatePaymentRequest(null);
    setCompany(null);
    setChain(null);
    setDateCreateStart(null);
    setDateCreateEnd(null);
    setDatePaymentStart(null);
    setDatePaymentEnd(null);
    setNumberLocator(null);
    formRef.current.resetFields();
  };

  const clickFilterButton = (e) => {
    dispatch(
      setAppliedFilters({
        referencia: numberLocator ?? undefined,
        cadena_hotelera: chain ?? undefined,
        empresa: company ?? undefined,
        estado: statePaymentRequest ?? undefined,
        fecha_inicio_solicitud: !dateCreateStart
          ? undefined
          : format(
            dateCreateStart.toDate(),
            ConstantsDate.DATE_FORMAT_REQUEST,
            {
              locale: es
            }
          ),
        fecha_fin_solicitud: !dateCreateEnd
          ? undefined
          : format(dateCreateEnd.toDate(), ConstantsDate.DATE_FORMAT_REQUEST, {
            locale: es
          }),
        fecha_inicio_pago: !datePaymentStart
          ? undefined
          : format(
            datePaymentStart.toDate(),
            ConstantsDate.DATE_FORMAT_REQUEST,
            {
              locale: es
            }
          ),
        fecha_fin_pago: !datePaymentEnd
          ? undefined
          : format(datePaymentEnd.toDate(), ConstantsDate.DATE_FORMAT_REQUEST, {
            locale: es
          })
      })
    );
  };

  const chainsOptions = getOptionsForSelect(
    filters?.chains,
    'text-option-value',
    'id_cadena',
    'nombre_cadena'
  );
  const companiesOptions = getOptionsForSelect(
    filters?.companies.filter((item) => !chain || item.id_cadena === chain),
    'text-option-value'
  );
  const paymentRequestStatesOptions = getOptionsForSelect(
    filters?.status,
    'text-option-value'
  );

  function emptyFilters() {
    return !(
      statePaymentRequest?.length
      || company?.length
      || chain?.length
      || dateCreateStart
      || dateCreateEnd
      || numberLocator?.length
      || datePaymentStart
      || datePaymentEnd
    );
  }

  return (
    <Row align="middle" className="row-filter remaquia">
      <Collapse
        accordion
        defaultActiveKey={['1']}
        style={{ width: '100%' }}
        expandIconPosition="end"
      >
        <Panel
          key="1"
          header={
            <Text className="text-title">{t('text_search_filters')}</Text>
          }
        >
          <Form
            name="advanced_search"
            className="ant-advanced-search-form"
            labelCol={{
              span: 10
            }}
            ref={formRef}
            onSubmit={clickFilterButton}
          >
            <Row gutter={24}>
              <Col xs={24} md={8}>
                <Form.Item
                  name="field1"
                  label={(
                    <Text className="text-title-description">
                      {t('text_reference')}
                    </Text>
                  )}
                  rules={[
                    {
                      required: false
                    }
                  ]}
                >
                  <Input
                    suffix={<SearchOutlined className="icon-filter" />}
                    placeholder={t('text_reference')}
                    onChange={(text) => {
                      setNumberLocator(text.target.value);
                    }}
                    value={numberLocator}
                    className="input-filter"
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={8}>
                <Form.Item
                  name="chain"
                  label={(
                    <Text className="text-title-description">
                      {t('text_chain')}
                    </Text>
                  )}
                  rules={[
                    {
                      required: false
                    }
                  ]}
                >
                  <Select
                    showSearch
                    allowClear
                    defaultActiveFirstOption={false}
                    className="select-input"
                    placeholder={t('text_chain')}
                    optionFilterProp="children"
                    onChange={(value) => {
                      setChain(value);
                    }}
                    filterOption={(input, option) => option.children.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0}
                    value={chain}
                  >
                    {chainsOptions}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} md={8}>
                <Form.Item
                  name="dateCreate"
                  label={(
                    <Text className="text-title-description">
                      {t('text_request_date')}
                    </Text>
                  )}
                  rules={[
                    {
                      required: false
                    }
                  ]}
                >
                  <RangePicker
                    className="input-filter"
                    format={ConstantsDate.DATE_FORMAT_PICKER}
                    onChange={(dates) => {
                      setDateCreateStart(dates === null ? null : dates[0]);
                      setDateCreateEnd(dates === null ? null : dates[1]);
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col xs={24} md={8}>
                <Form.Item
                  name="statePaymentRequest"
                  label={(
                    <Text className="text-title-description">
                      {t('text_conciliation_status')}
                    </Text>
                  )}
                  rules={[
                    {
                      required: false
                    }
                  ]}
                >
                  <Select
                    showSearch
                    className="select-input"
                    allowClear
                    placeholder={t('text_conciliation_status')}
                    optionFilterProp="children"
                    onChange={(value) => {
                      setStatePaymentRequest(value);
                    }}
                    filterOption={(input, option) => option.children.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0}
                    value={statePaymentRequest}
                  >
                    {paymentRequestStatesOptions}
                  </Select>
                </Form.Item>
              </Col>

              <Col xs={24} md={8}>
                <Form.Item
                  name="company"
                  label={(
                    <Text className="text-title-description">
                      {t('company')}
                    </Text>
                  )}
                  rules={[
                    {
                      required: false
                    }
                  ]}
                >
                  <Select
                    showSearch
                    allowClear
                    defaultActiveFirstOption={false}
                    className="select-input"
                    placeholder={t('company')}
                    optionFilterProp="children"
                    onChange={(value) => {
                      setCompany(value);
                    }}
                    filterOption={(input, option) => option.children.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0}
                    value={company}
                  >
                    {companiesOptions}
                  </Select>
                </Form.Item>
              </Col>

              <Col xs={24} md={8}>
                <Form.Item
                  name="dateCreatePayment"
                  label={(
                    <Text className="text-title-description">
                      {t('text_payment_date')}
                    </Text>
                  )}
                  rules={[
                    {
                      required: false
                    }
                  ]}
                >
                  <RangePicker
                    className="input-filter"
                    format={ConstantsDate.DATE_FORMAT_PICKER}
                    onChange={(dates) => {
                      setDatePaymentStart(dates === null ? null : dates[0]);
                      setDatePaymentEnd(dates === null ? null : dates[1]);
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={24}>
                <Form.Item
                  name="buttons"
                  rules={[
                    {
                      required: false
                    }
                  ]}
                >
                  <Row justify="end">
                    <Col>
                      <Button
                        onClick={clickClearFilters}
                        className="button-default-filters"
                      >
                        {t('text_button_clear')}
                      </Button>
                    </Col>
                    <Col>
                      <Button
                        type="primary"
                        htmlType="submit"
                        onClick={clickFilterButton}
                        className="button-primary-filters"
                        disabled={emptyFilters()}
                      >
                        {t('text_button_filter')}
                      </Button>
                    </Col>
                  </Row>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Panel>
      </Collapse>
    </Row>
  );
}
