import axios from 'axios';
import Cookies from 'js-cookie';
import ConstantsValue from '../constants/ConstantsValue';

const AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 90000,
  headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
});

AxiosInstance.interceptors.request.use((request) => request);

AxiosInstance.interceptors.response.use((response) => response, (error) => error.response);

const service = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 90000,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
    Accept: 'application/json',
  },
});

// request interceptor
service.interceptors.request.use(
  (request) => {
    // const token = store.getters["auth/token"]
    const token = Cookies.get(ConstantsValue.JWT);
    if (token) {
      request.headers.Authorization = token;
    }
    return request;
  },
  (error) => Promise.reject(error)
);

service.interceptors.response.use((response) => response, (error) => {
  if (error?.response?.status === 401) {
    Cookies.remove(ConstantsValue.JWT);
    window.location.href = '/login';
  }
});

export {
  service as request
};

export default AxiosInstance;
