import {
  call, takeEvery, put,
} from 'redux-saga/effects';
import qs from 'qs';
import i18n from '../assets/language/i18n';
import { actionLoading } from '../store/reducers/loading/loadingActions';
import {request} from './axiosConfig';
import ConstantsTpvManual from '../store/reducers/tpvManual/tpvManualConstants';
import {
  actionGetFiltersTpvManualResult,
} from '../store/reducers/tpvManual/tpvManualActions';

function getFiltersTpvManualApi() {
  return request.post('/cobros/getFiltrosTpvManual', new FormData())
    .then((response) => response)
    .catch((error) => error.response);
}

function* generateGetFiltersTpvManual() {
  const filters = {
    hotels: [],
    currencies: [],
  };
  try {
    const { status, data } = yield call(getFiltersTpvManualApi);
    if (status === 200) {
      filters.hotels = data.hoteles?.sort(
        (a, b) => a?.label.localeCompare(b?.label, i18n.language),
      );
      filters.currencies = data.monedas?.sort(
        (a, b) => a?.label.localeCompare(b?.label, i18n.language),
      );

      yield put(actionGetFiltersTpvManualResult(true, null, filters));
    } else {
      yield put(actionGetFiltersTpvManualResult(false, i18n.t('error_get_filters'), filters));
    }
  } catch (error) {
    console.log(error);
    yield put(actionGetFiltersTpvManualResult(false, i18n.t('error_get_filters'), filters));
  }

  yield put(actionLoading(false));
}

export default function* tpvManualSagas() {
  yield takeEvery(ConstantsTpvManual.GET_FILTERS_TPV_MANUAL, generateGetFiltersTpvManual);
}
