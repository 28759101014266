import LoadingConstants from './loadingConstants';

const reducerLoading = (state = false, action = {}) => {
  switch (action.type) {
    case LoadingConstants.LOADING:
      return action.value;
    default:
      return state;
  }
};

export default reducerLoading;
