import ConstantsPay from './payConstants';
import StoreGlobalConstants from '../storeGlobalConstants';

const reducerDataPayment = (state = {
  mensaje: null,
  code: false,
  url: null,
}, action = {}) => {
  switch (action.type) {
    case ConstantsPay.GET_DATA_PAYMENT_RESULT:
      return {
        ...state,
        mensaje: action.mensaje ?? '',
        code: action.code ?? false,
        url: action.url ?? null,
      };
    case StoreGlobalConstants.CLEAR_DATA:
      return {
        ...state,
        mensaje: null,
        code: false,
        url: null,
      };
    default:
      return state;
  }
};

export default reducerDataPayment;
