import React from 'react';
import { Layout, Row, Typography } from 'antd';
import 'antd/dist/antd.less';
import { useTranslation } from 'react-i18next';
import {
  Navigate, useLocation,
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Cookies from 'js-cookie';
import RoutesAuthYes from './routesAuthYes';
import ConstantsValue from '../../constants/ConstantsValue';
import TopBar from './TopBar';

import { actionDecodedJWT } from '../../store/reducers/home/homeActions';

import bgFooter from '../../assets/images/bg_footer.png';
import ConstantsRoutes from '../../constants/ConstantsRoutes';

const { Link, Text } = Typography;
const { Footer, Content } = Layout;

export default function Home() {
  const location = useLocation();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const jwtData = useSelector(
    (state) => state.reducerJwtData
  );

  if (Cookies.get(ConstantsValue.JWT) === undefined
    || Cookies.get(ConstantsValue.JWT) === null) {
    return <Navigate to="/login" replace />;
  }

  if (!jwtData || Object.keys(jwtData).length === 0) {
    dispatch(actionDecodedJWT());
  }

  let title = '';
  switch (location.pathname) {
    case ConstantsRoutes.PAYMENTS:
      title = t('text_title_payments');
      break;
    case ConstantsRoutes.TPVS:
      title = t('text_title_tpvs');
      break;
    case ConstantsRoutes.INVITATIONS:
      title = t('text_title_invitations');
      break;
    case ConstantsRoutes.INVITATION_ADD:
      title = t('new_invitation');
      break;
    case ConstantsRoutes.TPV_SECURE_MANUAL:
      title = t('new_tpv_secure');
      break;
    case ConstantsRoutes.PRE_CONCILIATION:
      title = t('text_button_pre_conciliation');
      break;
    case ConstantsRoutes.CONCILIATIONS:
      title = t('text_button_conciliations');
      break;
    case ConstantsRoutes.CONCILIATION:
      title = t('text_button_conciliation');
      break;
    case ConstantsRoutes.TPV_MANUAL:
      title = t('text_manual_tpv');
      break;
    case ConstantsRoutes.TPV_TRANSACTIONS:
      title = t('text_tpv_transactions');
      break;
    case ConstantsRoutes.COLLECTION_REQUESTS:
      title = t('text_collection_requests');
      break;
    case ConstantsRoutes.PAYMENT_REQUESTS:
      title = t('text_payment_requests');
      break;
    default:
      title = t('text_title_payments');
      break;
  }

  return (
    <Layout>
      <Content>
        <TopBar />
        <Row
          align="middle"
          className="p-3 px-lg-4 bg bg-color-white"
        >
          <Text className="text text-size-1-3 text-w-med">{title}</Text>
        </Row>
        <RoutesAuthYes />
      </Content>
      <Footer className="px-lg-0">
        <Row justify="end" className="px-lg-5 mb-lg-5">
          <Text className="text text-size-0-8 text-color-black mr-2">
            {t('text_contact_1')}
          </Text>
          <Link
            className="text text-size-0-8 text-color-secondary"
            href="mailto:info@adgtravel.com"
            target="_blank"
          >
            {t('text_contact_2')}
          </Link>
        </Row>
        <div className="d-none d-lg-block w-100">
          <img src={bgFooter} alt="" className="img img-w img-auto" />
        </div>
      </Footer>
    </Layout>
  );
}
