import React, { useEffect } from 'react';
import {
  Modal,
} from 'antd';
import queryString from 'query-string';
import 'antd/dist/antd.less';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

export default function PayResult() {
  const location = useLocation();

  const { t } = useTranslation();

  useEffect(() => {
    const values = queryString.parse(location.search);
    const { error, success } = Modal;

    if (values?.result?.toUpperCase() === 'OK') {
      success({
        title: t('text_ok_pay_result'),
        okButtonProps: { style: { display: 'none' } },
      });
    } else {
      error({
        title: t('text_ko_pay_result'),
        okButtonProps: { style: { display: 'none' } },
      });
    }
  }, []);

  return (
    <div />
  );
}
